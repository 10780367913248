
import { IPlan } from "../Plans/Plans"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Box, Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import Close from "@mui/icons-material/Close";
import { IMultiMedia } from "../../models/multi-media.model";
import { interfaceCourse } from "../../models/courses.model";


interface aboutPlans {
    coursesData :interfaceCourse[];
}


const NewAboutCourses: React.FC<aboutPlans> = ({coursesData}) => {

    const [open, setOpen] = useState(false);
    const [fullWidth] = useState(true);
    const [maxWidth] = useState<DialogProps["maxWidth"]>("sm");
    const [selectedItem, setSelectedItem] = useState<IPlan | null>(null);


    const handleCloses = () => {
        setOpen(false);
    };

    const handleClickOpen = (item: IPlan) => {
        setSelectedItem(item);
        setOpen(true);
    };

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 3,
            slidesToSlide: 1,
        },
        desktop: {
            breakpoint: { max: 1024, min: 800 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 800, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    
    const plansList = coursesData.map((course: any) => (
        <Card key={course._id} sx={{
            marginRight: "20px",
            paddingBottom: "20px",
            borderRadius: '20px',
            backgroundColor: "#ffffff",
            height: "92%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            boxShadow:1
        }}>
            <CardMedia
                component="img"
                alt={course?.name}
                image={
                    typeof course?.image === "object" && course?.image
                        ? (course?.image as IMultiMedia).value
                        : require("./../../assets/images/NoPlanImage.png")
                }
                sx={{
                    width: '100%',
                    height: '150px',
                    objectFit: 'cover',
                    marginBottom: '8px',
                    borderRadius: '20px',
                    p:1
                }}
            />
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography sx={{
                    color: '#2952A2',
                    fontSize: { xs: "12px", md: '16px' },
                    fontFamily: 'Montserrat',
                    fontWeight: "700",
                    textAlign: 'center',
                    marginBottom: '10px',
                }}>
                    {course?.name?.length > 25 ? `${course?.name?.slice(0, 25)}...` : course?.name}
                </Typography>

                <Box sx={{
                    marginBottom: '10px',
                    color: '#565656',
                    height: '11vh',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    position: 'relative',
                }}>
                    <Typography
                        sx={{
                            fontSize: { xs: '12px', md: '14px' },
                            fontWeight: 400,
                            fontFamily: 'Montserrat',
                            textAlign: 'justify',
                            color: "#000000"
                        }}
                    >
                        {course?.description && course.description.length > 110
                            ? course.description.substring(0, 110) + '...'
                            : course.description}
                    </Typography>
                    {course?.description && course?.description.length > 110 && (
                        <Button
                            onClick={() => handleClickOpen(course)}
                            sx={{
                                color: "#2952A2",
                                fontWeight: 500,
                                fontSize: { xs: '10px', md: '12px' },
                                textTransform: 'capitalize',
                                textDecoration: 'underline',
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                fontFamily: 'Montserrat',
                                padding: 0,
                                minHeight: 'auto',
                                lineHeight: 'normal',
                            }}
                        >
                            Read More
                        </Button>
                    )}
                </Box>
                <Stack
                    direction={"row"}
                    spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    alignContent={"center"}
                    justifyContent={"start"}
                    alignItems={"start"}
                    pt={1}
                >
                    <Typography sx={{ fontSize: { xs: "10px", md: '13px' }, fontWeight: 700, fontFamily: 'Montserrat' }}>{course?.pricing}</Typography> 
                    
                </Stack>
            </CardContent>
        </Card>
    ));

    return (
        <div>
            <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
            >
                {plansList}
            </Carousel>
            {selectedItem && (
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={open}
                    onClose={handleCloses}
                >
                    <DialogActions sx={{ backgroundColor: "#F0F9FF" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ backgroundColor: "#F0F9FF" }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { xs: "14px", md: "16px" },
                                    fontWeight: 500,
                                    textDecoration: "none",
                                    color: "#50A1CA",
                                    textTransform: "uppercase",
                                    marginLeft: 2,
                                    fontFamily: 'Montserrat'
                                }}
                            >
                                {selectedItem.name}
                            </Typography>
                            <Button onClick={handleCloses} sx={{ minWidth: "auto", marginRight: 1 }}>
                                <Close sx={{ fontSize: "20px", color: "#50A1CA" }} />
                            </Button>
                        </Grid>
                    </DialogActions>
                    <DialogContent sx={{ backgroundColor: "#F0F9FF", paddingBottom: "25px" }}>
                        <DialogContentText sx={{ textAlign: "justify", color: "#6B6B6B", fontSize: { xs: "14px", md: "14px" }, fontWeight: 500, lineHeight: 1.5, fontFamily: 'Montserrat' }}>
                            {selectedItem.description}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
}
export default NewAboutCourses;