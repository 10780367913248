
import { Stack, Typography } from "@mui/material";

export const Messages = () => {
  return <>
  <Stack>
        <Typography sx={{ fontSize: '20px', fontWeight: 500, color: '#50A1CA', fontFamily:'Inter' }}>
          Messages
        </Typography>
      </Stack>
  </>;
};
