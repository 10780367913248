import React, { useState, useEffect } from "react";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  Paper,
  Stack,
  TextField,
  styled,
} from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import OpenInNewIcon from "@mui/icons-material/OpenInNew";
// import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { usePosts } from "../../hooks/usePosts";
import { IPost } from "../Posts/Posts";
import { useSelector } from "react-redux";
import { AddPost } from "../sub-components/AddPost";
// import ImageGalleryCraft from "../ImageUploader/ImageGalleryCraft";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { NoPosts } from "../AlternatePages/noPosts";
import Loader from "../Loader/Loader";
import { DeleteIcon, EditIcon } from "../../assets/icons";
import { Close } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

// import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
// import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";

import { useCommunity } from "../../hooks/useCommunity";
import store from "../../store";
import { Favorite } from "./Favourite";
import PostImages from "../ImageUploader/PostImages";

const styleReport = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "48%",
  transform: "translate(-50%, -50%)",
  width: { xs: 250, md: 400 },
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const HomePost: React.FC = () => {
  const [homePosts, setHomePosts] = useState<IPost[]>([]);
  const [individualUserPosts, setIndividualUserPosts] = useState<any[]>([]);
  const [loading] = useState();
  const {
    isLoading,
    deletePostById,
    getCommunityPostsById,
    getIndividualUserPostsById,
    likePost,
  } = usePosts();

  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity
  );
  const state = store.getState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loggedInUsersData = useSelector(() => {
    return state?.loggedInUser;
  });

  const usersData = store.getState();

  const [userId] = useState(usersData.loggedInUser.user?.id);

  const navigate = useNavigate();
  const location = useLocation();
  const TotalPosts = location.pathname.match(
    /^\/profile\/posts\/[a-zA-Z0-9]+$/
  );
  const [showFullContents, setShowFullContents] = useState<{
    [key: string]: boolean;
  }>({});
  // const [postFavHeartTouch, setPostFavHeartTouch] = useState<{[key: string]: boolean;}>({});
  const [selectedPost, setSelectedPost] = useState<IPost | null>(null);
  const [openDialogg, setOpennDialogg] = useState<boolean[]>([]);
  const [mode, setMode] = useState<"Add" | "Edit">("Add");
  const [userMapping, setUserMapping] = useState<any>(null);
  const selectedCommunityId = selectedCommunity?.selectedCommunity?._id;
  // console.log(selectedCommunityId);

  const [isOpen, setIsOpen] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const { updateUserMappingDetails, updateUserMappingBanner } = useCommunity();
  const [bannerImage, setBannerImage] = useState("");
  const communitiesSuggestion = useSelector((state: any) => state?.communities);
  //  let filteredSelectedCommunity :any=[] ;//= communitiesSuggestion?.communities?.filter(
  // //   (community: any) =>
  // //     community._id === selectedCommunity.selectedCommunity?.id
  // // );
  let filteredSelectedCommunity: any = [];
  if (Array.isArray(communitiesSuggestion?.communities)) {
    // Filter the array if it is not null or undefined
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    filteredSelectedCommunity = communitiesSuggestion.communities.filter(
      (community: any) =>
        community._id === selectedCommunity.selectedCommunity?.id
    );
  }
  const handlePostImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length) {
      const formData = new FormData();

      try {
        setBannerImage(URL.createObjectURL(files[0]));
        await updateUserMappingBanner(selectedCommunityId, files, formData);

        console.log("User mapping details updated successfully!");
      } catch (error) {
        console.error("Error updating user mapping details:", error);
      }
    }
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setMode("Add");
  };
  // >>>>>>>>>>>>>>>>>> functions <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  //selectedCommunityId = selectedCommunityId?selectedCommunityId:localStorage.getItem('communityId')
  useEffect(() => {
    const fetchData = async () => {
      const communityPosts = await getCommunityPostsById(selectedCommunityId);
      if (communityPosts) setHomePosts(communityPosts);
    };
    if (location.pathname === "/home" && selectedCommunityId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId, location.pathname]);

  const { id } = useParams();

  useEffect(() => {
    const loadindividualUserPosts = async () => {
      const individualPosts = await getIndividualUserPostsById(
        selectedCommunityId || "",
        id || ""
      );
      if (individualPosts) {
        setIndividualUserPosts(individualPosts.posts);
        setUserMapping(individualPosts?.mapping);
      }
    };
    if (location.pathname.match(/^\/profile\/posts\/[a-zA-Z0-9]+$/))
      loadindividualUserPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId, id]);

  const userRole = individualUserPosts.map((post) => post.createdBy.role);
  const uniqueRoles = [...new Set(userRole)];
  const singleRole = uniqueRoles[0];
  // console.log(individualUserPosts);
  const [aboutMe, setAboutMe] = useState(userMapping?.aboutMe || "");

  /////////////////////////////// post like function
  const handleToggleContents = (
    e: React.MouseEvent<HTMLElement>,
    post: IPost
  ) => {
    setShowFullContents((prevShowFullContents) => ({
      ...prevShowFullContents,
      [post._id]: !prevShowFullContents[post._id],
    }));
  };

  const handleHeartClick = async (
    e: React.MouseEvent<HTMLElement>,
    post: IPost
  ) => {
    e.stopPropagation();
    const isLikedByUser = post?.likedBy.some((user) => user._id === userId);
    const liked: boolean = !isLikedByUser;
    const response = await likePost(post?._id, liked);
    if (response?.status === 200) {
    }
  };

  const handleSelectPost = (post: IPost | null) => {
    setMode("Edit");
    setSelectedPost(post);
  };
  const home = [...homePosts]; // Your list of home posts
  const NumberOfModals = home.length;
  const [isModalOpen, setIsModalOpen] = useState<boolean[]>(
    Array(NumberOfModals).fill(false)
  );

  const handleOpen = (index: number) => {
    setIsModalOpen((prevOpenModals) => {
      const updatedOpenModals = [...prevOpenModals];
      updatedOpenModals[index] = true;
      return updatedOpenModals;
    });
  };
  const handleClose = (index: number) => {
    setIsModalOpen((prevOpenModals) => {
      const updatedOpenModals = [...prevOpenModals];
      updatedOpenModals[index] = false;
      return updatedOpenModals;
    });
    setMode("Add");
  };
  const handleOpenDialogg = (index: number) => {
    setOpennDialogg((prevOpenModals) => {
      const updatedOpenModals = [...prevOpenModals];
      updatedOpenModals[index] = true;
      return updatedOpenModals;
    });
  };

  const handleCloseDialogg = (index: number) => {
    setOpennDialogg((prevOpenModals) => {
      const updatedOpenModals = [...prevOpenModals];
      updatedOpenModals[index] = false;
      return updatedOpenModals;
    });
  };

  const loggedInUserData = useSelector((state: any) => state?.loggedInUser);

  // const filteredSelectedCommunity = communitiesSuggestion?.communities?.filter(
  //   (community: any) => {
  //     return community?.id === selectedCommunityId;
  //   }
  // );

  // posts display logic for profile and home page
  const postsToDisplay =
    location.pathname === "/home"
      ? homePosts
      : location.pathname.match(/^\/profile\/posts\/[a-zA-Z0-9]+$/)
      ? individualUserPosts
      : [];
  const sortedPosts = postsToDisplay.sort((a, b) => {
    const dateB = new Date(b.updatedAt).getTime();
    const dateA = new Date(a.updatedAt).getTime();
    return dateB - dateA;
  });
  const [postToDelete, setPostToDelete] = useState<string | null>(null);
  // post delete api function
  const handleDeletePost = async (index: number) => {
    if (postToDelete) {
      const response = await deletePostById(postToDelete);
      if (response?.status === 200) {
        // setOpennDialogg(false);
        handleCloseDialogg(index);
        setHomePosts((prevPosts) =>
          prevPosts.filter((post) => post._id !== postToDelete)
        );
        setIndividualUserPosts((prevPosts) =>
          prevPosts.filter((post) => post._id !== postToDelete)
        );
      }
    }
  };
  // date  coverting function
  const formatDateRelative = (dateString: string): string => {
    const currentDate = new Date();
    const inputDate = new Date(dateString);
    const timeDifference = currentDate.getTime() - inputDate.getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    if (daysDifference < 1) {
      if (hoursDifference < 1) {
        if (minutesDifference < 1) {
          return "Just Now";
        }
        return `${minutesDifference} minute${
          minutesDifference !== 1 ? "s" : ""
        } ago`;
      } else if (hoursDifference < 12) {
        if (hoursDifference >= 1) {
          return "1 hour ago";
        }
        // return `${hoursDifference} Hours ago`;
      } else {
        return "Today";
      }
    }
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return inputDate.toLocaleDateString(undefined, options);
  };

  // hyperlink converting function
  const renderContentWithLinks = (content: string) => {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const contentArray = content.split(urlRegex);
    return contentArray.map((segment, index) => {
      if (segment.match(urlRegex)) {
        // If the segment is a URL, render it as a hyperlink
        return (
          <a
            key={index}
            href={segment}
            target="_blank"
            rel="noopener noreferrer"
          >
            {segment}
          </a>
        );
      } else {
        // If the segment is plain text, render it as is
        return <span key={index}>{segment}</span>;
      }
    });
  };
  // created Posts auto update  in posts list function
  const updatePosts = (newPost: IPost) => {
    if (location.pathname === "/home") {
      setHomePosts((prevPosts: IPost[]) => [newPost, ...prevPosts]);
    } else if (location.pathname.match(/^\/profile\/posts\/[a-zA-Z0-9]+$/)) {
      setIndividualUserPosts((prevPosts: IPost[]) => [newPost, ...prevPosts]);
    }
  };
  // edited post auto  update in posts list function
  const updateExisitingPosts = (editPost: IPost) => {
    if (location.pathname === "/home") {
      setHomePosts((prevPosts) => [
        editPost,
        ...prevPosts.filter((post) => post._id !== editPost._id),
      ]);
    } else if (location.pathname.match(/^\/profile\/posts\/[a-zA-Z0-9]+$/)) {
      setIndividualUserPosts((prevPosts) => [
        editPost,
        ...prevPosts.filter((post) => post._id !== editPost._id),
      ]);
    }
  };

  // report Modal //

  const [openReport, setOpenReport] = React.useState(false);

  // const handleOpenReport = () => setOpenReport(true);
  // const handleCloseReport = () => setOpenReport(false);

  // report content //
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  const handleSubmitReport = () => [setOpenReport(false)];
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  if (isLoading) {
    return <Loader />;
  }

  const handlenavigateUser = (id: string) => {
    navigate(`/profile/posts/${id}`);
  };

  const containerSize = 150;
  const progressSize = 130;

  //////usermapping update function

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    setAboutMe(userMapping?.aboutMe);
  };
  const handleAboutMeChange = (e: any) => {
    setAboutMe(e.target.value);
  };
  // Banner edit and about me edit function for user mapping data
  const handleSaveClick = async () => {
    const formData = new FormData();
    formData.append("aboutMe", aboutMe);

    try {
      setIsEditing(false);
      await updateUserMappingDetails(selectedCommunityId, formData);

      // console.log("User mapping details updated successfully!");
    } catch (error) {
      console.error("Error updating user mapping details:", error);
    }
  };

  // console.log(loggedInUserData, "loggedInUserData");

  return (
    <>
      {location.pathname.match(/^\/profile\/posts\/[a-zA-Z0-9]+$/) && (
        <Grid container spacing={1} sx={{ height: "auto" }}>
          <Grid item xs={12}>
            <Paper
              elevation={0}
              sx={{
                // pb: "35px",
                borderRadius: "8px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {userMapping && (
                <Box sx={{ flex: 1 }}>
                  <Box>
                    {/* cover images edit */}
                    <div style={{ position: "relative" }}>
                      <img
                        // src="https://upload-community-files.s3.ap-south-1.amazonaws.com/static/default_banner.png"
                        src={
                          bannerImage ||
                          userMapping?.banner ||
                          "https://upload-community-files.s3.ap-south-1.amazonaws.com/static/default_banner.png"
                        }
                        alt="Cover"
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: 180,
                          objectFit: "cover",
                          borderRadius: "0px 0px 10px 10px",
                        }}
                      />
                      {loggedInUserData?.user?.id ===
                        userMapping?.user?._id && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 4,
                            marginLeft: "4px",
                            // padding: "3px",
                            // borderRadius: "50%",
                            // backgroundColor: "rgba(232, 243, 248, 1)",
                          }}
                        >
                          <label htmlFor="img1">
                            <EditIcon
                              sx={{
                                fontSize: "20px",
                                cursor: "pointer",
                                "&:hover": {
                                  color: "#3C5AA0",
                                },
                              }}
                            />
                          </label>
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "transparent",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: `${containerSize}px`,
                          height: `${containerSize}px`,
                          marginLeft: "20px",
                        }}
                      >
                        <img
                          src={userMapping?.user?.avatar}
                          alt=""
                          style={{
                            width: progressSize,
                            height: progressSize,
                            borderRadius: "50%",
                            position: "absolute",
                            objectFit: "cover",
                          }}
                        />
                        <CircularProgress
                          variant="determinate"
                          value={100}
                          style={{
                            position: "absolute",
                            color: "#EBEBEB",
                            width: `${progressSize}px`,
                            height: `${progressSize}px`,
                          }}
                          thickness={2}
                        />
                      </div>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          ml: "190px",
                        }}
                      >
                        <Stack direction="row" alignItems="center">
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: { xs: "15px", md: "20px" },
                              lineHeight: "35px",
                              color: "#1E1E1E",
                              fontFamily: "Inter",
                            }}
                          >
                            {/* {userMapping?.user?.firstName} */}
                            {userMapping?.user?.firstName +
                              " " +
                              userMapping?.user?.lastName}
                            <br />
                            {userMapping && (
                              <Chip
                                sx={{
                                  color: "white",
                                  // backgroundColor: "#3B9B7F",
                                  backgroundColor:
                                    singleRole
                                      ?.toLowerCase()
                                      .includes("superadmin") ||
                                    singleRole?.toLowerCase().includes("admin")
                                      ? "#3B7F9B"
                                      : "#3B9B7F",
                                  p: "auto",
                                  borderRadius: "4px",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  fontFamily: "Inter",
                                }}
                                label={userMapping?.slug || "NA"}
                              />
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: { xs: "13px", md: "15px" },
                              lineHeight: "35px",
                              color: "#2952A2",
                            }}
                          ></Typography>
                        </Stack>
                      </Box>
                      {loggedInUserData?.user?.id ===
                        userMapping?.user?._id && (
                        <Stack
                          sx={{
                            ml: "auto",
                            pr: "10px",
                            alignItems: "flex-start",
                            mt: "-35px",
                          }}
                        >
                          <EditIcon
                            onClick={handleEditClick}
                            sx={{
                              fontSize: "20px",
                              cursor: "pointer",
                              "&:hover": {
                                color: "#3C5AA0",
                              },
                            }}
                          />
                        </Stack>
                      )}
                    </div>
                  </Box>

                  <Typography
                    sx={{
                      pt: "15px",
                      paddingLeft: "2vw",
                      paddingRight: "2vw",
                      paddingBottom: "3vh",
                      coor: "#434343",
                      mt: "20px",
                      fontFamily: "Inter",
                    }}
                  >
                    {isEditing ? (
                      <Stack spacing={1}>
                        <TextField
                          value={aboutMe}
                          onChange={handleAboutMeChange}
                          size="small"
                          fullWidth
                          minRows={2}
                        />
                        <Stack
                          direction={"row"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"flex-start"}
                          spacing={4}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            onClick={handleSaveClick}
                            sx={{
                              textTransform: "capitalize",
                              backgroundColor: "#50A1CA",
                              color: "white",
                              boxShadow: "none",
                              "&:hover": { backgroundColor: "#50A1CA" },
                            }}
                          >
                            Save
                          </Button>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={handleEditClick}
                            sx={{
                              textTransform: "capitalize",
                              backgroundColor: "#50A1CA",
                              color: "white",
                              boxShadow: "none",
                              "&:hover": { backgroundColor: "#50A1CA" },
                            }}
                          >
                            Cancel
                          </Button>
                        </Stack>
                      </Stack>
                    ) : (
                      <Typography sx={{ fontSize: { xs: "13px", md: "15px" } }}>
                        {userMapping?.aboutMe}
                      </Typography>
                    )}
                  </Typography>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      )}
      <Paper elevation={0} sx={{ mb: 1, height: "auto", mt: 1 }}>
        <Stack
          direction={"row"}
          spacing={2}
          sx={{ paddingBottom: "10px", p: 2, pt: "4vh" }}
        >
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            variant="dot"
            sx={{ cursor: "pointer" }}
          >
            <Avatar
              onClick={() => {
                navigate(`/profile/posts/${userId}`);
              }}
              src={loggedInUserData?.user?.avatar}
              sx={{ backgroundColor: "#3C5AA0" }}
            >
              {loggedInUserData?.user?.avatar
                ? null
                : loggedInUserData?.user?.firstName?.charAt(0) +
                  "" +
                  loggedInUserData?.user?.lastName?.charAt(0)}
            </Avatar>
          </StyledBadge>
          <TextField
            onClick={handleOpenModal}
            size="small"
            sx={{ width: "50vw" }}
            placeholder="What's on your mind?"
            InputProps={{
              sx: {
                borderRadius: "100px",
                fontSize: { xs: "13px", md: "17px" },
              },
              readOnly: true, // Disables typing
            }}
            inputProps={{
              readOnly: true, // Disables typing
            }}
          />

          <Stack direction={"row"} sx={{ pt: "1vh", flexGrow: "1" }}>
            <AddPhotoAlternateIcon
              onClick={handleOpenModal}
              sx={{ color: "#50A1CA", mr: "0.5vw", cursor: "pointer" }}
            />
            <EmojiEmotionsIcon
              onClick={handleOpenModal}
              className="emoji-icon"
              sx={{ color: "#FE7F06", cursor: "pointer" }}
            />
          </Stack>
        </Stack>
        <AddPost
          open={isOpen}
          onClose={handleCloseModal}
          postHeder={mode === "Edit" ? "Edit Post" : "Add New Post"}
          selectedCommunity={[selectedCommunity.selectedCommunity]}
          updatePosts={updatePosts}
          placeHolder=""
          updateExistingPost={function (updatedPost: IPost): void {
            throw new Error("Function not implemented.");
          }}
        />
      </Paper>
      {postsToDisplay.length > 0 ? (
        <Paper elevation={0} sx={{ p: TotalPosts ? 1 : 0 }}>
          <Stack
            direction={"row"}
            spacing={1}
            alignItems={"baseline"}
            sx={{ paddingBottom: "10px" }}
          >
            {TotalPosts && (
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  color: "#000000",
                  display: TotalPosts ? "block" : "none",
                }}
              >
                Total Posts: {postsToDisplay.length}
              </Typography>
            )}
            {/* {TotalPosts && (
              <Stack direction={'row-reverse'} sx={{ flexGrow: '1' }}>
                <Button
                  href="/posts"
                  size='small'
                  sx={{
                    color: '#7A7A7A',
                    borderColor: '#7A7A7A',
                    borderRadius: '6px',
                    border: '1px solid #7A7A7A',
                  }}
                  startIcon={<SettingsIcon />}
                >
                  Manage posts
                </Button>
              </Stack>
            )} */}
          </Stack>
          <Divider sx={{ display: TotalPosts ? "block" : "none" }} />
          <Stack>
            {loading ? (
              <Loader />
            ) : (
              <Stack>
                {sortedPosts?.map((data: IPost, index: number) => (
                  <Stack key={index} sx={{ marginBottom: "5px" }}>
                    <Card elevation={0} sx={{ pl: 1, pr: 1 }}>
                      <CardHeader
                        sx={{ p: 1 }}
                        avatar={
                          <Avatar
                            onClick={() =>
                              handlenavigateUser(data?.createdBy?._id)
                            }
                            src={data?.createdBy?.avatar}
                            sx={{
                              backgroundColor: "#50A1CA",
                              cursor: "pointer",
                            }}
                          >
                            {data?.createdBy?.avatar
                              ? null
                              : data?.createdBy?.firstName?.charAt(0)}
                          </Avatar>
                        }
                        action={
                          loggedInUserData?.user?.id ===
                          data?.createdBy?._id ? (
                            <>
                              <Dialog
                                open={openDialogg[index]}
                                // onClose={handleCloseDialogg}
                                onClose={() => handleCloseDialogg(index)}
                                sx={{ textAlign: "center", fontSize: "small" }}
                              >
                                <DialogActions
                                  sx={{ backgroundColor: "#F0F9FF" }}
                                >
                                  <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    sx={{ backgroundColor: "#F0F9FF" }}
                                  >
                                    <Stack></Stack>
                                    <Stack></Stack>
                                    <Stack sx={{ backgroundColor: "#F0F9FF" }}>
                                      <Box
                                        onClick={() => {
                                          handleCloseDialogg(index);
                                        }}
                                        sx={{
                                          backgroundColor: "#50A1CA",
                                          padding: "4px",
                                          borderRadius: "50%",
                                          width: "20px",
                                          height: "20px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          color: "white",
                                          fontSize: "10px",
                                          mt: "0.5px",
                                          mr: "10px",
                                          cursor: "pointer",
                                          "&:hover": {
                                            // backgroundColor: "#50A1CA", // Background color on hover
                                            cursor: "pointer",
                                            border: "none",
                                          },
                                        }}
                                      >
                                        <Close sx={{ fontSize: "15px" }} />
                                      </Box>
                                    </Stack>
                                  </Stack>
                                </DialogActions>
                                <DialogTitle>
                                  <Typography
                                    sx={{
                                      fontSize: "15px",
                                      fontFamily: "Inter",
                                      // color: "red",
                                    }}
                                  >
                                    Are you sure you want to Delete
                                  </Typography>

                                  <Typography
                                    sx={{
                                      fontSize: "15px",
                                      fontFamily: "Inter",
                                      // color: "red",
                                    }}
                                  >
                                    the Post?
                                  </Typography>
                                </DialogTitle>
                                <Divider />
                                <Stack
                                  direction="row"
                                  justifyContent={"space-around"}
                                >
                                  <DialogActions>
                                    <Button
                                      size="small"
                                      onClick={() => {
                                        handleDeletePost(index);
                                      }}
                                      sx={{
                                        cursor: "pointer",
                                        pr: 1,
                                        textTransform: "capitalize",
                                        color: "White",
                                        fontFamily: "Inter",
                                        background:
                                          "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                                      }}
                                    >
                                      Delete
                                    </Button>
                                    {/* <Divider
                                      orientation="vertical"
                                      style={{ marginLeft: 25 }}
                                    /> */}
                                    <Stack>
                                      <Button
                                        size="small"
                                        variant="outlined"
                                        sx={{
                                          ml: 3,
                                          cursor: "pointer",
                                          textTransform: "capitalize",
                                          fontFamily: "Inter",
                                          color: "#50A1CA",
                                          border: "1px solid #50A1CA",
                                        }}
                                        onClick={() => {
                                          handleCloseDialogg(index);
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    </Stack>
                                  </DialogActions>
                                </Stack>
                              </Dialog>

                              <ButtonGroup
                                variant="outlined"
                                aria-label="outlined button group"
                                size="small"
                                key={data._id}
                              >
                                <Button
                                  onClick={() => {
                                    handleOpen(index);
                                    handleSelectPost(data);
                                    setSelectedPost(data);
                                  }}
                                  sx={{
                                    textTransform: "capitalize",
                                    border: "none",
                                    padding: 0,
                                    "&:hover": {
                                      color: "#3C5AA0",
                                      border: "none",
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                >
                                  <Tooltip title="Edit" placement="top">
                                    <IconButton>
                                      <EditIcon
                                        sx={{
                                          fontSize: "17px",
                                          "&:hover": {
                                            color: "#50A1CA",
                                          },
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Button>

                                <Button
                                  sx={{
                                    textTransform: "capitalize",
                                    border: "none",
                                    padding: 0,

                                    "&:hover": {
                                      color: "#3C5AA0",
                                      border: "none",
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                  onClick={() => {
                                    handleOpenDialogg(index);
                                    setPostToDelete(data._id);
                                  }}
                                >
                                  <Tooltip title="Delete" placement="top">
                                    <IconButton>
                                      <DeleteIcon
                                        sx={{
                                          fontSize: "17px",
                                          "&:hover": {
                                            color: "#50A1CA",
                                          },
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Button>
                              </ButtonGroup>
                            </>
                          ) : (
                            <></>
                          )
                        }
                        title={
                          <Typography
                            sx={{
                              //  color: "#1E1E1E",
                              //   background:
                              //   "-webkit-linear-gradient(90deg, #50A1CA 0%, #3B9B7F 100%);",

                              // WebkitBackgroundClip: "text",
                              // WebkitTextFillColor: "transparent",
                              fontSize: "13px",
                              fontWeight: "bold",
                              font: "Roboto",
                            }}
                          >
                            <Link
                              to={`/profile/posts/${data?.createdBy?._id}`}
                              style={{
                                textDecoration: "underline",
                                color: "black",
                                fontWeight: "500",
                              }}
                            >
                              {data?.createdBy?.firstName +
                                " " +
                                data?.createdBy?.lastName}
                            </Link>
                          </Typography>
                        }
                        subheader={
                          <Typography
                            sx={{
                              color: "#787878",
                              fontSize: "11px",
                              fontWeight: "400",
                              font: "Roboto",
                            }}
                          >
                            {" "}
                            {data?.mapping.length > 0
                              ? data.mapping
                                  .filter(
                                    (mapping) =>
                                      mapping?.community === selectedCommunityId
                                  )
                                  .map((mapping, index) => (
                                    <span key={index}>{mapping?.slug}</span>
                                  ))
                              : "NA"}{" "}
                            | {formatDateRelative(data?.updatedAt)}
                          </Typography>
                        }
                      />
                      <CardContent sx={{ pl: 1, pr: 1, pt: 0, pb: 1 }}>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            pt: 1,
                            // mx: 1,
                            color: "#000000",
                            fontSize: "13px",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {data?.content?.length > 190 ? (
                            showFullContents[data._id] ? (
                              <>
                                {renderContentWithLinks(data?.content)}
                                <span
                                  className="toggle-button"
                                  onClick={(e) => handleToggleContents(e, data)}
                                  style={{
                                    color: "#50A1CA",
                                    fontWeight: "500",
                                    cursor: "pointer",
                                  }}
                                >
                                  {/* <ArrowDropUpIcon /> */} &nbsp; &nbsp; Less
                                </span>
                              </>
                            ) : (
                              <>
                                {/* {data?.content?.slice(0, 150)}... */}
                                {renderContentWithLinks(
                                  data?.content?.slice(0, 190)
                                )}{" "}
                                ... &nbsp; &nbsp;
                                <span
                                  className="toggle-button"
                                  onClick={(e) => handleToggleContents(e, data)}
                                  style={{
                                    color: "#50A1CA",
                                    fontWeight: "500",
                                    cursor: "pointer",
                                  }}
                                >
                                  {/* <ArrowDropDownIcon /> */}
                                  Read More
                                </span>
                              </>
                            )
                          ) : (
                            <> {renderContentWithLinks(data?.content)}</>
                          )}
                        </Typography>
                      </CardContent>

                      <PostImages
                        images={data.images}
                        imageHeight={100}
                        imageWidth={100}
                      ></PostImages>

                      <CardActions disableSpacing>
                        <Favorite
                          post={data}
                          userId={userId}
                          handleHeartClick={handleHeartClick}
                        />

                        <Stack className="likes-comments">
                          <Stack className="likes">
                            <Typography variant="body2" color="text.secondary">
                              {data?.likedBy?.length}
                            </Typography>
                          </Stack>
                        </Stack>
                      </CardActions>
                      <Divider />
                    </Card>
                    {isModalOpen ? (
                      <AddPost
                        open={isModalOpen[index]}
                        onClose={() => handleClose(index)}
                        postHeder={
                          mode === "Edit" ? "Edit Post" : "Add New Post"
                        }
                        editPostData={selectedPost || undefined}
                        selectedCommunity={[
                          selectedCommunity?.selectedCommunity,
                        ]}
                        placeHolder={location?.pathname}
                        updatePosts={function (newPost: IPost): void {
                          throw new Error("Function not implemented.");
                        }}
                        updateExistingPost={updateExisitingPosts}
                      />
                    ) : (
                      ""
                    )}
                  </Stack>
                ))}
              </Stack>
            )}
          </Stack>
          <input
            type="file"
            name="files"
            id="img1"
            multiple
            accept="image/jpeg, image/png, image/gif"
            style={{ display: "none" }}
            onChange={handlePostImages}
          />
          <Modal
            open={openReport}
            // onClose={handleCloseReport}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleReport}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: { xs: 600, md: 600 },
                  mb: 2,
                }}
              >
                Reason for Reporting{" "}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: 460,
                  bgcolor: "background.paper",
                }}
              >
                <List component="nav" aria-label="main mailbox folders">
                  <ListItemButton
                    selected={selectedIndex === 0}
                    onClick={(event) => handleListItemClick(event, 0)}
                  >
                    <ListItemText primary="Spamming" />
                  </ListItemButton>
                  <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={(event) => handleListItemClick(event, 1)}
                  >
                    <ListItemText primary="Privacy Violation" />
                  </ListItemButton>
                  <ListItemButton
                    selected={selectedIndex === 2}
                    onClick={(event) => handleListItemClick(event, 2)}
                  >
                    <ListItemText primary="Misinformation" />
                  </ListItemButton>
                  <ListItemButton
                    selected={selectedIndex === 3}
                    onClick={(event) => handleListItemClick(event, 3)}
                  >
                    <ListItemText primary="Off-Topic" />
                  </ListItemButton>
                  <ListItemButton
                    selected={selectedIndex === 4}
                    onClick={(event) => handleListItemClick(event, 4)}
                  >
                    <ListItemText primary="Violation of Guidelines" />
                  </ListItemButton>
                  <ListItemButton
                    selected={selectedIndex === 5}
                    onClick={(event) => handleListItemClick(event, 5)}
                  >
                    <ListItemText primary="Other" />
                  </ListItemButton>
                </List>
                <TextField
                  id="outlined-basic"
                  label="Additional details or comments"
                  variant="outlined"
                  placeholder="Type Here"
                  multiline
                  rows={3}
                  fullWidth
                ></TextField>
                <Button
                  fullWidth
                  sx={{
                    background: "linear-gradient(to right,#50A1CA, #3B9B7F)",
                    color: "#fff",
                    textTransform: "none",
                    mt: 2,
                    borderRadius: "3vh",
                  }}
                  onClick={handleSubmitReport}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Modal>
        </Paper>
      ) : (
        <NoPosts />
      )}
    </>
  );
};

export default HomePost;
