import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import { Login } from "./components/Auth/Login/Login";
import { useContext } from "react";
import { AuthContext } from "./contexts/Auth.context";
import Loader from "./components/Loader/Loader";
import { Messages } from "./components/messages/Messages";
import { Register } from "./components/Auth/Login/Register";
import Profile from "./components/Layout/components/NavigationBar/Profile";
import { ProfileEdit } from "./components/Auth/Login/ProfileEdit";
import ExploreCommunities from "./components/ExploreCommunities/ExploreCommunities";
import ExploreCreateCommunities from "./components/ExploreCommunities/ExploreCreateCommunities";
import { Home } from "./components/Home/Home";
import Activities from "./components/Activities/Activities";
import NoAuthLayout from "./components/Layout/NoAuthLayout";
import CommunityInvitations from "./components/Invitations/CommunityInvitations";
import TrendingCommunitiesGird from "./components/ExploreCommunities/GirdViewCommunities/TrendingCommunitiesGird";
import InvitiesCommunitiesGird from "./components/ExploreCommunities/GirdViewCommunities/InvitiesCommunitiesGird";
import JoinedCommunitiesGird from "./components/ExploreCommunities/GirdViewCommunities/JoinedCommunitiesGird";
import AdminCommunitiesGird from "./components/ExploreCommunities/GirdViewCommunities/AdminCommunitiesGird";
import Members from "./components/members/Members";
import { UserPayments } from "./components/Payments/UserPayments";
import NoAuthCommunityLayout from "./components/AboutShare/NoAuthCommunity/NoAuthCommunity";
import { PlansList } from "./components/Plans/PlanList";
// import AboutCommunityShare from "./components/AboutShare/AboutCommunityShare";
import About from "./components/community/aboutCommunity/About";
import NewLayout from "./components/Layout/NewLayout";
import { SubscriptionList } from "./components/Subscription/SubscriptionList";
import SubscriptionLayout from "./components/Subscription/SubscriptionLayout/SubscriptionLayout";
import { Courses } from "./components/Courses/Courses";
import ViewCourse from "./components/Courses/ViewCourse";
import { AiPost } from "./components/Posts/AIpost";
import NewAboutShare from "./components/AboutShare/NewAboutShare";
import UserSubscription from "./components/Plans/SubscriptionList/UserSubscription";

// import { NotFound } from "./components/404/NotFound";
// import Invitation from "./components/Invitations/Invitation";
// import { Help } from "./components/help/Help";
// import { Settings } from "./components/settings/Settings";
// import { Payments } from "./components/Payments/Payments";
// import { CreateCommunity } from "./components/community/CreateCommunity";
// import { BuildCommunity } from "./components/community/BuildCommunity";
// import { AddOns } from "./components/add-ons/AddOns";
// import { CreateAddOn } from "./components/add-ons/CreateAddOn";
// import { Dashboard } from "./components/Dashboard/Dashboard";
// import FAQ from "./components/FAQ/FAQ";
// import { AddPlan } from "./components/Plans/AddPlan";
// import CreateCommunity3 from "./components/community/CreateCommunity3";
// import InvitesList from "./components/Invitations/CommunityInvitations/InvitesList";
// import { Dashboard } from "./components/Dashboard/Dashboard";

const RequireAuth = (props: any) => {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  if (authContext.loading) {
    return <Loader />;
  }
  // return authContext.isAuthenticated ? (
  //   props.children
  // ) : location.pathname.includes("/communities/") ? (
  //   <>
  //   <NoAuthCommunityLayout />
  // </>
  // ): (
  //   <Navigate to={"/login"} />
  // );

  if (authContext.isAuthenticated) {
    return props.children;
  } else if (location.pathname.includes("/communities/")) {
    return (
      <>
        <NoAuthCommunityLayout />
      </>
    );
  } else {
    // Redirect to the login page for non-authenticated users
    return <Navigate to="/login" />;
  }
};

const LoginWrapper = (props: any) => {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  // console.log(location.pathname, "path");
  if (authContext.loading) {
    return <Loader />;
  }
  return authContext.isAuthenticated ? (
    <Navigate to="/" replace={true} />
  ) : location.pathname.includes("/communities/:id/about") ? (
    <NoAuthCommunityLayout />
  ) : (
    props.children
  );
};

function App() {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <>
      <Routes>
        <Route
          element={
            <RequireAuth>
              <NewLayout />
            </RequireAuth>
          }
        >
          <Route path="/home/:id/:token" element={<Home />} />
          <Route path="/" element={<Navigate to="/home" replace={true} />} />
          <Route path="/plans-lists" element={<PlansList />} />
          <Route path="/course-lists" element={<Courses />} />
          <Route path="/course-lists/view/:id" element={<ViewCourse />} />
          <Route path="/members" element={<Members />} />
          <Route path="/profile/posts/:id" element={<Profile />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/home" element={<Home />} />
          <Route path="/payment-success" element={<Home />} />
          <Route path="/payment-failure" element={<Home />} />
          <Route path="/ai-generate" element={<AiPost />} />
          <Route path="/invitations" element={<CommunityInvitations />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/community/:id/about" element={<About />} />

          <Route
            path="/paymentss?status=failed&key=failed&ref_id=S24020914CXG54"
            element={<UserPayments />}
          />
        </Route>
      </Routes>

      <Routes>
        <Route
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route path="/payments" element={<UserPayments />} />
          <Route
            path="/payments/:status/:key/:amount"
            element={<UserPayments />}
          />
          <Route path="/user-subscription/:id" element={<UserSubscription />} />
          <Route path="/profile/:id" element={<ProfileEdit />} />

          {/* <Route path="/plans" element={<PlansList />} /> */}
          {/* <Route
            path="/plans/new"
            element={<AddPlan selectedCommunity={[]} />}
          /> */}
          {/* <Route
            path="/plans/:id"
            element={<AddPlan selectedCommunity={[]} />}
          /> */}
          {/* <Route path="/members" element={<Members />} /> */}
          {/* <Route path="/users/add" element={<AddUser />} /> */}
          {/* <Route path="/users/invitations" element={<Invitation />} /> */}
          {/* <Route path="/invitations" element={<CommunityInvitations />} /> */}
          {/* <Route path="/create-community" element={<CreateCommunity3 />} /> */}
          {/* <Route
            path="/communities/add"
            element={<CreateCommunity communitydata={null} />}
          /> */}
          {/* <Route
            path="/communities/:id"
            element={<CreateCommunity communitydata={null} />}
          /> */}
          {/* <Route path="/communities/build/:communityId" element={<BuildCommunity />} /> */}
          {/* <Route path="/add-ons" element={<AddOns />} />
          <Route path="/add-ons/create" element={<CreateAddOn />} /> */}
          {/* <Route path="/profile" element={<Profile />} /> */}
          {/* <Route path="/help" element={<Help />} />
          <Route path="/settings" element={<Settings />} /> */}
          {/* <Route path="/faq" element={<FAQ communityId="community1" />} /> */}
          {/* <Route path="/community/:id/about" element={<About/>}/> */}
        </Route>
        <Route
          path="/login"
          element={
            <LoginWrapper>
              <Login />
            </LoginWrapper>
          }
        />
      </Routes>

      <Routes>
        {isAuthenticated ? (
          <Route path="/communities/:id/about" element={<NewAboutShare />} />
        ) : (
          <Route path="/communities/:id/about" element={<NewAboutShare />} />
        )}
      </Routes>

      <Routes>
        <Route
          element={
            <RequireAuth>
              <SubscriptionLayout />
            </RequireAuth>
          }
        >
          <Route path="/subscriptions" element={<SubscriptionList />} />
        </Route>
      </Routes>

      <Routes>
        <Route
          element={
            <RequireAuth>
              <NoAuthLayout />
            </RequireAuth>
          }
        >
          <Route path="/explore-communities" element={<ExploreCommunities />} />
          <Route
            path="/admin-communities"
            element={<AdminCommunitiesGird data={[]} />}
          />
          <Route
            path="/joined-communities"
            element={<JoinedCommunitiesGird />}
          />
          <Route
            path="/invites-communities"
            element={<InvitiesCommunitiesGird />}
          />
          <Route
            path="/trending-communities"
            element={<TrendingCommunitiesGird />}
          />
          <Route
            path="/explore-create-community"
            element={<ExploreCreateCommunities />}
          />

          <Route
            path="/explore-create-community/:token"
            element={<ExploreCreateCommunities />}
          />
          <Route path="/profile-edit/:id" element={<ProfileEdit />} />
        </Route>
      </Routes>

      <Routes>
        <Route path="/register" element={<Register />} />
      </Routes>
    </>
  );
}

export default App;
