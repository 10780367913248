import { Box, Stack, Typography } from "@mui/material";
import CommunityBrief from "./CommunityBrief";
import HomeCreateCommunity from "./HomeCreateCommunity";
import { Suggestions } from "./SuggestionsCommunity/Suggestions";

const HomeRightBar = () => {
  // const location = useLocation();
  // const isProfilePage = /^\/profile/.test(location.pathname);

  // const BriefCommunity = location.pathname === "/home" || location.pathname === "/invites-communities" || /^\/community\/[a-zA-Z0-9]+\/about$/.test(location.pathname) || location.pathname === "/create-post" || isProfilePage;

  return (
    <Box
      sx={{
        display: { xs: "none", md: "none", lg: "block" },
        position: "sticky",
        overflowY: "auto",
      }}
    >
      <Box>
        <Stack spacing={1.5}>
          <CommunityBrief />
          <HomeCreateCommunity />

          {/* <Box
            sx={{
              alignItems: "center",
              borderRadius: "10px",
            }}
          >
            <Typography
              mt={1}
              sx={{
                color: "#787878",
                fontWeight: 500,
                fontSize: "14px",
                pb: 1,
              }}
            >
              Suggestions for you
            </Typography>
            <Suggestions />
          </Box> */}
        </Stack>
        {/* {!isProfilePage && <Suggestions />}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: 400,
            pt: 1,
            pb: 1,
            borderRadius: "10px",
          }}
        >
          {isProfilePage && (
            <Typography
              mt={1}
              sx={{ color: "#787878", fontWeight: 500, fontSize: "14px" }}
            >
              Notifications
            </Typography>
          )} */}
        {/* </Box> */}
      </Box>
      <Box
          sx={{
           
            alignItems: "center",
            // maxWidth: 400,
            pt: 1,
            
            borderRadius: "10px",
          }}
        >
          <Typography
            mt={1}
            sx={{ color: "#787878", fontWeight: 500, fontSize: "14px",pb: 1, }}
          >
            Suggestions for you
          </Typography>
          <Box sx={{height:'36vh', backgroundColor:'white', borderRadius:'10px'}}>
          <Suggestions />
          </Box>
        </Box>
      {/* <SuggestionsCommunity /> */}
      {/* <SuggestionsList /> */}
    </Box>
  );
};
export default HomeRightBar;
