// import * as React from "react";
import Box from "@mui/material/Box";
import { Grid, } from "@mui/material";
import { styles } from "./SubscriptionLayout.styles";
import { Outlet } from "react-router-dom";
import SubscriptionLayoutHeader from "./SubscriptionLayoutHeader";







export default function NoAuthCommunityLayout() {

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <Box sx={styles.main}>

          <SubscriptionLayoutHeader />

          <Box sx={styles.contentSection}>

            <Outlet />
          </Box>
        </Box>
      </Grid>

    </Grid>
  );
}
