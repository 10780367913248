import { useSelector } from "react-redux";
import PostAiSuggestions from "./PostAiSuggestions";



export const AiPost = () => {


    const selectedCommunity = useSelector((state: any) => state.selectedCommunity.selectedCommunity);

    const community_name = selectedCommunity?.name
    return (
        <>

            <PostAiSuggestions community_name={community_name} />


        </>
    )
}