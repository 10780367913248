
import {Paper, Stack, Typography } from "@mui/material";

// Use lowercase for the function name
export const NoNotifications = () => {
  return (
    <>
      <Paper elevation={0}>
        <Stack
          spacing={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ paddingBottom: "10px", p: 2 }}
        >
          <img
            src={require("./../../assets/icons/svg-icons/No Post.png")} // Replace with the actual image path
            alt=""
            style={{ maxWidth: "100%" }} // Optional: Adjust the image size
          />
          <Typography>You have no new notifications </Typography>
          <Typography>We'll alert you when you get any notifications!</Typography>
          {/* <Button size='small' variant="contained" href="/">Go back to Home</Button> */}
        </Stack>
      </Paper>
    </>
  );
};
