import axios, { AxiosError } from "axios";
import { BASE_URL_V2 } from "../configurations/url.config";







export const fetchSubscriptionByCommunityId = async (token: string, id: string) => {
  try {
    const response = await axios.get(`${BASE_URL_V2}/subscriptions/${id}/subscription`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    // console.log(response, "response");
    return response.data?.data; 
  } catch (err) {
    return { status: 500, data: [] };
  }
};


export const fetchSubscriptionByCommunityAndPlanId = async (token: string, id: string, planId:string) => {
    try {
      const response = await axios.get(`${BASE_URL_V2}/subscriptions/${id}/subscription/${planId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      // console.log(response, "response");
      return response.data?.data; 
    } catch (err) {
      return { status: 500, data: [] };
    }
  };


  export const createSubscriptionSequences = async (
    token: string,
    communityId: string,
    planId: string,
  ) => {
    try {
      const response = await axios.post(
        `${BASE_URL_V2}/subscriptions/${communityId}/create-fetch-subscription/${planId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      
      return response?.data?.data || [];
    } catch (err: AxiosError | any) {
      if (err.response) {
        return {
          status: err.response.status,
          data: err.response.data,
        };
      }
      return { status: 500, data: [] };
    }
  };
  
  
  


  