import React, { useContext, } from "react";
import {
  Avatar,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  SelectChangeEvent,
  Typography,
  styled,
} from "@mui/material";

// import {

//   Card,

//   ListItem,
//   ListItemAvatar,
//   ListItemText,

// } from "@mui/material";

import { Box, Stack } from "@mui/system";
import { AppBar } from "../../Layout/components/NavigationBar/NavigationBar.styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { AuthContext } from "../../../contexts/Auth.context";
// import { useLocation } from "react-router-dom";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Group401Icon } from "../../../assets/icons";
// import { useCommunity } from "../../../hooks/useCommunity";
import store from "../../../store";
import { useSelector } from "react-redux";
import Close from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
// import { ICommunity } from "../../../models/communities.modal";

interface INavbarProps { }
// eslint-disable-next-line no-empty-pattern
const SubscriptionLayoutHeader = ({ }: INavbarProps) => {
  useContext(AuthContext);
  const { isAuthenticated, logout } = useContext(AuthContext);
  // const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // const avatarProps = {
  //   ...stringAvatar(`${user?.firstName} ${user?.lastName}`),
  // };

  // const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  // const handleProfileClick = () => {
  //   handleMenuClose();
  // };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // const location = useLocation();

  //filter uncheck logic start
  // const [checkboxes, setCheckboxes] = useState<{ [key: string]: boolean }>({
  //   checkbox1: false,
  //   checkbox2: false,
  //   checkbox3: false,
  //   checkbox4: false,
  //   checkbox5: false,
  //   checkbox6: false,
  // });

  // const [open, setOpen] = useState(false);
  // const { communities } = useCommunity();
  // const [filteredUsers, setFilteredUsers] = useState<ICommunity[]>([]);
  // const [searchQuery, setSearchQuery] = useState<string>("");

  // useEffect(() => {
  //   const filteredUser = communities.filter((user) =>
  //     `${user.title} ${user.fullAddress}`
  //       .toLowerCase()
  //       .includes(searchQuery.toLowerCase())
  //   );
  //   setFilteredUsers(filteredUser);
  // }, [communities, searchQuery]);

  const state = store.getState();
  const loggedInUserData = useSelector(() => {
    return state?.loggedInUser;
  });

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  // const menuId = "primary-search-account-menu";

  const renderMenu = (
    <>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {isAuthenticated && (
          <MenuItem onClick={logout}>
            <Stack
              direction={"row"}
              spacing={1}
              sx={{ color: "#575757", fontWeight: "400" }}
            >
              <LogoutIcon /> <Typography>Logout</Typography>
            </Stack>
          </MenuItem>
        )}
      </Menu>
    </>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <Group401Icon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton size="large">
          <Badge>
            <NotificationsIcon sx={{ color: "#3C5AA0", fontSize: "4vh" }} />
          </Badge>
          <p style={{ marginLeft: "11px", color: "black", fontSize: "15px" }}>
            Notifications
          </p>
        </IconButton>
      </MenuItem>
    </Menu>
  );


  const [opens, setOpens] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("xs");

  const handleClickOpens = () => {
    setOpens(true);
  };

  const handleCloses = () => {
    setOpens(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleMaxWidthChange = (event: SelectChangeEvent<typeof maxWidth>) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  return (
    <>
      <Box
        component="nav"
        sx={{
          display: {
            xs: "none",
            sm: "block",
            md: "block",
            lg: "block",
            xl: "block",
            xxl: "block",
          },
        }}
      >
        <AppBar sx={{ backgroundColor: "#ffffff" }}>
          <Stack p={1}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={3}>
                {/* {/\/(explore-communities|explore-create-community|profile-edit\/\d+)/.test(
                  location.pathname
                ) ? null : (
                  <Stack>
                    <FormControl>
                      <Autocomplete
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={communities.map((Option) => Option.name)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search & join community"
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                )} */}
                <Link to="/explore-communities" >
                  <Box
                    ml={3}
                    mt={1}
                    component={"img"}
                    src={require("../../../assets/images/Communn-Logo.png")}
                    alt=""
                    sx={{ width: "45%" }}
                  />
                </Link>
              </Grid>
              <Grid item xs={7}>

              </Grid>

              <Grid item xs={2} sx={{ textAlign: "right" }} spacing={10}>
                <Box sx={{ flexGrow: 1 }} />
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      md: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    },
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      marginLeft: "10px",
                    }}
                  >
                    {/* <IconButton size="large">
                      <Badge>
                        <NotificationsIcon
                          sx={{ color: "#565656", fontSize: "4vh" }}
                        />
                      </Badge>
                    </IconButton> */}
                  </div>

                  <IconButton
                    disableRipple
                    size="small"
                    edge="end"
                    aria-label="account of current user"

                    aria-haspopup="true"
                    onClick={handleClickOpens}
                    color="inherit"
                    sx={{ ml: 5 }}
                  >
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      variant="dot"
                    >
                      <Avatar
                        src={loggedInUserData?.user?.avatar}
                        sx={{ backgroundColor: "#3C5AA0" }}
                      >
                        {loggedInUserData?.user?.avatar
                          ? null
                          : loggedInUserData?.user?.firstName?.charAt(0) +
                          "" +
                          loggedInUserData?.user?.lastName?.charAt(0)}
                      </Avatar>
                    </StyledBadge>
                  </IconButton>
                </Box>
                <Box sx={{ display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <MoreIcon />
                  </IconButton>
                </Box>


                <Box
                  onClick={handleClickOpens}
                  sx={{ display: { xs: "flex", md: "none" } }}
                >
                  <IconButton
                    disableRipple
                    size="large"
                    edge="end"
                    aria-label="account of current user"

                    aria-haspopup="true"
                    onClick={handleClickOpens}
                    color="inherit"
                  >
                    <Avatar
                      src={loggedInUserData?.user?.avatar}
                      sx={{ backgroundColor: "#3C5AA0" }}
                    >
                      {loggedInUserData?.user?.avatar
                        ? null
                        : loggedInUserData?.user?.firstName?.charAt(0) +
                        "" +
                        loggedInUserData?.user?.lastName?.charAt(0)}
                    </Avatar>
                  </IconButton>
                </Box>


                <Dialog maxWidth={maxWidth} open={opens} onClose={handleCloses}>
                  <DialogActions sx={{ backgroundColor: "#F0F9FF" }}>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      sx={{ backgroundColor: "#F0F9FF" }}
                    >
                      <Stack></Stack>
                      <Stack></Stack>
                      <Stack sx={{ backgroundColor: "#F0F9FF" }}>
                        <Box
                          onClick={handleCloses}
                          sx={{
                            backgroundColor: "#50A1CA",
                            padding: "4px",
                            borderRadius: "50%",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            fontSize: "10px",
                            mt: "0.5px",
                            mr: "10px",
                            cursor: "pointer",
                            "&:hover": {
                              // backgroundColor: "#50A1CA", // Background color on hover
                              cursor: "pointer",
                              border: "none",
                            },
                          }}
                        >
                          <Close sx={{ fontSize: "15px" }} />
                        </Box>
                      </Stack>
                    </Stack>
                  </DialogActions>
                  <DialogTitle>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        font: "Roboto",
                        // color: "red",
                      }}
                    >
                      Are you sure you want to log out?
                    </Typography>
                  </DialogTitle>
                  <Divider />

                  <Stack
                    direction="row"
                    sx={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <DialogActions>
                      <Button
                        size="small"
                        onClick={logout}
                        sx={{
                          cursor: "pointer",
                          pr: 1,
                          textTransform: "capitalize",
                          color: "White",
                          background:
                            "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                        }}
                      >
                        Yes
                      </Button>
                      <Divider orientation="vertical" style={{ marginLeft: 25 }} />
                    </DialogActions>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{
                        cursor: "pointer",
                        textTransform: "capitalize",
                        color: "#50A1CA",
                        border: "1px solid #50A1CA",
                      }}
                      onClick={handleCloses}
                    >
                      No
                    </Button>
                  </Stack>
                </Dialog>

                <Box sx={{ display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <MoreIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </AppBar>

        {renderMobileMenu}
        {renderMenu}
      </Box>
    </>
  );
};

export default SubscriptionLayoutHeader;
