import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { getStaticValue } from "../../utils/StaticValues";
import { IMultiMedia } from "../../models/multi-media.model";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { usePayment } from "../../hooks/usePayment";
import { Helmet } from "react-helmet";
import PaymentSuccess from "../Payments/PaymentSuccessPopUp";
import { useNavigate } from "react-router-dom";
import { IPaymentList } from "../../models/payment.model";
import PaymentFailed from "../Payments/PaymentFailedPopUp";
import store from "../../store";
import { useSelector } from "react-redux";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import { isBefore, parseISO } from "date-fns";
import { metaConfig } from "../../utils/constants";

interface IParam {
  name: string;
  value: string;
  icon: string;
}

export interface ISubscribers {
  avatar: string;
  firstname: string;
  lastname: string;
  email: string;
  _id: string;
}

export interface ISubscriptionDue {
  nextDue: string;
  plan: string;
  user: string;
  _id: string;
}

export interface IPlan {
  _id: string;
  name: string;
  description: string;
  image: IMultiMedia | string;
  parameters: IParam[];
  createdAt: string;
  updatedAt: string;
  status: string;
  duration: string;
  interval: string;
  billingFrequency: string;
  summary: string;
  promocode: string;
  totalPlanValue: number;
  startDate: string;
  offerValue: number;
  community: string;
  document: IMultiMedia | " ";
  subscribers: ISubscribers[];
  subscriptionDue: ISubscriptionDue[];
}

interface IPlanProps {
  plan: IPlan;
  onClick?: () => void;
  plans: any[];
}

const UserPlan: React.FC<IPlanProps> = ({ plan, plans }) => {
  const { initiatePaymentByIds, getPaymentStatusById } = usePayment();
  const [retryLoadingStates, setRetryLoadingStates] = useState<boolean[]>(
    Array(plans?.length).fill(false)
  );
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState<IPaymentList>();
  const [community, setCommunity] = useState("");
  // const { id } = useParams();
  // console.log(id, "id");
  const [planId, setplanId] = useState("");
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [timer, setTimer] = useState(5);
  const [failureOpen, setFailureOpen] = React.useState(false);
  // const [expiredPopupOpen, setExpiredPopupOpen] = useState(false);

  enum PaymentStatus {
    SUCCESS = "SUCCESS",
    PENDING = "PENDING",
    FAILED = "FAILED",
    USERCANCELLED = "USERCANCELLED",
  }

  const paymentResponse = (response: any) => {
    const tnxId = response?.transactionId;
    const transaction = response?.transaction as IPaymentList;
    if (transaction) setTransaction(transaction);

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const width = Math.min(1000, screenWidth);
    const height = Math.min(1000, screenHeight);
    const left = (screenWidth - width) / 2;
    const top = (screenHeight - height) / 2;

    // Debug: Log the user agent
    console.log("User Agent:", navigator.userAgent);

    // Check if it's an iOS device
    const isIOSDevice = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isMacOSDevice =
      /Macintosh|MacIntel|MacPPC|Mac68K/i.test(navigator.userAgent) &&
      !(navigator.maxTouchPoints && navigator.maxTouchPoints > 1);

    // Debug: Log checks
    console.log("isIOSDevice:", isIOSDevice, "isMacOSDevice:", isMacOSDevice);

    // Debug: Log if webkit is available
    console.log("window.webkit:", (window as any).webkit);

    // If running in a WebView (like WKWebView) with webkit.messageHandlers support
    if (
      (isIOSDevice || isMacOSDevice) &&
      (window as any).webkit?.messageHandlers?.webToNativeInterface
    ) {
      console.log("webkit.messageHandlers.webToNativeInterface exists");

      const message = {
        action: "openNewWindow",
        data: JSON.stringify({
          openUrl: response.url,
          closeWindow: [
            { regex: "(^https://testpay\\\\.easebuzz\\\\.in/error)" },
            {
              regex:
                "(^https://api\\\\.onecommunn\\\\.com/api/v1/payments/failure)",
            },
            {
              regex:
                "(^https://api\\\\.onecommunn\\\\.com/api/v1/payments/success)",
            },
          ],
        }),
      };

      (window as any).webkit.messageHandlers.webToNativeInterface.postMessage(
        message
      );
      console.log("Posted message:", message);
    } else {
      // This will run on non-iOS/macOS or in browsers like Safari
      console.log("Opening window in non-iOS/macOS device (or in Safari)");
      const windowRef = window.open(
        response.url,
        `addressbar=no,directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no, width=${width},height=${height},left=${left},top=${top}`
      );

      const intervalRef = setInterval(async () => {
        const paymentStatus = await getPaymentStatusById(tnxId);
        if (paymentStatus && paymentStatus.length > 0) {
          clearInterval(intervalRef);
          windowRef?.close();
          if (paymentStatus[0]?.status === PaymentStatus.SUCCESS) {
            setSuccessOpen(true);
          } else {
            setFailureOpen(true);
          }
        }
      }, 1000);
    }
  };

  const handleSuccessClose = () => {
    setTimer(5);
    navigate("/payments");
    setSuccessOpen(false);
  };

  const handleFailureClose = () => {
    setTimer(5);
    navigate("/plans-lists");
    setFailureOpen(false);
  };
  const handleRetryClick = async (
    communityId: string,
    planId: string,
    id: string
  ) => {
    setCommunity(communityId);
    setplanId(planId);
    setRetryLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[parseInt(id, 10)] = true;
      return newStates;
    });

    const currentDueDate = "24/09/2025";
    const response = await initiatePaymentByIds(
      communityId,
      planId,
      currentDueDate
    );
    setRetryLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[parseInt(id, 10)] = false;
      return newStates;
    });
    paymentResponse(response);
  };

  const state = store.getState();
  const loggedInUserData = useSelector(() => {
    return state?.loggedInUser;
  });

  const isDueDatePast = (dueDate: string) => {
    const currentDate = new Date();
    // console.log(dueDate, "dueDate");
    return isBefore(parseISO(dueDate), currentDate);
  };

  const isAppleDevice = /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent);

  return (
    <>
      <Helmet>
        <Helmet>
          <title>{metaConfig.title.plans}</title>
          <meta name="description" content={metaConfig.description} />
          <meta name="format-detection" content="telephone=no" />
          <meta name="author" content={metaConfig.description} />
          <meta name="keywords" content={metaConfig.description} />
        </Helmet>
      </Helmet>

      {plan.status === "ACTIVE" && (
        <Card sx={{ pt: 1.5, pb: 1.5, pl: 2, pr: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={3}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Stack>
                <Card elevation={0} sx={{ pb: 1 }}>
                  <CardMedia
                    component="img"
                    alt="No posts"
                    image={
                      typeof plan?.image === "object"
                        ? plan?.image?.value
                        : require("./../../assets/images/NoPlanImage.png")
                    }
                    sx={{
                      objectFit: "cover",
                      borderRadius: "10px",
                      width: "100%",
                      height: { xs: "15vh", md: "20vh" },
                    }}
                  />
                </Card>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 500,
                    fontSize: "20px",
                    color: "#000000",
                    fontFamily: "Inter",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  {plan?.name.length < 30
                    ? plan?.name
                    : plan?.name.slice(0, 30) + ".."}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 500,
                    fontSize: "15px",
                    color: "#000000",
                    fontFamily: "Inter",
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {plan?.name.length < 30
                    ? plan?.name
                    : plan?.name.slice(0, 30) + ".."}
                </Typography>
              </Box>
              <Stack
                alignItems={"center"}
                sx={{ mt: 0, ml: 0, display: { xs: "block", md: "none" } }}
              >
                <Typography
                  sx={{
                    color: "#464646",
                    mt: 1,
                    fontSize: { xs: "12px", md: "13px" },
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    textAlign: "justify",
                  }}
                >
                  {plan?.description}
                </Typography>
                {plan?.document ? (
                  <a
                    href={(plan?.document as IMultiMedia)?.value || ""}
                    download
                  >
                    <Tooltip title="Download Document" placement="left-start">
                      <IconButton>
                        <CloudDownloadOutlinedIcon
                          sx={{ cursor: "pointer", color: "#50A1CA" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </a>
                ) : (
                  ""
                )}
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              display={"flex"}
              sx={{ alignItems: { xs: "end", md: "start" } }}
            >
              <Box>
                <Stack
                  direction={"row"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"start"}
                >
                  <Typography
                    sx={{
                      color: "#787878",
                      fontSize: { xs: "14px", md: "16px" },
                      mr: "2px",
                      fontFamily: "Inter",
                    }}
                  >
                    ₹
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="#50A1CA"
                    fontWeight={"500"}
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      fontFamily: "Inter",
                    }}
                  >
                    {plan?.offerValue === 0
                      ? plan?.totalPlanValue
                      : plan?.offerValue}{" "}
                    / {plan?.interval}{" "}
                    {plan?.interval > "1"
                      ? `${getStaticValue(plan?.duration)}s`
                      : getStaticValue(plan?.duration)}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"start"}
                >
                  {plan.offerValue !== 0 ? (
                    <>
                      <Typography
                        sx={{
                          color: "#787878",
                          fontFamily: "Inter",
                          fontSize: { xs: "14px", md: "16px" },
                        }}
                      >
                        ₹
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#787878",
                          fontSize: { xs: "14px", md: "16px" },
                          textDecorationLine: "line-through",
                          fontFamily: "Inter",
                        }}
                      >
                        {plan?.totalPlanValue}
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )}
                </Stack>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"start"}
              alignItems={"end"}
              direction={"row"}
              sx={{ pb: 1 }}
            >
              <Stack
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                direction={"row"}
                sx={{ mb: 1 }}
                spacing={1}
              >
                {plan?.subscriptionDue.some(
                  (planDue) => planDue.user === loggedInUserData?.user?.id
                ) ? (
                  <>
                    {plan?.subscriptionDue.slice(-1).map((due, index) => (
                      <Typography
                        key={due._id}
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "12px",
                          color: "orange",
                        }}
                      >
                        Due on: {new Date(due.nextDue).toLocaleDateString()}
                      </Typography>
                    ))}
                  </>
                ) : null}
              </Stack>
              <Stack
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                direction={"row"}
                sx={{ mb: 1 }}
              >
                {plan?.subscribers?.some(
                  (subscriber) => subscriber._id === loggedInUserData?.user?.id
                ) ? (
                  plan.subscriptionDue.every((due) =>
                    isDueDatePast(due.nextDue)
                  ) ? (
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "13px",
                          md: "15px",
                          fontFamily: "Inter",
                          fontWeight: "500",
                          textAlign: "center",
                        },
                      }}
                    >
                      <CircleRoundedIcon
                        sx={{ color: "red", fontSize: "13px" }}
                      />{" "}
                      Expired
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "13px",
                          md: "15px",
                          fontFamily: "Inter",
                          fontWeight: "500",
                          textAlign: "center",
                        },
                      }}
                    >
                      <CircleRoundedIcon
                        sx={{ color: "#27AE1B", fontSize: "13px" }}
                      />{" "}
                      Active
                    </Typography>
                  )
                ) : null}
              </Stack>

              {/* {isAppleDevice ? (
                <Button
                  variant="contained"
                  size="large"
                  disabled={
                    plan?.subscribers?.some(
                      (subscriber) =>
                        subscriber._id === loggedInUserData?.user?.id
                    ) &&
                    !plan.subscriptionDue.every((due) =>
                      isDueDatePast(due.nextDue)
                    )
                  }
                  onClick={() =>
                    handleRetryClick(plan?.community, plan?._id, plan._id)
                  }
                  sx={{
                    boxShadow: "none",
                    color: plan?.subscribers?.some(
                      (subscriber) =>
                        subscriber._id === loggedInUserData?.user?.id
                    )
                      ? "#ffffff"
                      : "#ffffff",
                    minWidth: "8vw",
                    textTransform: "capitalize",
                    fontSize: { xs: "10px", md: "13px" },

                    fontFamily: "Inter",
                    background:
                      plan?.subscribers?.some(
                        (subscriber) =>
                          subscriber._id === loggedInUserData?.user?.id
                      ) &&
                      !plan.subscriptionDue.every((due) =>
                        isDueDatePast(due.nextDue)
                      )
                        ? "#000000"
                        : "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                  }}
                >
                  {retryLoadingStates[parseInt(plan._id)] ? (
                    <CircularProgress size={20} style={{ color: "#ffffff" }} />
                  ) : plan?.subscribers?.some(
                      (subscriber) =>
                        subscriber._id === loggedInUserData?.user?.id
                    ) ? (
                    plan.subscriptionDue.every((due) =>
                      isDueDatePast(due.nextDue)
                    ) ? (
                      "Renewal "
                    ) : (
                      "Choose & Pay Apple"
                    )
                  ) : (
                    "Choose & Pay Apple"
                  )}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="large"
                  disabled={
                    plan?.subscribers?.some(
                      (subscriber) =>
                        subscriber._id === loggedInUserData?.user?.id
                    ) &&
                    !plan.subscriptionDue.every((due) =>
                      isDueDatePast(due.nextDue)
                    )
                  }
                  onClick={() =>
                    handleRetryClick(plan?.community, plan?._id, plan._id)
                  }
                  sx={{
                    boxShadow: "none",
                    color: plan?.subscribers?.some(
                      (subscriber) =>
                        subscriber._id === loggedInUserData?.user?.id
                    )
                      ? "#ffffff"
                      : "#ffffff",
                    minWidth: "8vw",
                    textTransform: "capitalize",
                    fontSize: { xs: "10px", md: "13px" },

                    fontFamily: "Inter",
                    background:
                      plan?.subscribers?.some(
                        (subscriber) =>
                          subscriber._id === loggedInUserData?.user?.id
                      ) &&
                      !plan.subscriptionDue.every((due) =>
                        isDueDatePast(due.nextDue)
                      )
                        ? "#000000"
                        : "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                  }}
                >
                  {retryLoadingStates[parseInt(plan._id)] ? (
                    <CircularProgress size={20} style={{ color: "#ffffff" }} />
                  ) : plan?.subscribers?.some(
                      (subscriber) =>
                        subscriber._id === loggedInUserData?.user?.id
                    ) ? (
                    plan.subscriptionDue.every((due) =>
                      isDueDatePast(due.nextDue)
                    ) ? (
                      "Renewal"
                    ) : (
                      "Choose & Pay"
                    )
                  ) : (
                    "Choose & Pay"
                  )}
                </Button>
              )} */}

              <Button
                variant="contained"
                size="large"
                disabled={
                  plan?.subscribers?.some(
                    (subscriber) =>
                      subscriber._id === loggedInUserData?.user?.id
                  ) &&
                  !plan.subscriptionDue.every((due) =>
                    isDueDatePast(due.nextDue)
                  )
                }
                onClick={() =>
                  handleRetryClick(plan?.community, plan?._id, plan._id)
                }
                sx={{
                  boxShadow: "none",
                  color: plan?.subscribers?.some(
                    (subscriber) =>
                      subscriber._id === loggedInUserData?.user?.id
                  )
                    ? "#ffffff"
                    : "#ffffff",
                  minWidth: "8vw",
                  textTransform: "capitalize",
                  fontSize: { xs: "10px", md: "13px" },

                  fontFamily: "Inter",
                  background:
                    plan?.subscribers?.some(
                      (subscriber) =>
                        subscriber._id === loggedInUserData?.user?.id
                    ) &&
                    !plan.subscriptionDue.every((due) =>
                      isDueDatePast(due.nextDue)
                    )
                      ? "#000000"
                      : "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                }}
              >
                {retryLoadingStates[parseInt(plan._id)] ? (
                  <CircularProgress size={20} style={{ color: "#ffffff" }} />
                ) : plan?.subscribers?.some(
                    (subscriber) =>
                      subscriber._id === loggedInUserData?.user?.id
                  ) ? (
                  plan.subscriptionDue.every((due) =>
                    isDueDatePast(due.nextDue)
                  ) ? (
                    "Renewal"
                  ) : (
                    "Choose & Pay"
                  )
                ) : (
                  "Choose & Pay"
                )}
              </Button>

              {/* <Stack>
                <Button>
                  {" "}
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      boxShadow: "none",
                      color: "#ffffff",
                      minWidth: "8vw",
                      textTransform: "capitalize",
                      fontSize: { xs: "10px", md: "13px" },
                      fontFamily: "Inter",
                      background:
                        "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                    }}
                    onClick={() => {
                      navigate(`/user-subscription/${plan?._id}`);
                    }}
                  >
                    Choose & Pay
                  </Button>
                </Button>
              </Stack> */}

              <Stack
                alignItems={"center"}
                sx={{ mt: 8, ml: 7, display: { xs: "none", md: "block" } }}
              >
                {plan?.document ? (
                  <a
                    href={(plan?.document as IMultiMedia)?.value || ""}
                    download
                  >
                    <Tooltip title="Download Document" placement="left-start">
                      <IconButton>
                        <CloudDownloadOutlinedIcon
                          sx={{ cursor: "pointer", color: "#50A1CA" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </a>
                ) : (
                  ""
                )}
              </Stack>
            </Grid>
          </Grid>

          <Typography
            sx={{
              color: "#464646",
              mt: 1,
              fontSize: { xs: "12px", md: "13px" },
              textTransform: "capitalize",
              fontFamily: "Inter",
              textAlign: "justify",
              display: { xs: "none", md: "block" },
            }}
          >
            {plan?.description}
          </Typography>
        </Card>
      )}
      <PaymentSuccess
        txnid={transaction?.txnid || ""}
        open={successOpen}
        amount={transaction?.amount || ""}
        timer={timer}
        dialogText={""}
        onClose={handleSuccessClose}
        onClickNavigateButton={() => {
          navigate("/payments");
          setSuccessOpen(false);
        }}
      />

      {/* Payment Failed Pop up */}
      <PaymentFailed
        open={failureOpen}
        onClose={handleFailureClose}
        amount={transaction?.amount || ""}
        txnid={transaction?.txnid || ""}
        onClickNavigateButton={() => {
          navigate("/payments");
          setFailureOpen(false);
        }}
        dialogText={""}
        timer={timer}
        OnClickRetryButtonFunction={() => {
          handleRetryClick(community, planId, "");
          setFailureOpen(false);
        }}
      />
    </>
  );
};

export default UserPlan;
