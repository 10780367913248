import { Grid } from "@mui/material";
import HomePost from "./HomePost";
import { HomeCarousel } from "./HomeCarousel";
//import store from "../../store";
//import { useSelector } from "react-redux";
//import {  loadSelectedCommunity } from "../../store/slices/CommunitySlice";
//import Loader from "../Loader/Loader";
import { Helmet } from "react-helmet";
import { metaConfig } from "../../utils/constants";
// import PostAiSuggestions from "../Posts/PostAiSuggestions";
// import { useSelector } from "react-redux";

//import { useCommunity } from "../../hooks/useCommunity";

export const Home = () => {



  // const { getCommunityPostsById ,ProfilePostsById } = usePosts();
  //  const { isLoading, communities } = useCommunity();
  // const selectedCommunity = useSelector((state: any) => state.selectedCommunity.selectedCommunity);

  // const community_name = selectedCommunity?.name

  // console.log(community_name, "community_name");

  //  const selectedCommunityId=selectedCommunity?.id  ;
  // const communityState = store.getState();
  //Load selected community from store


  // const myCommunities = useSelector(() =>{
  //   return communityState?.communities;
  // });
  // on reload, redux will loose data, so get community again

  //console.log(myCommunities?.communities)
  // if(selectedCommunityId){
  //   const community = myCommunities?.communities?.find(c => c?.id === selectedCommunityId)
  //   //console.log(community)
  //   if(community)
  //   store.dispatch(loadSelectedCommunity(community))
  // }



  // if(!selectedCommunityId){
  //   return <Loader/>
  // }
  return (
    <>
      <Helmet>
        <title>{metaConfig.title.home}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.description} />
        <meta name="keywords" content={metaConfig.description} />
      </Helmet>
      <Grid container >
        <Grid item xs={12}  >
          <>
            <HomeCarousel />
            <HomePost />
          </>
        </Grid>

      </Grid>

    </>
  );
};
