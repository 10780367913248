import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { IPost } from "../components/Posts/Posts";
import { AuthContext, IAuthContext } from "../contexts/Auth.context";
import {
  HomePosts,
  ProfilePosts,
  deletePost,
  getPosts,
  updatePost,
  createNewCommunityPost,
  deleteMultiplePosts,
  userPosts,
  likeUnLikePost,
} from "../services/post.service";

// export function stat
export const usePosts = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [postsData, setPostsData] = useState<IPost[]>([]);

  // To colect all posts
  const getPostList = async () => {
    setIsLoading(true);
    try {
      const response = await getPosts(getAccessToken());
      if (response.status === 200) {
        // setPostsChanged(true)
        return response.data as IPost[];
      } else {
        enqueueSnackbar("Fetching posts failed", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      enqueueSnackbar("Error fetching posts", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
    setIsLoading(false);
  };

  // get only user posts
  // const getProfilePostList = async (id: string) => {
  //   setIsLoading(true);
  //   const response = await ProfilePosts(getAccessToken(), id);
  //   if (response.status === 200) {
  //      return response.data as IPost[];
  //   } else {
  //     enqueueSnackbar("Fetching posts failed", { variant: "error" });
  //   }
  //   setIsLoading(false);
  // };
  const getProfilePostList = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await ProfilePosts(getAccessToken(), id);

      if (response.status === 200) {
        return response.data as IPost[];
      } else {
        enqueueSnackbar("Fetching posts failed", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      // console.error("Error fetching posts:", error);
      enqueueSnackbar("An error occurred while fetching posts", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };
  // const getMemberPostList = async (communityId: string,memberId:string) => {
  //   try {
  //     setIsLoading(true);
  //     const response = await MemberPosts(getAccessToken(),communityId,memberId);

  //     if (response.status === 200) {
  //       return response.data as IPost[];
  //     } else {
  //       enqueueSnackbar("Fetching posts failed", { variant: "error" });
  //     }
  //   } catch (error) {
  //     console.error("Error fetching posts:", error);
  //     enqueueSnackbar("An error occurred while fetching posts", { variant: "error" });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // Get all posts for selected community
  const getCommunityPostsById = async (id: string) => {
    setIsLoading(true);
    const response = await HomePosts(getAccessToken(), id);
    // console.log("Community Posts:", response.data);
    if (response.status === 200) {
      setIsLoading(false);
      return response.data as IPost[];
    } else {
      enqueueSnackbar("Fetching posts failed", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const deletePostById = async (id: string) => {
    try {
      const response = await deletePost(getAccessToken(), id);
      if (response.status === 200) {
        setPostsData((prevPosts) =>
          prevPosts.filter((post) => post._id !== id)
        );
        enqueueSnackbar("Post deleted", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar("Couldn’t delete post", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn’t delete post", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  const deletePostByIds = async (idsToDelete: string[]) => {
    // console.log("Deleting posts with IDs:", idsToDelete);
    try {
      const response = await deleteMultiplePosts(getAccessToken(), idsToDelete);

      if (response.status === 200) {
        // Filter out the deleted posts from the current state
        const updatedPosts = postsData.filter(
          (post) => !idsToDelete.includes(post._id)
        );

        // Update the state with the filtered posts
        setPostsData(updatedPosts);

        enqueueSnackbar("Post deleted", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar("Couldn’t delete post", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn’t delete post", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const createNewPost = async (formData: FormData, file: FileList | null) => {
    try {
      setIsLoading(true);
      const response = await createNewCommunityPost(
        getAccessToken(),
        file,
        formData
      );

      if (response.status === 201) {
        enqueueSnackbar("Post created", {
          variant: "success",
          autoHideDuration: 3000,
        });
        // setUpdatePostsFlag(true);
      } else {
        enqueueSnackbar("Couldn’t create post", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }

      setIsLoading(false);
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn’t create post", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const editPost = async (
    postId: string,
    formData: FormData,
    file: FileList | null
  ) => {
    try {
      setIsLoading(true);
      const response = await updatePost(
        getAccessToken(),
        postId,
        formData,
        file
      );
      if (response.status === 200) {
        enqueueSnackbar("Post updated", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar("Update failed", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
      setIsLoading(false);
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn’t update post", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  const getIndividualUserPostsById = async (
    community: string,
    userId: string
  ) => {
    setIsLoading(true);
    const response = await userPosts(getAccessToken(), community, userId);
    console.log(response);
    if (response.status === 200) {
      setIsLoading(false);
      return response.data;
    } else {
      // enqueueSnackbar("Fetching community posts failed", { variant: "error" });
      setIsLoading(false);
    }
  };

  const likePost = async (postId: string, isLiked: boolean) => {
    const response = await likeUnLikePost(getAccessToken(), postId, isLiked);

    if (response.status === 200) {
      enqueueSnackbar("Liked Post", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setIsLoading(false);
      return response.data;
    } else {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    getCommunityPostsById,
    deletePostById,
    createNewPost,
    getAccessToken,
    getProfilePostList,
    editPost,
    deletePostByIds,
    getPostList,
    getIndividualUserPostsById,
    likePost,
  };
};
//////////////////////////////// export function ending
