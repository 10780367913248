import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Fab,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/Auth.context";
import { getOtp, verifyOtp } from "../../../services/otp.service";
import {
  EMPTY_STRING,
  INACTIVE_USER_MSG,
  INVALID_OTP,
  OTP_LIMIT_REACHED,
  OTP_RESENT,
  OTP_SEND_FAILED,
  OTP_SENT,
  USER_NOT_FOUNT_MSG,
} from "../../../utils/UdfMessages";
// import {

//   useGoogleLogin,
//   CodeResponse,

// } from "@react-oauth/google";
import {
  BASE_URL,
  USER_APP_BASE_URL,
} from "../../../configurations/url.config";
// import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet";
import RegisterPopUp from "./RegisterPopUp";
import { ICommunity } from "../../../models/communities.modal";
import { joinCommunity } from "../../../services/communities.service";
import { useDispatch } from "react-redux";
import { loadSelectedCommunity } from "../../../store/slices/CommunitySlice";
import SendRequestCommunityPreview from "../../ExploreCommunities/CommunityPreview/SendRequestCommunityPreview";
import { sendRequestToJoin } from "../../../services/requests.service";
import { useSnackbar } from "notistack";
import { auth, google, apple } from "../../../configurations/fireBase"
import { signInWithPopup } from "@firebase/auth";
import { login as loginGoogle } from "webtonative/SocialLogin/google"
import { login as loginApple } from "webtonative/SocialLogin/apple";
import GoogleIcon from "./../../../assets/images/icons8-google.svg";
import AppleIcon from "./../../../assets/images/icons8-apple.svg";
// interface User {
//   access_token: string;
// }

interface PopUpInterFace {
  data: ICommunity;
}

const LoginPopUp1: React.FC<PopUpInterFace> = ({ data }) => {

  const { enqueueSnackbar } = useSnackbar();
  const authContex = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword] = useState(false);
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [enableGetOTP, setEnableGetOTP] = useState(false);
  const [otpReceived, setOtpReceived] = useState(false);
  const [otpSuccessMessage, setOtpSuccessMessage] = useState("");
  // const [enteredOpt, setEnteredOtp] = useState("");
  const [resendCount, setResendCount] = useState(0);
  const [remainingTime, setRemainingTime] = useState(30000);
  const [timerRunning, setTimerRunning] = useState(false);
  const [isBlocked] = useState(false);
  const [actionButton, setActionButton] = useState("Login");
  const [information] = React.useState("");
  // const [otp6, setOtp6] = useState("");
  // eslint-disable-next-line
  const [generatedOTP, setGeneratedOTP] = useState<Number>(0);
  const NUMBER_3 = 3;
  const [isModalOpen, setModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const dispatch = useDispatch();

  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${data?._id}/about`
  )}`;

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${data?._id}/about`
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${data?._id}/about`
  )}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${data?._id}/about`
  )}`;

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleCopyLink = () => {
    const labelContent = `${USER_APP_BASE_URL}/communities/${data?._id}/about`;

    navigator.clipboard
      .writeText(labelContent)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Mobile Number is required")
      .test(
        "username",
        "Invalid Mobile Number",
        (value) => validateEmail(value) || validateMobileNumber(value)
      ),
    password: Yup.string()
      .required("OTP is required")
      .max(40, "Password must not exceed 40 characters"),
  });

  const validateEmail = (value: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(value);
  };

  const validateMobileNumber = (value: string) => {
    const mobileNumberRegex = /^\d{10}$/;
    return mobileNumberRegex.test(value);
  };

  const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleNumberChange = (e: any) => {
    const value = e.target.value;
    if (/^\d+$/.test(value) && value.length === 10) {
      setPhoneNumber(value);
      setEnableGetOTP(true);
      setValidPhoneNumber(true);
      setResendCount(0);
      setTimerRunning(false);
      setRemainingTime(60);
      setOtpReceived(false);
      setOtpSuccessMessage("");
      setError("");
    } else {
      setEnableGetOTP(false);
      setValidPhoneNumber(false);
      setError(EMPTY_STRING);
      setActionButton("Login");
      setGeneratedOTP(0);
      setOtpSuccessMessage(EMPTY_STRING);
    }
  };

  const generateOTP = async () => {
    if (resendCount < NUMBER_3) {
      const { data } = await getOtp(phoneNumber);
      let number = phoneNumber.replace(/^.{6}/g, "******");
      setError("");
      if (data) {
        setOtpReceived(true);
        setActionButton("Verify OTP");
        setResendCount(resendCount + 1);
        setTimerRunning(true);
        setGeneratedOTP(data?.opt);
        //console.log(generatedOTP);
        if (resendCount === 0) {
          setOtpSuccessMessage(OTP_SENT.replace("#PHONE_NUMBER", number));
        } else {
          setOtpSuccessMessage(OTP_RESENT.replace("#PHONE_NUMBER", number));
        }
      } else {
        setOtpSuccessMessage("");
        setError(OTP_SEND_FAILED.replace("#PHONE_NUMBER", number));
      }
    } else if (resendCount === 3) {
      setOtpSuccessMessage(OTP_LIMIT_REACHED);
      setTimerRunning(false);
    }
  };

  useEffect(() => {
    if (timerRunning && remainingTime > 0) {
      const interval = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 3000);

      return () => clearInterval(interval);
    } else {
      setTimerRunning(false);
      setRemainingTime(30);
    }
  }, [timerRunning, remainingTime]);

  const onSubmit = async (data: any) => {

    setLoading(true);
    const value = data.password;
    //console.log(generatedOTP);
    if (!isNaN(value) && value.toString().length === 6) {
      setLoading(true);
      // Call verify OTP to verify
      const verifyResponse = await verifyOtp(phoneNumber, value);
      if (verifyResponse.status === 200) {
        // call autoLogin
        setLoading(true);
        const response = await authContex.autoLoginForLoginPopup(phoneNumber, "");
        handleLoginResponse(response);
      } else {
        // OTP
        setOtpSuccessMessage(EMPTY_STRING);
        setError(INVALID_OTP);
        setLoading(false);
      }
    } else {
      // Email flow: login
      const response: any = await authContex.loginPopUp(data);
      handleLoginResponse(response);
    }
  };

  //   const handleLoginResponse = async (response: any) => {
  //     console.log(response,"RES");
  //     console.log(data);

  //     if (response?.status === 200) {
  //       const { user, joinedCommunities } = response?.data;


  //       if (joinedCommunities && joinedCommunities.length > 0) {
  //         joinedCommunities.forEach((community:any) => {
  //           console.log(community._id);
  //         });
  // console.log(joinedCommunities,data,"joijnedd");

  //         if (joinedCommunities) {
  //           // Navigate to home page or any other desired location
  //           navigate('/home');
  //         } else {
  //           // Navigate to explore communities page

  //         if (data.type === "PUBLIC") {
  //           const result = await joinCommunity(
  //             response?.data.user.token.accessToken,
  //             data._id ?? ""
  //           );
  //           if (result.status === "success") {
  //             enqueueSnackbar("You have successfully joined!", {
  //               variant: "success",
  //             });
  //             navigate(`/community/${data?._id}/about`);
  //             dispatch(loadSelectedCommunity(data));
  //           } else {
  //             enqueueSnackbar("Failed to Request to Join Community", {
  //               variant: "error",
  //             });
  //             navigate(`/community/${data?._id}/about`);
  //           }
  //         } else if (data.type === "PRIVATE") {
  //           setModalOpen(true);
  //           const payload = {
  //             community: data,
  //           };
  //           const result = await sendRequestToJoin(
  //             response?.data.user.token.accessToken,
  //             payload
  //           );
  //           if (result.status === 201) {
  //             enqueueSnackbar("Request Sent Successfully to Join Community", {
  //               variant: "success",
  //             });
  //             navigate("/explore-communities");
  //           } else {
  //             enqueueSnackbar("Failed to Request to Join Community", {
  //               variant: "error",
  //             });
  //             navigate(`/community/${data?._id}/about`);
  //           }
  //         }
  //         }

  //       } else if (!joinedCommunities || joinedCommunities.length === 0) {
  //         navigate("/explore-communities", {
  //           state: { id: 2, data: user },
  //         });
  //       } else if (user?.community) {
  //         navigate("/dashboard", { state: { id: 3, data: user } });
  //       }
  //     } else if (response.status === 403) {
  //       setError(INACTIVE_USER_MSG);
  //       setOtpSuccessMessage(EMPTY_STRING);
  //     } else if (response?.status === 404) {
  //       setError(USER_NOT_FOUNT_MSG);
  //       // navigate("/register", { state: { id: 1, phoneNumber: phoneNumber } });
  //       setOpen(true);
  //     }
  //     setLoading(false);
  //   };

  const handleLoginResponse = async (response: any) => {
    if (response?.status === 200) {
      const { user, joinedCommunities, adminCommunities } = response?.data;
      // console.log(response.data);
      if (joinedCommunities && adminCommunities && data._id) {
        const isInJoinedCommunities = joinedCommunities.find((item: any) => item.community._id === data._id);
        const isInAdminCommunities = adminCommunities.find((item: any) => item.community._id === data._id);
        if (isInJoinedCommunities && isInAdminCommunities) {
          navigate(`/community/${data?._id}/about`);
          dispatch(loadSelectedCommunity(data));


        } else {
          if (data.type === "PUBLIC") {
            const result = await joinCommunity(
              response?.data.user.token.accessToken,
              data._id ?? ""
            );
            if (result.status === "success") {
              if (data.collectSubscription === "YES") {
                navigate("/subscriptions"); 
              } else {
                navigate(`/community/${data?._id}/about`); 
                dispatch(loadSelectedCommunity(data));
              }
            } else {
              // Handle other cases (if necessary)
              navigate(`/community/${data?._id}/about`); 
              dispatch(loadSelectedCommunity(data));
            }
          }
          
          else if (data.type === "PRIVATE") {
            setModalOpen(true);
            const payload = {
              community: data,
            };
            const result = await sendRequestToJoin(
              response?.data.user.token.accessToken,
              payload
            );
            if (result.status === 201) {
              enqueueSnackbar("Request Sent Successfully to Join Community", {
                variant: "success",
              });
              navigate("/explore-communities");
            }

          }
        }
      }
      else if (!joinedCommunities || joinedCommunities.length === 0) {
        navigate("/explore-communities", {
          state: { id: 2, data: user },
        });
      } else if (user?.community) {
        navigate("/dashboard", { state: { id: 3, data: user } });
      }
      else if (user?.community) {
        navigate("/dashboard", { state: { id: 3, data: user } });
      }
    } else if (response.status === 403) {
      setError(INACTIVE_USER_MSG);
      setOtpSuccessMessage(EMPTY_STRING);
    } else if (response?.status === 404) {
      setError(USER_NOT_FOUNT_MSG);
      // navigate("/register", { state: { id: 1, phoneNumber: phoneNumber } });
      setOpen(true);
    }
    setLoading(false);
  };




  // const logOut = () => {
  //   googleLogout();
  //   setProfile(null);
  // };

  const SocialLogin = async (login: any) => {
    const response: any = await signInWithPopup(auth, login)
    const socialProfile = response['_tokenResponse'];
    const user = response.user;
    const autoCreateData = {
      firstName: socialProfile.firstName,
      lastName: socialProfile.lastName,
      emailId: socialProfile.email,
      isEmailVerified: socialProfile.emailVerified,
      avatar: socialProfile.photoUrl,
      phoneNumber: user?.phoneNumber

    };

    axios
      .post(`${BASE_URL}/auth/social-login`, autoCreateData)
      .then(async (response) => {
        const { user } = response?.data
        // console.log("User Info:", user)
        const autologinResponse = await authContex.autoLoginForLoginPopup("", user?.emailId);
        handleLoginResponse(autologinResponse);

      })
      .catch((error) => {
        console.error("Auto login error:", error);
      });


  }
  const handleGoogleLogin = async () => {
    SocialLogin(google);
  };
  const handleFacebookLogin = async () => {
    SocialLogin(apple);
  }

  // Use MUI's useMediaQuery hook to determine screen size
  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:850px) and (max-width:1199px)"
  );
  const dialogWidth = isLargeScreen ? "50%" : isMediumScreen ? "68%" : "100%";


  const handleButtonClick = () => {
        const { _id } = data;
    if(data?.collectSubscription === "YES" ){
      navigate('/subscription', { state: { _id } });
      dispatch(loadSelectedCommunity(data));
    }else{
      navigate('/home', { state: { _id } });
      dispatch(loadSelectedCommunity(data));
    }
    //     navigate('/home', { state: { _id } });
    //     dispatch(loadSelectedCommunity(data));
    // console.log(_id, "ID")
  };


  const handleClickPaid = () => {
    const { _id } = data;
if(data?.collectSubscription === "YES" ){
  navigate('/subscription', { state: { _id } });
  dispatch(loadSelectedCommunity(data));
}else{
  navigate('/home', { state: { _id } });
  dispatch(loadSelectedCommunity(data));
}
//     navigate('/home', { state: { _id } });
//     dispatch(loadSelectedCommunity(data));
// console.log(_id, "ID")
};


const handleMobileGoogleLogin = () => {
  loginGoogle({
    callback: async (value) => {
      // console.log(value, "WebToNative login response");
      if (value) {
        try {
          const googleResponse = await axios.get(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${value.idToken}`);
          const googleData = googleResponse.data;
          const autoCreateData = {
            firstName: googleData.given_name,
            lastName: googleData.family_name,
            emailId: googleData.email,
            isEmailVerified: googleData.email_verified,
            avatar: googleData.picture,
            phoneNumber: "",
          };
          const response = await axios.post(`${BASE_URL}/auth/social-login`, autoCreateData);
          const { user } = response.data;
          // console.log(user, "response");
          const autoLoginResponse =  authContex.autoLogin("", user?.emailId);
          handleLoginResponse(autoLoginResponse);
        } catch (error) {
          console.error("Error during Google login or fetching user info:", error);
        }
      }
    }
  });
};

const handleMobileAppleLogin = () => {
  loginApple({
    callback: async (value) => {
      // console.log(value, "WebToNative Apple login response");
      if (value && value.isSuccess) {
        const autoCreateData = {
          firstName: value.firstName || "",
          lastName: value.lastName || "",
          emailId: value.emailId || "",
          isEmailVerified: true,
          avatar: "",
          phoneNumber: "",
        };
        try {
          const response = await axios.post(`${BASE_URL}/auth/social-login`, autoCreateData);
          const { user } = response.data;
          // console.log(user, "response");

          // Handle auto-login
          const autologinResponse = await authContex.autoLogin("", user?.emailId);
          handleLoginResponse(autologinResponse);

        } catch (error) {
          console.error("Error during Apple login or processing user data:", error);
        }
      }
    }
  });
};




const isAppleDevice = /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent);

const handleGoogleMobileLogin = () => {
  handleMobileGoogleLogin();
};

const handleAppleMobileLogin = () => {
  handleMobileAppleLogin();
};

  return (
    <>
      <Helmet>
        <title>
          Login / Register - Communn - Build & Manage your community
        </title>
        <meta
          name="description"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta
          name="keywords"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
      </Helmet>
      {/* <StyledLogin> */}
      <Grid container>
        <Grid item xs={12} md={12}>
          <Stack
            sx={{
              overflow: "auto",
              justifyContent: {
                xs: "flex-start",
                sm: "flex-start",
                md: "center",
                lg: "center",
              },
            }}
            className="login-form-container"
            alignItems={"center"}
          >
            {information ? (
              <Typography
                sx={{
                  color: "green",
                  fontWeight: "700",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                {information}
              </Typography>
            ) : (
              ""
            )}

            <Box
              component={"form"}
              onSubmit={onSubmit}
              sx={{
                width: "328px",
                textAlign: "center",
                mt: { xs: "5vh", sm: "5vh", md: "10vh", lg: "2vh" },
              }}
            >
              <Box
                component={"img"}
                src={require("./../../../assets/images/Communn-new-logo.png")}
                alt=""
                width={"40%"}
                sx={{ pb: 2 }}
              />

              <Typography
                sx={{
                  color: "#000000",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                New user? Enter mobile number please.
              </Typography>
              <Stack
                spacing={2}
                sx={{ pt: 2, backgroundColor: "white", px: 2 }}
                className="login-form"
              >
                <TextField
                  sx={{ backgroundColor: "white" }}
                  size="small"
                  required
                  fullWidth
                  placeholder="Enter Mobile Number"
                  {...register("username")}
                  error={errors.username ? true : false}
                  helperText={
                    errors.username ? (errors.username.message as string) : ""
                  }
                  onChange={handleNumberChange}
                  // InputProps={{ style: { borderRadius: "30px", backgroundColor: '#ffffff' } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          cursor: "pointer",
                          backgroundColor: "#ffffff",
                          alignContent: "center",
                          borderRadius: "100px",
                        }}
                      >
                        <Box
                          display="flex"
                          sx={{
                            display: validPhoneNumber ? "block" : "none",
                          }}
                        >
                          <Stack direction="row">
                            <Divider
                              orientation="vertical"
                              sx={{ m: 1, height: 24 }}
                            />

                            <Button
                              size="small"
                              sx={{
                                backgroundColor: "#3b9b7f",
                                textTransform: "capitalize",
                                color: "#ffffff",
                                borderRadius: "100px",
                                height: "30px",
                                mt: "5px",
                                mr: "-15px",
                                ":hover": {
                                  backgroundColor: "#3b9b7f",
                                  color: "#ffffff",
                                },
                                // display: validPhoneNumber ? "block" : "none",
                              }}
                              disabled={
                                !enableGetOTP || isBlocked || timerRunning
                              }
                              onClick={generateOTP}
                            >
                              {timerRunning
                                ? `Resend OTP in ${remainingTime}s`
                                : resendCount === 0
                                  ? `Get OTP`
                                  : `Resend OTP`}
                            </Button>
                          </Stack>
                        </Box>
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "100px",
                      backgroundColor: "#ffffff",
                    },
                  }}
                />

                <TextField
                  size="small"
                  required
                  defaultValue=""
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter OTP "
                  {...register("password")}
                  error={errors.password ? true : false}
                  helperText={
                    errors.password ? (errors.password.message as string) : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          cursor: "pointer",
                          backgroundColor: "#E5E3EF",
                          borderRadius: "100px",
                        }}
                      >
                        <Stack
                          divider={<Divider orientation="vertical" flexItem />}
                        ></Stack>
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "30px",
                      backgroundColor: "#ffffff",
                    },
                  }}
                ></TextField>

                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    borderRadius: "30px",
                    background:
                      "linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)",
                    textTransform: "capitalize",
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  {loading ? <CircularProgress size={24} sx={{ color: 'white' }} />
                    :
                    `${actionButton}`}
                </Button>

                <Stack
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ color: "red" }}
                >
                  {" "}
                  {error ? (
                    <Typography sx={{ textAlign: "center", fontSize: "14px", }}>
                      {error}
                    </Typography>
                  ) : null}{" "}
                </Stack>
                {otpReceived && (
                  <p
                    style={{
                      color: "green",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                    className="success-message"
                  >
                    {otpSuccessMessage}
                  </p>
                )}
                <Divider sx={{ pt: 1 }}>
                  <Box component={"span"}>OR Continue With</Box>
                </Divider>
                <Box sx={{ pt: 1 }}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"center"}
                    alignContent={"center"}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  >
                    <Fab size="medium" sx={{ backgroundColor: "white" }}>
                      <Box
                        onClick={() => handleGoogleLogin()}
                        component={"img"}
                        src={GoogleIcon}
                        sx={{ width: '30px', height: '30px' }}
                      />
                    </Fab>

                    {/* <Fab size="medium">
                      <Box
                        onClick={() => handleFacebookLogin()}
                        component={"img"}
                        src={require("./../../../assets/images/facebook.png")}
                      />
                    </Fab> */}
                    <Fab size="medium" sx={{ backgroundColor: "white" }}>

                      <Box
                        onClick={() => handleFacebookLogin()}
                        component={"img"}
                        src={AppleIcon}
                        sx={{ width: '30px', height: '30px' }}
                      />

                    </Fab>
                  </Stack>

                  {/* Mobile Screen Login */}

                  <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent={"center"}
                      alignContent={"center"}
                      sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                      <Fab size="medium">
                        <Box
                          onClick={handleGoogleMobileLogin}
                          component={"img"}
                          src={GoogleIcon}
                          sx={{ width: '30px', height: '30px' }}
                        />
                      </Fab>

                      {isAppleDevice && (
                        <Fab size="medium">
                          <Box
                            onClick={handleAppleMobileLogin}
                            component={"img"}
                            src={AppleIcon}
                            sx={{ width: '30px', height: '30px' }}
                          />
                        </Fab>
                      )}
                    </Stack>

                  <SendRequestCommunityPreview
                    isOpen={isModalOpen}
                    handleClose={handleCloseModal}
                    logo={data?.logo ?? ""}
                    id={data?._id ?? ""}
                    title={data?.name ?? ""}
                    city={data?.city}
                    members={data?.members?.length || []}
                    message={data?.directorMessage ?? ""}
                    type={data?.type ?? ""}
                    description={data?.description ?? ""}
                    handleCopyLink={handleCopyLink}
                    copied={copied}
                    whatsappShareUrl={whatsappShareUrl}
                    facebookShareUrl={facebookShareUrl}
                    linkedinShareUrl={linkedinShareUrl}
                    twitterShareUrl={twitterShareUrl}
                    coverImageSrc={data?.banner ?? ""}
                    handleButtonClick={handleButtonClick}
                    handleClickPaid={handleClickPaid}
                  />

                  {/* <React.Fragment>
                    <BootstrapDialog
                      onClose={handleClose}
                      aria-labelledby="customized-dialog-title"
                      open={open}
                      fullWidth
                    >
                      <Box
                        component={"img"}
                        src={require("./../../../assets/images/LoginMbg.png")}
                        sx={{
                          height: "100px",
                          width: "100%",
                          objectFit: "fill",
                          display: { xs: "block", md: "block" },
                        }}
                        alt=""
                      ></Box>
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon sx={{ color: "white" }} />
                      </IconButton>
                      <DialogContent>
                      {open && <RegisterPopUp phoneNumber={phoneNumber} />}
                      </DialogContent>
                    </BootstrapDialog>
                  </React.Fragment> */}

                  <React.Fragment>
                    <Dialog
                      fullWidth
                      maxWidth="sm"
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          width: dialogWidth,
                          padding: 0,
                        },
                      }}
                    >
                      <DialogContent sx={{ padding: 0 }}>
                        <RegisterPopUp
                          handleClose={handleClose}
                          phoneNumber={phoneNumber} communityData={data} />
                      </DialogContent>
                    </Dialog>
                  </React.Fragment>

                  <Box
                    sx={{
                      color: "#9A9A9A",
                      fontSize: "12px",
                      pt: 2,
                      textAlign: "center",
                      mt: 2,
                    }}
                  >
                    By clicking Get OTP, you agree to the communn{" "}
                    <Link href="" style={{ color: "#9A9A9A" }}>
                      User Agreement,
                    </Link>
                    <Link href="" style={{ color: "#9A9A9A" }}>
                      {" "}
                      Privacy Policy,
                    </Link>
                    and
                    <Link href="" style={{ color: "#9A9A9A" }}>
                      {" "}
                      Cookie Policy.
                    </Link>
                  </Box>
                </Box>

                <Stack>
                  <script
                    async
                    src="https://cse.google.com/cse.js?cx=a2e80b1b9920b4352"
                  ></script>
                  <div className="gcse-search"></div>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Grid>
      </Grid>

    </>
  );
};

export default LoginPopUp1;
