import { Box, Stack } from "@mui/material";
// import CircularProgress from "@mui/material/CircularProgress";

export const SplashScreen = () => {
  return (
    <Stack sx={{
      color: 'grey.500', display: "flex",

      backgroundColor:'#ffffff',
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }} spacing={2} direction="row" >
      {/* <CircularProgress color="inherit" /> */}
      <Box
        component={"img"}
        src={require("../../assets/images/Splash.gif")}
        alt=""
        sx={{ width: "100%" }}
      />
    </Stack>
  );
};

export default SplashScreen;
