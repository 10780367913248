import {
  Avatar,
  Breadcrumbs,
  Button,
  Chip,
  Divider,
  Grid,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SubNavbar } from "../../Layout/components/SubNavbar";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { metaConfig } from "../../../utils/constants";
import { useSubscription } from "../../../hooks/useSubscription";
import { ISubscribers } from "../../../models/subscription.model";
import store from "../../../store";
import { getStaticValue } from "../../../utils/StaticValues";
import SmallLoader from "../../Loader/SmallLoader";
import { IPaymentList } from "../../../models/payment.model";
import { usePayment } from "../../../hooks/usePayment";
import { IPlan } from "../Plans";
import PaymentSuccess from "../../Payments/PaymentSuccessPopUp";
import PaymentFailed from "../../Payments/PaymentFailedPopUp";

interface IPlanProps {
  plan?: IPlan;
  onClick?: () => void;
  plans?: any[];
}

const UserSubscription = (props: IPlanProps) => {
  enum PaymentStatus {
    SUCCESS = "SUCCESS",
    PENDING = "PENDING",
    FAILED = "FAILED",
    USERCANCELLED = "USERCANCELLED",
  }

  const state = store.getState();
  const loggedInUserData = useSelector(() => {
    return state?.loggedInUser;
  });

  const [subscriptionList, setSubscriptionList] = useState<[]>([]);
  const [subscriptions, setSubscriptions] = useState<ISubscribers[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAmounts, setSelectedAmounts] = useState<
    { id: string; amount: number }[]
  >([]);
  const [planId, setplanId] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [timer, setTimer] = useState(5);
  const [failureOpen, setFailureOpen] = useState(false);
  const [transaction, setTransaction] = useState<IPaymentList>();
  const [community, setCommunity] = useState("");

  const plans = [];
  const [retryLoadingStates, setRetryLoadingStates] = useState<boolean[]>(
    Array(plans?.length).fill(false)
  );

  const { initiatePaymentByIds, getPaymentStatusById } = usePayment();

  const navigate = useNavigate();

  const paymentResponse = (response: any) => {
    const tnxId = response?.transactionId;
    const transaction = response?.transaction as IPaymentList;
    if (transaction) setTransaction(transaction);
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const width = Math.min(1000, screenWidth);
    const height = Math.min(1000, screenHeight);
    const left = (screenWidth - width) / 2;
    const top = (screenHeight - height) / 2;
    const windowRef = window.open(
      response.url,
      `addressbar=no,directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no, width=${width},height=${height},left=${left},top=${top}`
    );
    const intervalRef = setInterval(async () => {
      const paymentStatus = await getPaymentStatusById(tnxId);
      if (paymentStatus && paymentStatus.length > 0) {
        clearInterval(intervalRef);
        windowRef?.close();
        if (paymentStatus[0]?.status === PaymentStatus.SUCCESS) {
          setSuccessOpen(true);
        } else {
          setFailureOpen(true);
        }
      }
    }, 1000);
  };
  const handleSuccessClose = () => {
    setTimer(5);
    navigate("/payments");
    setSuccessOpen(false);
  };

  const handleFailureClose = () => {
    setTimer(5);
    navigate("/plans-lists");
    setFailureOpen(false);
  };
  const handleRetryClick = async (
    communityId: string,
    planId: string,
    id: string
  ) => {
    setCommunity(communityId);
    setplanId(planId);
    setRetryLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[parseInt(id, 10)] = true;
      return newStates;
    });

    const currentDueDate = "30/09/2024";
    const response = await initiatePaymentByIds(
      communityId,
      planId,
      currentDueDate
    );
    setRetryLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[parseInt(id, 10)] = false;
      return newStates;
    });
    paymentResponse(response);
  };

  const handleSelectAmount = (id: string, amount: number) => {
    setSelectedAmounts((prev) =>
      prev.some((item) => item.id === id)
        ? prev.filter((item) => item.id !== id)
        : [...prev, { id, amount }]
    );
  };

  const totalAmount = selectedAmounts.reduce(
    (acc, curr) => acc + curr.amount,
    0
  );

  console.log("totalAmount", totalAmount);

  const {
    subscriptionListByCommunityId,
    createSubscriptionSequencesByPlanAndCommunityId,
  } = useSubscription();
  const selectedCommunityId = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity?._id
  );

  const { id } = useParams();
  const validId = id ?? "";

  const loadSubscription = async () => {
    try {
      setIsLoading(true);
      if (selectedCommunityId) {
        const subscription = await subscriptionListByCommunityId(
          selectedCommunityId
        );
        if (subscription) {
          setIsLoading(false);
          setSubscriptionList(subscription);
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadSubscription();
  }, [selectedCommunityId]);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const mobileViewHeight = 280;
  const desktopViewHeight = 330;

  const height = isMobileView ? mobileViewHeight : desktopViewHeight;

  const filteredSubscription = subscriptionList.filter(
    (subscription: ISubscribers) =>
      String(subscription?.plan?._id) === String(id)
  );
  useEffect(() => {
    if (subscriptionList && subscriptionList.length > 0) {
      const filteredSubscription = subscriptionList.filter(
        (subscription: ISubscribers) =>
          String(subscription?.plan?._id) === String(id)
      );
      if (filteredSubscription && filteredSubscription.length > 0) {
        setSubscriptions(filteredSubscription);
      } else {
        console.warn(
          "No matching subscription found or subscriptionList is empty."
        );
      }
    }
  }, [subscriptionList, id]);

  const communityId = selectedCommunityId;

  const fetchSequencesByPlanAndCommunityID = async () => {
    setIsLoading(true);
    try {
      const response = await createSubscriptionSequencesByPlanAndCommunityId(
        communityId,
        validId
      );
      setSubscriptions(response);
      return response || [];
    } catch (err) {
      console.log("Error: ", err);
      setSubscriptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSequencesByPlanAndCommunityID();
  }, [communityId, validId]);

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.subscriberList}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <SubNavbar>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            href="/plans-lists"
            sx={{
              fontSize: { xs: "15px", md: "20px" },
              fontWeight: 500,
              color: "inherit",
              fontFamily: "Inter",
            }}
          >
            Plans
          </Link>

          <Typography
            sx={{
              fontSize: { xs: "15px", md: "20px" },
              fontWeight: 500,
              color: "#3C5AA0",
              fontFamily: "Inter",
            }}
          >
            Subscription Details
          </Typography>
        </Breadcrumbs>
      </SubNavbar>

      <div>
        <Paper
          sx={{
            px: { xs: 3, md: 2 },
            py: { xs: 2, md: 1 },
            mb: 2,
            mt: 1,
            boxShadow: "none",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              display: { xs: "flex", md: "none" },
            }}
          >
            <Grid item xs={4} md={4}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: 1,
                  fontFamily: "Inter",
                  fontSize: "10px",
                }}
              >
                {" "}
                Name
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  marginBottom: 1,
                  fontFamily: "Inter",
                }}
              >
                <Stack
                  sx={{
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                  gap={1}
                >
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "11px",
                      fontFamily: "Inter",
                      color: "#1A1A1A",
                    }}
                  >
                    {" "}
                    {loggedInUserData?.user?.firstName}
                  </Typography>
                </Stack>
              </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: 1,
                  fontFamily: "Inter",
                  fontSize: "10px",
                }}
              >
                {" "}
                Plan Name
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  marginBottom: 1,
                  fontFamily: "Inter",
                }}
              >
                <Stack
                  sx={{
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                  gap={1}
                >
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "11px",
                      fontFamily: "Inter",
                      color: "#1A1A1A",
                    }}
                  >
                    {" "}
                    Ond Day Plan
                  </Typography>
                </Stack>
              </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: 1,
                  fontFamily: "Inter",
                  fontSize: "10px",
                }}
              >
                {" "}
                Amount
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  marginBottom: 1,
                  fontFamily: "Inter",
                }}
              >
                <Stack
                  sx={{
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                  gap={1}
                >
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "11px",
                      fontFamily: "Inter",
                      color: "#1A1A1A",
                    }}
                  >
                    {" "}
                    ₹350
                  </Typography>
                </Stack>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              display: { xs: "flex", md: "none" },
            }}
          >
            <Grid item xs={4} md={4}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: 1,
                  fontFamily: "Inter",
                  fontSize: "10px",
                }}
              >
                {" "}
                Billing Cycle
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  marginBottom: 1,
                  fontFamily: "Inter",
                }}
              >
                <Stack
                  sx={{
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                  gap={1}
                >
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "11px",
                      fontFamily: "Inter",
                      color: "#1A1A1A",
                    }}
                  >
                    {" "}
                    Every Month
                  </Typography>
                </Stack>
              </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: 1,
                  fontFamily: "Inter",
                  fontSize: "10px",
                }}
              >
                {" "}
                Created
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  marginBottom: 1,
                  fontFamily: "Inter",
                }}
              >
                <Stack
                  sx={{
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                  gap={1}
                >
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "11px",
                      fontFamily: "Inter",
                      color: "#1A1A1A",
                    }}
                  >
                    {" "}
                    25/12/2024
                  </Typography>
                </Stack>
              </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: 1,
                  fontFamily: "Inter",
                  fontSize: "10px",
                }}
              >
                {" "}
                Status
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  marginBottom: 1,
                  fontFamily: "Inter",
                }}
              >
                <Stack
                  sx={{
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                  gap={1}
                >
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "11px",
                      fontFamily: "Inter",
                      color: "#1A1A1A",
                    }}
                  >
                    {" "}
                    <FiberManualRecordIcon
                      sx={{ color: "#7CB92E", fontSize: "9px" }}
                    />{" "}
                    Active
                  </Typography>
                </Stack>
              </Typography>
            </Grid>
          </Grid>

          <TableContainer
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Plan Name</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell align="right">Billing Cycle</TableCell>
                  <TableCell align="right">Created</TableCell>
                  <TableCell align="right">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptions?.map((userDetails: ISubscribers, index) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    key={index}
                  >
                    <TableCell component="th" scope="row">
                      <Stack
                        sx={{
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                        gap={2}
                      >
                        <Avatar
                          src={loggedInUserData?.user?.avatar}
                          sx={{
                            height: "40px",
                            width: "40px",
                            fontSize: "11px",
                          }}
                          alt={`${loggedInUserData?.user?.firstName}`}
                        />
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontFamily: "Inter",
                          }}
                        >
                          {"  "}
                          <Typography
                            component={"span"}
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              fontFamily: "Inter",
                            }}
                          >
                            {" "}
                            {loggedInUserData?.user?.firstName}
                          </Typography>
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="right">
                      {userDetails?.plan?.name}
                    </TableCell>
                    <TableCell align="right">
                      ₹
                      {userDetails?.plan?.offerValue === 0
                        ? userDetails?.plan?.totalPlanValue
                        : userDetails?.plan?.offerValue}
                    </TableCell>
                    <TableCell align="right">
                      {userDetails?.plan?.interval}
                      {"   "}
                      {userDetails?.plan?.interval > "1"
                        ? `${getStaticValue(userDetails?.plan?.duration)}s`
                        : getStaticValue(userDetails?.plan?.duration)}{" "}
                    </TableCell>
                    <TableCell align="right">
                      {new Date(userDetails?.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="right">
                      {/* {subscription.active ? ( */}
                      <Chip
                        icon={
                          <FiberManualRecordIcon
                            sx={{ color: "#7CB92E", fontSize: "15px" }}
                          />
                        }
                        label="Active"
                        sx={{
                          color: "#515151",
                          backgroundColor: "#7CB92E33",
                          "& .MuiChip-icon": {
                            color: "#7CB92E",
                          },
                        }}
                      />
                      {/* ) : (
                  <Chip
                    icon={
                      <FiberManualRecordIcon
                        sx={{ color: "#7CB92E", fontSize: "15px" }}
                      />
                    }
                    label="Active"
                    sx={{
                      color: "#515151",
                      backgroundColor: "#7CB92E33",
                      "& .MuiChip-icon": {
                        color: "#7CB92E",
                      },
                    }}
                  />
                )} */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>

      <Paper
        sx={{
          p: { xs: 1, md: 5 },
          mt: 2,
          height: { xs: "45vh", md: "60vh" },
          boxShadow: "none",
        }}
      >
        <Stack display={"flex"} flexDirection={"row"}>
          <Stack>
            {subscriptions.map((subscription: ISubscribers, index) => (
              <Typography
                key={index}
                sx={{
                  fontSize: { xs: "15px", md: "18px" },
                  fontWeight: "500",
                  fontFamily: "Inter",
                  color: "#3C5AA0",
                  pt: { xs: 1, md: 0 },
                }}
              >
                Start Date{" "}
                {new Date(subscription?.createdAt).toLocaleDateString()}
                {/* <Chip label="Daily" sx={{ color: "#515151", ml: 2 }} /> */}
              </Typography>
            ))}
          </Stack>
          <Stack></Stack>
        </Stack>

        <Stack>
          <SimpleBar style={{ height: `${height}px` }}>
            <Grid container spacing={2} sx={{ padding: 2 }}>
              {isLoading && (
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <SmallLoader />
                </Grid>
              )}

              {!isLoading &&
                subscriptions.map((subscription: ISubscribers) =>
                  subscription?.sequences.map((sequence) => {
                    const amount =
                      subscription?.plan?.offerValue === 0
                        ? subscription?.plan?.totalPlanValue
                        : subscription?.plan?.offerValue;
                    const isSelected = selectedAmounts.some(
                      (item) => item.id === sequence._id
                    );
                    return (
                      <Grid
                        item
                        key={sequence._id}
                        xs={4}
                        sm={6}
                        md={2}
                        lg={1.5}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "10px", md: "12px" },
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          {new Date(sequence?.startDate).toLocaleDateString()}
                        </Typography>

                        <Button
                          disabled={
                            sequence?.status === "PAID" ||
                            sequence?.status === "PAID_BY_CASH"
                          }
                          onClick={() =>
                            handleSelectAmount(sequence._id, amount)
                          }
                          sx={{
                            backgroundColor: isSelected ? "#50A1CA" : "#EEF7FD",
                            color: isSelected ? "#ffffff" : "#515151",
                            fontSize: { xs: "12px", md: "14px" },
                            border:
                              sequence?.status === "PAID" ||
                              sequence?.status === "PAID_BY_CASH"
                                ? "1px solid #7FC41B"
                                : "1px solid #50A1CA",
                            borderRadius: "30px",
                            overflow: "hidden",
                            display: "flex",
                            boxShadow: "none",
                            justifyContent: "center",
                            alignItems: "center",
                            width: { xs: "100%", md: "90%" },
                            mt: 1,
                            "&:hover": {
                              border: "1px solid #50A1CA",
                              boxShadow: "none",
                            },
                          }}
                        >
                          ₹{amount}
                        </Button>

                        <Typography
                          sx={{
                            fontSize: { xs: "10px", md: "12px" },
                            color: "#333",
                            fontWeight: 400,
                            mt: 1,
                            textAlign: "center",
                          }}
                        >
                          {getStaticValue(sequence?.status)}
                        </Typography>
                      </Grid>
                    );
                  })
                )}
            </Grid>
          </SimpleBar>
        </Stack>

        <Divider></Divider>
        <Stack sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
          <Button
            variant="contained"
            sx={{
              background:
                "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
              textTransform: "capitalize",
              borderRadius: "5px",
              boxShadow: "none",
              width: "150px",

              "&:hover": {
                background:
                  "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                color: "white",
              },
            }}
            onClick={() => handleRetryClick(communityId, validId, validId)}
          >
            Pay ₹{totalAmount}
          </Button>
        </Stack>
      </Paper>
      <PaymentSuccess
        txnid={transaction?.txnid || ""}
        open={successOpen}
        amount={transaction?.amount || ""}
        timer={timer}
        dialogText={""}
        onClose={handleSuccessClose}
        onClickNavigateButton={() => {
          navigate("/payments");
          setSuccessOpen(false);
        }}
      />
      <PaymentFailed
        open={failureOpen}
        onClose={handleFailureClose}
        amount={transaction?.amount || ""}
        txnid={transaction?.txnid || ""}
        onClickNavigateButton={() => {
          navigate("/payments");
          setFailureOpen(false);
        }}
        dialogText={""}
        timer={timer}
        OnClickRetryButtonFunction={() => {
          handleRetryClick(community, planId, "");
          setFailureOpen(false);
        }}
      />
    </>
  );
};
export default UserSubscription;
