import { Box, Grid } from "@mui/material"
import { Subscription } from "./Subscription"





export const SubscriptionList = () => {
    return (
        <>
        <Grid container sx={{pb:8}}>
        <Grid item xs={0.5} md={2.5}></Grid>
        <Grid item xs={11} md={7} lg={7}>
          <>
            <Box>
              <Subscription />
            </Box>
          </>
        </Grid>
        <Grid item xs={0.5} md={2.5}></Grid>
      </Grid>
        </>
    )
}