import React, { useContext, useEffect, useState, } from "react";
import {
  Button, IconButton, Typography, Link,
  Avatar,
  Grid,
  Dialog,
  Divider,
  DialogActions,
  DialogTitle,
  DialogProps,
  SelectChangeEvent,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { EditIcon, } from "../../../assets/icons";
import LinearProgress, { linearProgressClasses, } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { useLocation, useNavigate } from 'react-router-dom';
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import { AuthContext } from "../../../contexts/Auth.context";
import store from "../../../store";
import Close from "@mui/icons-material/Close";
import { ADMIN_APP_BASE_URL } from "../../../configurations/url.config";



const Sidebar = () => {
  const navigate = useNavigate();
  //const userData = useLocation();

  // const handleCreateNew = () => {
  //   navigate("/explore-create-community");
  // };

  const navigateToAdminFlowCreateCommunnity = () => {
    const token = localStorage.getItem("access-token");
    const redirectPath = encodeURIComponent('/explore-create-community');
    const url = `${ADMIN_APP_BASE_URL}/login?token=${token}&redirect=${redirectPath}`;
    window.open(url, '_blank');
  };

  const handleExploreCommunities = () => {
    navigate("/explore-communities");
  };

  //const users = userData?.state?.data;
  //console.log("USER",users?.firstName)
  // const [profileImage, setProfileImage] = useState(
  //   "https://media.licdn.com/dms/image/C4D03AQHY0lgjd9835g/profile-displayphoto-shrink_800_800/0/1657463160696?e=2147483647&v=beta&t=Pzo6gxl5qtxsg875yYk1-9g21CQrNtaFcIvyWKjSYtc"
  // );


  const usersData = store.getState();
  //console.log(usersData.loggedInUser.user,'hello world');

  // const [userId, setUserId] = useState(usersData.loggedInUser.user?.id)
  // console.log(userId);


  // const { user } = useContext(AuthContext);
  const { logout } = useContext(AuthContext);

  //console.log(user)
  // const location = useLocation();



  const [progress, setProgress] = useState(0);





  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#3B9B7F" : "#3B9B7F",
    },
  }));


  const calculateProgress = () => {
    let completedFields = 0;
    const userData = usersData.loggedInUser.user;
    if (userData?.firstName) completedFields += 10;
    if (userData?.lastName) completedFields += 5;
    if (userData?.userName) completedFields += 10;
    if (userData?.isPhoneVerified) completedFields += 20;
    if (userData?.isEmailVerified) completedFields += 20;
    if (userData?.about) completedFields += 20;
    if (userData?.address) completedFields += 5;
    if (userData?.pincode) completedFields += 5;
    if (userData?.city) completedFields += 5;
    setProgress(completedFields);
  };


  useEffect(() => {
    calculateProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersData]);


  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const [fullWidth, setFullWidth] = React.useState(true);
  // const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('lg');


  // const isMobile = useMediaQuery("(max-width:600px)");

  const location = useLocation();
  const isExploreCommunitiesPage = location.pathname === "/explore-communities" || location.pathname === "/invites-communities" ||
    location.pathname === "/trending-communities" || location.pathname === "/joined-communities" || location.pathname === "/admin-communities";
  const isCreateCommunityPage = location.pathname === "/explore-create-community";


  const firstName = usersData.loggedInUser.user?.firstName;

  const Name = firstName && firstName.length > 8 ? firstName.substring(0, 8) + '...' : firstName;


  const [open, setOpen] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("xs");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleMaxWidthChange = (event: SelectChangeEvent<typeof maxWidth>) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };



  return (
    <>

      {/* mobile view header */}


      <Box sx={{
        display: { xs: "block", sm: "block", md: "none", lg: "none" },
        position: "sticky",
        top: 0,
        backgroundColor: "#ffffff",
        zIndex: 1000, // Adjust the zIndex as needed

      }}>

        <Grid container>
          <Grid item xs={8} spacing={2}>
            <Stack p={1} direction={"row"} alignItems={'center'} spacing={3} >
              <Avatar
                style={{
                  width: '75px',
                  height: '75px',
                  borderRadius: '50%',
                  background: "linear-gradient(91.58deg, #3C5AA0 21.06%, #467DB4 66.97%, #50A1CA 92.96%)",
                }} src={usersData?.loggedInUser?.user?.avatar}
              >
                {usersData?.loggedInUser?.user?.avatar ? null : usersData?.loggedInUser?.user?.firstName?.charAt(0) +
                  '' + usersData?.loggedInUser?.user?.lastName?.charAt(0)}
              </Avatar>
              <Typography
                variant="h4"
                sx={{ fontSize: "20px", fontWeight: 600 }}>
                {Name}
                <Typography
                  variant="h6"
                  sx={{ fontSize: "13px", fontWeight: 500, color: "#838383" }}
                >
                  {usersData.loggedInUser.user?.phoneNumber}
                </Typography>
              </Typography>
              <IconButton
                aria-label="Edit Profile Image"
                component="span"
                sx={{
                  width: "10px",
                  height: "10px",
                  color: "black",
                }}
              >
                <EditIcon onClick={() => navigate(`/profile-edit/${usersData?.loggedInUser?.user?.id}`)} sx={{ color: "#565656", fontSize: '20px' }} />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={4} alignContent={"center"}>
            <Stack direction={"row"} justifyContent={"end"} alignItems={"center"}>
              <IconButton
                disableRipple
                size="large"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
              >
                <PowerSettingsNewOutlinedIcon onClick={handleClickOpen} sx={{ color: "#565656", fontSize: '20px' }} />
              </IconButton>

              <Dialog maxWidth={maxWidth} open={open} onClose={handleClose}>
                <DialogActions sx={{ backgroundColor: "#F0F9FF" }}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{ backgroundColor: "#F0F9FF" }}
                  >
                    <Stack></Stack>
                    <Stack></Stack>
                    <Stack sx={{ backgroundColor: "#F0F9FF" }}>
                      <Box
                        onClick={handleClose}
                        sx={{
                          backgroundColor: "#50A1CA",
                          padding: "4px",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          fontSize: "10px",
                          mt: "0.5px",
                          mr: "10px",
                          cursor: "pointer",
                          "&:hover": {
                            // backgroundColor: "#50A1CA", // Background color on hover
                            cursor: "pointer",
                            border: "none",
                          },
                        }}
                      >
                        <Close sx={{ fontSize: "15px" }} />
                      </Box>
                    </Stack>
                  </Stack>
                </DialogActions>
                <DialogTitle>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      font: "Roboto",
                      // color: "red",
                    }}
                  >
                    Are you sure you want to log out?
                  </Typography>
                </DialogTitle>
                <Divider />

                <Stack
                  direction="row"
                  sx={{ alignItems: "center", justifyContent: "center" }}
                >
                  <DialogActions>
                    <Button
                      size="small"
                      onClick={logout}
                      sx={{
                        cursor: "pointer",
                        pr: 1,
                        textTransform: "capitalize",
                        color: "White",
                        background:
                          "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                      }}
                    >
                      Yes
                    </Button>
                    <Divider orientation="vertical" style={{ marginLeft: 25 }} />
                  </DialogActions>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      cursor: "pointer",
                      textTransform: "capitalize",
                      color: "#50A1CA",
                      border: "1px solid #50A1CA",
                    }}
                    onClick={handleClose}
                  >
                    No
                  </Button>
                </Stack>
              </Dialog>


            </Stack>
          </Grid>
        </Grid>
      </Box>





      {/* Big Screens View */}
      <Box display={"flex"} pl={3} sx={{ display: { xs: "none", sm: "block", md: "block", lg: "block" } }}>
        <Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Stack p={1}>
              <Avatar
                style={{
                  width: '75px',
                  height: '75px',
                  borderRadius: '50%',
                  background: "linear-gradient(to right bottom, #50a1ca, #3b9b7f)",
                }} src={usersData?.loggedInUser?.user?.avatar} >
                {usersData?.loggedInUser?.user?.avatar ? null : usersData?.loggedInUser?.user?.firstName?.charAt(0) +
                  '' + usersData?.loggedInUser?.user?.lastName?.charAt(0)}
              </Avatar>
            </Stack>


            <Stack justifyContent={"center"}  >
              <Typography
                variant="h4"
                sx={{ fontSize: "25px", fontWeight: 600, }}
              >
                {Name}
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: "14px", fontWeight: 500, color: "#838383", }}>
                {usersData.loggedInUser.user?.phoneNumber}
              </Typography>
            </Stack>
            <Stack justifyContent={"center"}>
              <IconButton
                aria-label="Edit Profile Image"
                component="span"
                sx={{
                  width: "10px",
                  height: "10px",
                  color: "black",

                }}
              >
                <EditIcon onClick={() => navigate(`/profile-edit/${usersData?.loggedInUser?.user?.id}`)} ></EditIcon>
              </IconButton>

            </Stack>
            <Stack>
            </Stack>
          </Stack>


          <Stack p={1} mt={0}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "11px", color: "#838383", mb: 0.5 }}
              >
                Profile Completion
              </Typography>
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >{`${progress}%`}</Typography>
            </Stack>
            <BorderLinearProgress variant="determinate" value={progress} />
          </Stack>





          <Link
            // onAbort={handleCreateNew}
            onClick={handleExploreCommunities}
            sx={{
              background: isExploreCommunitiesPage
                ? "#F4F4F4"
                : "#ffffff",
              borderRadius: isExploreCommunitiesPage ? "10px 0px 0px 10px" : "none",
              textDecoration: "none",
              boxShadow: isExploreCommunitiesPage ? "1px 1px 3px rgba(0, 0, 0, 0.2)" : "none",
              cursor: "pointer",
            }}
          >
            <Stack
              sx={{ p: 4 }}
              mt={1}
              justifyContent={"center"}
              alignContent={"center"}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: "15px",
                  fontWeight: 700,
                  textAlign: "center",
                  color: "#000000",
                }}
              >
                Discover and join communities for<br></br> premium content
              </Typography>

              <Typography
                sx={{
                  fontSize: "9px",
                  textAlign: "center",
                  color: "#7D7D7D",
                  mt: 1,
                }}
              >
                Expand your knowledge, stay connected with networks and gain
                unprecedented access to resources and opportunities like never
                before
              </Typography>

              <Typography sx={{ textAlign: "center", p: 1 }}>
                <PersonAddAltOutlinedIcon
                  sx={{ color: "#8F8F8F", fontSize: "30px" }}
                />
              </Typography>
              <Stack
                direction={"row"}
                spacing={2}
                alignContent={"center"}
                justifyContent={"center"}
              >
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  fullWidth
                  sx={{
                    background: "linear-gradient(to right bottom, #50a1ca, #3b9b7f)",
                    textTransform: "capitalize",
                    borderRadius: "100px",
                    fontSize: "16px",
                    width: 210,
                  }}
                  onClick={handleExploreCommunities}
                >
                  Explore Communities
                </Button>
              </Stack>
            </Stack>
          </Link>



          <Link
            onAbort={navigateToAdminFlowCreateCommunnity}
            onClick={navigateToAdminFlowCreateCommunnity}
            sx={{
              background: isCreateCommunityPage
                ? "#F4F4F4"
                : "#ffffff",
              borderRadius: isCreateCommunityPage ? "10px 0px 0px 10px" : "none",
              textDecoration: "none",
              boxShadow: isCreateCommunityPage ? "1px 1px 3px rgba(0, 0, 0, 0.2)" : "none",
              cursor: "pointer",
            }}
          >
            <Stack
              sx={{
                p: 4,
              }}
              mt={1}
              justifyContent={"center"}
              alignContent={"center"}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: "15px",
                  fontWeight: 700,
                  textAlign: "center",
                  color: "#000000",
                }}
              >
                Start Building your Community <br></br>Right Away
              </Typography>

              <Typography
                sx={{
                  fontSize: "9px",
                  textAlign: "center",
                  color: "#7D7D7D",
                  mt: 1,
                }}
              >
                Streamline community management with tools for data,
                communication, subscriptions, payments, content, and analytics.
              </Typography>

              <Typography sx={{ textAlign: "center", p: 1 }}>
                <BorderColorOutlinedIcon
                  sx={{ color: "#8F8F8F", fontSize: "30px" }}
                />
              </Typography>

              <Stack
                direction={"row"}
                spacing={2}
                alignContent={"center"}
                justifyContent={"center"}
              >
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  fullWidth
                  sx={{
                    background:
                      "linear-gradient(to right bottom, #50a1ca, #3b9b7f)",
                    textTransform: "capitalize",
                    borderRadius: "100px",
                    fontSize: "16px",
                    width: 210,
                  }}
                  onClick={navigateToAdminFlowCreateCommunnity}
                >
                  Create Community
                </Button>
              </Stack>
            </Stack>

          </Link>

          <Stack
            direction={"row"}
            spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            alignContent={"center"}
            justifyContent={"center"}
            pt={1}
          >
            <Link href="https://onecommunn.com/terms-of-uses" target="_blank">
              <Typography sx={{ color: "#8F8F8F", fontSize: "12px" }}>
                Terms & Conditions {" "}
              </Typography>
            </Link>
            <Link href="https://onecommunn.com/privacy-policy" target="_blank">
              <Typography sx={{ color: "#8F8F8F", fontSize: "12px" }}>
                {" "}
                Privacy Policy {" "}
              </Typography>
            </Link>
            <Link href="https://onecommunn.com/refund-policy" target="_blank">
              <Typography sx={{ color: "#8F8F8F", fontSize: "12px" }} >
                Refund Policy{" "}
              </Typography>
            </Link>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default Sidebar;
