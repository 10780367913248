
import * as React from "react";
// import { useHistory } from 'react-router-dom';
import { StyledLogin, error } from "./Login.styles";
import { Alert, AlertTitle, Box, Button, CircularProgress, Divider, Grid, IconButton, InputAdornment, Snackbar, Stack, TextField, Typography } from "@mui/material";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import { AuthContext } from "../../../contexts/Auth.context";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSnackbar } from "notistack";
import CloseIcon from '@mui/icons-material/Close';
import SplashScreen from "../../Loader/SplashScreen";
import { metaConfig } from "../../../utils/constants";
// import { BASE_URL } from "../../../configurations/url.config";
// import { auth, google, facebook } from "../../../configurations/fireBase"
// import { signInWithPopup } from "@firebase/auth";
// import axios from "axios";

// << ---- Class or interface definition  starts ---- >> 
interface FormValues {
  firstName: string;
  lastName: string;
  emailId: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string
  isPhoneVerified: boolean;
  userName: string;
};

// << ---- Class or interface definition  Ends ---- >> 

// << ---- Register component starts  ---- >>
export const Register = () => {

  // << ---- Used hooks
  const navigate = useNavigate();
  const location = useLocation();



  // << ---- components properties starts ------------------------- >>
  const [userNames, setUserNames] = useState<string[]>([]);
  const authContex = React.useContext(AuthContext);
  // const [userName, setUserName] = useState<string>('')
  const [formValues, setFormValues] = useState<FormValues>({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: location?.state?.phoneNumber,
    password: "",
    confirmPassword: "",
    isPhoneVerified: true,
    userName: ""

  });
  const [formErrors, setFormErrors] = useState<FormValues>({
    firstName: '',
    lastName: '',
    emailId: '',
    phoneNumber: "",
    password: '',
    confirmPassword: '',
    isPhoneVerified: true,
    userName: ''
  });
  // const [createShowPassword, setCreateShowPassword] = React.useState(false);
  // const [confirmShowPassword, setConfirmShowPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isShowingSplash, setIsShowingSplash] = useState(false);

  // << Function definition  starts ------------------------------
  // const generateUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   //const { name, value } = event.target;
  //   if (formValues.lastName.length >= 0) {
  //     let count = 4;
  //     while (count > 0) {
  //       let uname = formValues.firstName + formValues.lastName.slice(0, 3) + Math.floor(1000 + Math.random() * 9000);
  //       userNames.push(uname.toLocaleLowerCase());
  //       // count = count -1;
  //       --count;
  //     }

  //   }
  // }

  const generateUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Assuming formValues is accessible here
    const { firstName, lastName } = formValues;
    const newNames: string[] = [];
    if (firstName.length >= 4) {
      let count = 12;
      while (count > 0) {
        let uname = firstName + (lastName ? lastName.slice(0, 3) : '') + Math.floor(1000 + Math.random() * 9000);
        newNames.push(uname.toLocaleLowerCase());
        --count;
      }
    }
    setUserNames(newNames);
  }

  const handleUserName = (event: any) => {
    const { outerText } = event.target;
    // setUserName(outerText)
    setFormValues({ ...formValues, userName: outerText });
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleCloseSnackbar = () => {
    setErrorMessage(null);
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const errors: FormValues = {
      firstName: '',
      lastName: '',
      emailId: '',
      phoneNumber: "",
      password: '',
      confirmPassword: '',
      isPhoneVerified: true,
      userName: ''

    };
    if (!formValues.firstName) {
      errors.firstName = 'Please Enter your First Name';
    } else if (!/^[A-Z,a-z ,.'-]+$/.test(formValues.firstName)) {
      errors.firstName = 'Special characters not allowed';
    } else if ((formValues.firstName.length < 4) || (formValues.firstName.length > 50)) {
      errors.firstName = 'Please Enter min 3 or max 50 characters';
    }
    // if (!formValues.lastName) {
    //   errors.lastName = 'Please Enter your Last Name';
    // } else if ((!/^[A-Z,a-z ,.'-]+$/.test(formValues.lastName))) {
    //   errors.lastName = 'Special characters not allowed';
    // } else if ((formValues.lastName.length < 1) || (formValues.lastName.length > 50)) {
    //   errors.lastName = 'Please Enter min 1 Characters';
    // }
    if (!formValues.emailId) {
      errors.emailId = 'Please Enter your Email ID';
    } else if (!/\S+@\S+\.\S+/.test(formValues.emailId)) {
      errors.emailId = 'Please Enter a valid Email ID';
    }
    if (!formValues.phoneNumber) {
      errors.phoneNumber = 'Please enter Mobile Number';
    } else if (!/^\d{10}$/.test(formValues.phoneNumber)) {
      errors.phoneNumber = 'Please enter valid Mobile Number'
    }
    // if (!formValues.password) {
    //   errors.password = 'Please enter a password';
    // } else if (formValues.password.length < 8) {
    //   errors.password = 'Password must be at least 8 characters';
    // } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,15}$/.test(formValues.password)) {
    //   errors.password = 'Password must be in valid format';
    // }
    // if (!formValues.confirmPassword) {
    //   errors.confirmPassword = 'Re-Enter Password'
    // } else if ((formValues.password) !== (formValues.confirmPassword)) {
    //   errors.confirmPassword = 'Password mismatched';
    // }
    // if (!formValues.userName) {
    //   errors.userName = "Please enter Username"
    // } else if (formValues.userName.length < 0) {
    //   errors.userName = "Please enter a Username"
    // }


    setFormErrors(errors);

    // if ((formValues.firstName) && (formValues.lastName) && (formValues.email) && (formValues.phoneNumber) && (formValues.password) && (formValues.confirmPassword)) {
    //   alert('signupsuccesfully')
    // }
    if (formValues.password !== formValues.confirmPassword) {
      return;
    }

    const data = JSON.stringify(formValues);
    const response: any = await authContex.autoCreate(data)
    try {
      setIsLoading(true)
      if (response.status === 200) {
        // Replace the current history entry with the landing page
        window.history.replaceState({ id: 2, data: response.data }, '/explore-communities', '/explore-communities');
        enqueueSnackbar("Account Created successfully", {
          variant: "success",
        });

        // Show splash screen for 2 seconds
        setIsShowingSplash(true); // Set a state to control the visibility of the splash screen
        setTimeout(() => {
          setIsShowingSplash(true); // Hide the splash screen after 2 seconds
          navigate("/explore-communities", { state: { id: 2, data: response.data } });
        }, 2000);
      } else if (response?.response.data && Array.isArray(response.response.data)) {
        // Handle array of errors
        const errorMessages = response.response.data.map((error: { msg: string }) => error.msg);
        setErrorMessage(errorMessages.join(", ")); // Set the error message
      } else {
        console.log(response.response.data.message, "error");
        setErrorMessage(response.response.data.message);
      }
    } catch (error) {
      console.log("error");
    }
    finally {
      setIsLoading(false)
    }


  };
  // const handleClickCreateShowPassword = () => setCreateShowPassword((show) => !show);
  // const handleClickConfirmShowPassword = () => setConfirmShowPassword((show) => !show);


  React.useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      const userInfo = JSON.parse(storedUserInfo);

      // Update the formValues with user information
      setFormValues({
        ...formValues,
        firstName: userInfo.given_name || "",
        lastName: userInfo.family_name || "",
        emailId: userInfo.email || "",
        // Update other form fields as needed
      });
    }
    // eslint-disable-next-line
  }, []);

  // const handleGoogleLogin = async () => {
  //   SocialLogin(google);
  // };

  // const handleFacebookLogin = async () => {
  //   SocialLogin(facebook);
  // }

  // const SocialLogin = async (login: any) => {
  //   const response: any = await signInWithPopup(auth, login)
  //   const socialProfile = response['_tokenResponse'];
  //   const user = response.user;
  //   const autoCreateData = {
  //     firstName: socialProfile.firstName,
  //     lastName: socialProfile.lastName,
  //     emailId: socialProfile.email,
  //     isEmailVerified: socialProfile.emailVerified,
  //     avatar: socialProfile.photoUrl,
  //     phoneNumber: user?.phoneNumber

  //   };

  //   axios.post(`${BASE_URL}/auth/social-login`, autoCreateData)
  //     .then(async (response) => {
  //       const { user } = response?.data
  //       const autologinResponse = await authContex.autoLogin("", user?.emailId);
  //       handleLoginResponse(autologinResponse);


  //     })
  //     .catch((error) => {
  //       console.error("Auto login error:", error);
  //     });

  // }



  // const handleLoginResponse = async (response: any) => {

  //   if (response?.status === 200) {
  //     const { user, adminCommunities } = response?.data;
  //     if (adminCommunities && adminCommunities.length > 0) {
  //       console.log("INSIDE 1")
  //       navigate("/home", { state: { id: user?.community } });
  //     } else if (!adminCommunities || adminCommunities.length === 0) {
  //       console.log("INSIDE 2")
  //       navigate("/explore-communities", {
  //         state: { id: 2, data: user },
  //       });
  //     } else if (user?.community) {
  //       navigate("/dashboard", { state: { id: 3, data: user } });
  //     }
  //   } else if (response.status === 403) {

  //   } else if (response?.status === 404) {

  //   }

  // };


  // <<<<<<<<<< HTML COMPONENT STARTS HERE >>>>>>>>>>>>>
  return (
    <>
      <Helmet>
        <title>{metaConfig.title.loginPage}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.description} />
        <meta name="keywords" content={metaConfig.description} />
      </Helmet>

      <StyledLogin>
        {isShowingSplash && <SplashScreen />}
        <Grid container>
          <Grid
            item
            md={7}
            sx={{
              background: "linear-gradient(to right bottom, #50a1ca,#469EA7)",
              display: { xs: "none", md: "block" },
            }}
          >
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ height: "100%", width: "100%" }}
            >
              <Box sx={{ cursor: "pointer" }}>
                <Box
                  component={"img"}
                  src={require("./../../../assets/images/registerbckg.png")}
                  alt=""
                  maxWidth={"100%"}
                />
              </Box>
              <Stack alignItems={"center"} sx={{ mt: 1 }}>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: "28px",
                    fontWeight: "700",
                    color: "#ffffff",
                    fontFamily: "Inter",
                  }}
                >
                  Unlock Community Access
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#ffffff",
                    fontFamily: "Montserrat",
                    pt: 1,
                  }}
                >
                  Take the first step to embrace the limitless possibilities
                  that await you here.
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack
              sx={{
                height: "100vh",
                overflow: "auto",
                alignItems: 'center',
                justifyContent: {
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "center",
                  lg: "center",
                },
              }}
              className="login-form-container"
            >
              <Box>
                <Stack
                  direction={"row"}
                  sx={{
                    pr: 10,
                    pl: 2,
                    pt: 0.5,
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <ArrowBackIcon
                    sx={{ color: "#1C1B1F", cursor: "pointer" }}
                    onClick={() => navigate("/login")}
                  />
                </Stack>
                <Divider
                  sx={{ pt: 1, display: { xs: "none", md: "block" } }}
                ></Divider>

                <Box
                  component={"img"}
                  src={require("./../../../assets/images/RegisterMBg.png")}
                  sx={{
                    height: "126px",
                    width: "100%",
                    display: { xs: "block", md: "none" },
                  }}
                  alt=""
                ></Box>
                <Box
                  component={"form"}
                  sx={{
                    mt: { xs: "5vh", sm: "0vh", md: "0vh", lg: "0vh" },
                    pt: { xs: 3, sm: 3, md: 3, lg: 3, xxl: 5 },
                    width: { xs: "auto", md: '328px' },
                    px: { xs: 5, md: 0 }
                  }}
                >
                  <Stack direction={"row"} justifyContent={"center"}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#424242",
                        fontFamily: "Inter",
                        pb: 3,
                      }}
                    >
                      Register
                    </Typography>
                    {/* <Typography sx={{
                      fontSize: '14px',
                      fontWeight: '500',
                      color: '#424242',
                      fontFamily: 'Inter',

                    }}>Skip</Typography> */}
                  </Stack>
                  <Stack>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange(e);
                            generateUserName(e);
                          }}
                          value={formValues.firstName}
                          fullWidth
                          label="Name"
                          id="outlined-size-small"
                          size="small"
                          type="text"
                          name="firstName"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{
                                  cursor: "pointer",
                                  backgroundColor: "#E5E3EF",
                                  borderRadius: "100px",
                                }}
                              >

                              </InputAdornment>
                            ),
                            style: {
                              borderRadius: "30px",
                              backgroundColor: "#ffffff",
                            },
                          }}
                        />
                        {/* {formErrors.firstName && (
                          <Stack sx={error}>{formErrors.firstName}</Stack>
                        )} */}
                      </Grid>

                      {/* <Grid item xs={12} sm={6}>
                        <TextField
                          value={formValues.lastName}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChange(e);
                            generateUserName(e);
                          }}
                          fullWidth
                          label="Last Name"
                          id="outlined-size-small"
                          size="small"
                          type="text"
                          name="lastName"
                          sx={label}
                        />
                        {formErrors.lastName && (
                          <Stack sx={error}>{formErrors.lastName}</Stack>
                        )}
                      </Grid> */}
                    </Grid>
                  </Stack>

                  {/* {userNames.length > 0 ? (
                    <Stack>
                      <Grid container spacing={2} sx={{ pt: 4 }}>
                        <Grid item xs={12}>
                          <Typography sx={{ fontSize: "15px", fontWeight: 400, pb: 1 }}>
                            Available usernames
                          </Typography>

                          <Grid container spacing={1}>
                            {userNames.slice(0, 8).map((e, index) => (
                              <Grid item key={index} xs={6} sm={6} md={6} lg={3}>
                                <Chip
                                  label={e}
                                  component="a"
                                  sx={{
                                    color: "#50A1CA",
                                    border: "1px solid #50A1CA",
                                    marginBottom: 1,
                                  }}
                                  variant="outlined"
                                  onClick={(e: any) => handleUserName(e)}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Stack>


                  ) : (
                    ""
                  )} */}

                  {/* <Stack>
                    <Grid container spacing={2} sx={{ pt: 4 }}>
                      <Grid item xs={12}>
                        <TextField
                          value={formValues.userName}
                          fullWidth
                          label="Create User Name"
                          size="small"
                          name="userName"
                          onChange={handleChange}
                        />
                        {formErrors.userName && (
                          <Stack sx={error}>{formErrors.userName}</Stack>
                        )}
                      </Grid>
                    </Grid>
                  </Stack> */}

                  <Stack>
                    <Grid container spacing={2} sx={{ pt: 2 }}>
                      <Grid item xs={12}>
                        <TextField
                          aria-readonly
                          value={formValues.phoneNumber}
                          onChange={handleChange}
                          fullWidth
                          label="Mobile"
                          type="tel"
                          size="small"
                          name="phoneNumber"
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{
                                  cursor: "pointer",
                                  backgroundColor: "#E5E3EF",
                                  borderRadius: "100px",
                                }}
                              >

                              </InputAdornment>
                            ),
                            style: {
                              borderRadius: "30px",
                              backgroundColor: "#ffffff",
                            },
                          }}
                        // InputProps={{
                        //   readOnly: true,
                        // }}


                        />
                        {/* {formErrors.phoneNumber && (
                          <Stack sx={error}>{formErrors.phoneNumber}</Stack>
                        )} */}
                      </Grid>
                      <Grid item xs={12} >
                        <TextField
                          value={formValues.emailId}
                          onChange={handleChange}
                          fullWidth
                          label="Email"
                          type="email"
                          size="small"
                          name="emailId"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{
                                  cursor: "pointer",
                                  backgroundColor: "#E5E3EF",
                                  borderRadius: "100px",
                                }}
                              >

                              </InputAdornment>
                            ),
                            style: {
                              borderRadius: "30px",
                              backgroundColor: "#ffffff",
                            },
                          }}
                        />
                        {/* {formErrors.emailId && (
                          <Stack sx={error}>{formErrors.emailId}</Stack>
                        )} */}
                      </Grid>
                    </Grid>
                  </Stack>

                  {/* <Stack>
                    <Grid container spacing={2} sx={{ pt: 4 }}>
                      <Grid item xs={12}>
                        <TextField
                          value={formValues.emailId}
                          onChange={handleChange}
                          fullWidth
                          label="Email"
                          type="email"
                          size="small"
                          name="emailId"
                        />
                        {formErrors.emailId && (
                          <Stack sx={error}>{formErrors.emailId}</Stack>
                        )}
                      </Grid>
                    </Grid>
                  </Stack> */}

                  {/* <Stack>
                    <Grid container spacing={3} sx={{ pt: 4 }}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={formValues.password}
                          onChange={handleChange}
                          size="small"
                          name="password"
                          fullWidth
                          type={createShowPassword ? "text" : "password"}
                          label={"Create Password"}
                          placeholder="Create Password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                onClick={handleClickCreateShowPassword}
                                position="start"
                                sx={{ cursor: "pointer" }}
                              >
                                {createShowPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                        {formErrors.password && (
                          <Stack sx={error}>{formErrors.password}</Stack>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={formValues.confirmPassword}
                          onChange={handleChange}
                          name="confirmPassword"
                          size="small"
                          fullWidth
                          type={confirmShowPassword ? "text" : "password"}
                          label={"Confirm Password"}
                          placeholder="Password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                onClick={handleClickConfirmShowPassword}
                                position="start"
                                sx={{ cursor: "pointer" }}
                              >
                                {confirmShowPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                        {formErrors.confirmPassword && (
                          <Stack sx={error}>{formErrors.confirmPassword}</Stack>
                        )}
                      </Grid>
                    </Grid>
                  </Stack> */}

                  <Grid container sx={{ mt: 3 }}>
                    <Grid item>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignContent={"center"}
                        justifyContent={"center"}
                        mb={2}
                      >
                        <Button
                          size="large"
                          variant="contained"
                          type="submit"
                          onChange={(e: any) => setFormValues(e.target.value)}
                          onClick={handleSubmit}
                          fullWidth
                          sx={{
                            background:
                              "linear-gradient(to right bottom, #50a1ca, #3b9b7f)",
                            textTransform: "capitalize",
                            borderRadius: "100px",
                            fontSize: "16px",
                            width: 210,
                            boxShadow: 'none',
                          }}
                        >
                          {isLoading ? (
                            <CircularProgress sx={{ color: "grey" }} />
                          ) : (
                            "Save & Continue"
                          )}
                        </Button>
                      </Stack>
                      {errorMessage && (
                        <Snackbar
                          anchorOrigin={{ vertical: "top", horizontal: "right" }}
                          open={Boolean(errorMessage)}
                          autoHideDuration={6000}
                          onClose={handleCloseSnackbar}
                        >
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={handleCloseSnackbar}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                            onClose={handleCloseSnackbar}
                          >
                            <AlertTitle>Error</AlertTitle>
                            {errorMessage}
                          </Alert>
                        </Snackbar>
                      )}
                      {/* <Divider sx={{ pt: 2 }}>
                        <Box component={"span"}>OR Continue With</Box>
                      </Divider> */}
                      {/* <Stack
                        direction={"row"}
                        spacing={2}
                        justifyContent={"center"}
                        alignContent={"center"}
                        sx={{ pt: 2 }}
                      >
                        <Fab size="medium">
                          <Box
                            onClick={() => handleGoogleLogin()}
                            component={"img"}
                            src={require("./../../../assets/images/google.png")}
                          />
                        </Fab>
                        <Fab size="medium">
                          <Box
                            onClick={() => handleFacebookLogin()}
                            component={"img"}
                            src={require("./../../../assets/images/facebook.png")}
                          />
                        </Fab>
                      </Stack> */}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </StyledLogin>
    </>
  );
  // <<<<<<<<<< HTML COMPONENT ENDS HERE >>>>>>>>>>>>>>>>
};
// << ---- Register component ends  ---- >>

