import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import { ICommunity } from '../models/communities.modal';
import {
  getCommunities,
  checkCommunityAvailability,
  communityById,
  createCommunity,
  updateCommunityService,
  membershipCommunities,
  fetchTrendingCommunities,
  joinCommunity,
  suggestionCommunities,
  updateUserMappingCommunity,
  updateUserMappingCommunityBanner,
} from '../services/communities.service';
import store from '../store';
import { loadSelectedCommunity } from '../store/slices/CommunitySlice';

export const useCommunity = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [communities, setCommunities] = useState<ICommunity[]>([]);

  const getCommunitiesList = async () => {
    setIsLoading(true);
    const response = await getCommunities(getAccessToken());

    if (response.status === 200) {
      setCommunities(response.data);
      setIsLoading(false);
      return response.data;
    } else {
      enqueueSnackbar('Fetching communities failed', { variant: 'error' });
      setIsLoading(false);
      return [];
    }
  };

  const isCommunityExist = async (name: string) => {
    setIsLoading(true);
    const response = await checkCommunityAvailability(getAccessToken(), name);
    if (response.status === 200) {
      return true;
    } else if (response.status === 302) {
      return false;
    } else {
    }
    setIsLoading(false);
  };

  const getCommunityById = async (id: string) => {
    setIsLoading(true);
    console.log('5678');

    const response = await communityById(getAccessToken(), id);
    if (response.status === 200) {
      store.dispatch(loadSelectedCommunity(response.data));
      return response;
    } else if (response.status === 302) {
      return response;
    } else {
      // enqueueSnackbar("Fetching communities failed", { variant: "error" });
    }
    setIsLoading(false);
  };

  const createNewCommunity = async (CommunityData: any) => {
    try {
      const response = await createCommunity(getAccessToken(), CommunityData);
      if (response.status === 201) {
        setCommunities(response.data);
        store.dispatch(loadSelectedCommunity(response.data));
        enqueueSnackbar(' Community Created successfully', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Failed to Create Community', { variant: 'error' });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('An error occurred while Creating the Community', {
        variant: 'error',
      });
    }
  };

  const updateCommunityDetails = async (id: string, formData: any) => {
    setIsLoading(true);

    try {
      const response = await updateCommunityService(
        getAccessToken(),
        id,
        formData
      );
      if (response.status === 200) {
        setCommunities(response.data);
      } else {
        enqueueSnackbar('Failed to update Community', {
          variant: 'error',
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('An error occurred while update the Community', {
        variant: 'error',
      });
    }

    setIsLoading(false);
  };

  const memberCommunities = async () => {
    setIsLoading(true);
    try {
      const response = await membershipCommunities(getAccessToken());
      if (response?.status === 200) {
        return response?.data.data;
      }
    } catch (error) {
      enqueueSnackbar('An error occurred while update the Community', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getTrendingCommunities = async () => {
    setIsLoading(true);
    try {
      const response = await fetchTrendingCommunities(getAccessToken());
      if (response?.status === 200) {
        return response.data;
      } else {
        enqueueSnackbar('Failed to fetch trending communities', {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar('An error occurred while fetching the Community', {
        variant: 'error',
      });
    }

    setIsLoading(false);
  };

  const getSuggestionCommunities = async () => {
    setIsLoading(true);
    try {
      const response = await suggestionCommunities(getAccessToken());
      if (response?.status === 200) {
        return response.data;
      } else {
        enqueueSnackbar('Failed to fetch trending communities', {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar('An error occurred while fetching the Community', {
        variant: 'error',
      });
    }

    setIsLoading(false);
  };

  const joinToPublicCommunity = async (community_id: string) => {
    setIsLoading(true);
    try {
      const response = await joinCommunity(getAccessToken(), community_id);
      if (response.status === 'success') {
        enqueueSnackbar('You have successfully joined!', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Error while joining to public community!', {
          variant: 'error',
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar(
        'An error occurred while joining to Community, Please try again.',
        { variant: 'error' }
      );
    }
    setIsLoading(false);
  };
  const updateUserMappingDetails = async (id: string, formData: any) => {
    setIsLoading(true);

    try {
      const response = await updateUserMappingCommunity(
        getAccessToken(),
        id,
        formData
      );
      if (response.status === 200) {
        enqueueSnackbar('updated user in community Successfully', {
          variant: 'success',
        });
        return response;
      } else {
        enqueueSnackbar('Failed to update user in community', {
          variant: 'error',
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('An error occurred while update the Community', {
        variant: 'error',
      });
    }

    setIsLoading(false);
  };
  const updateUserMappingBanner = async (
    id: string,
    file: FileList | null,
    formData: FormData
  ) => {
    try {
      setIsLoading(true);
      const response = await updateUserMappingCommunityBanner(
        getAccessToken(),
        id,
        file,
        formData
      );

      if (response.status === 200) {
        enqueueSnackbar('Banner  updated successfully', { variant: 'success' });
        // setUpdatePostsFlag(true);
      } else {
        enqueueSnackbar('Failed to Update Banner ', { variant: 'error' });
      }

      setIsLoading(false);
      return response;
    } catch (error) {
      enqueueSnackbar('An error occurred while Updating  the  Banner', {
        variant: 'error',
      });
    }
  };

  return {
    isLoading,
    communities,
    isCommunityExist,
    getCommunityById,
    createNewCommunity,
    // toggleCommunityFavorite,
    updateCommunityDetails,
    setCommunities,
    memberCommunities,
    getTrendingCommunities,
    getCommunitiesList,
    joinToPublicCommunity,
    getSuggestionCommunities,
    updateUserMappingDetails,
    updateUserMappingBanner,
  };
};
