import React, { useContext } from "react";
import {
  Badge,
  Button,
  DialogContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { AppBar } from "../../Layout/components/NavigationBar/NavigationBar.styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { AuthContext } from "../../../contexts/Auth.context";

// import MoreIcon from "@mui/icons-material/MoreVert";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Group401Icon } from "../../../assets/icons";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";

import { ICommunity } from "../../../models/communities.modal";
import LoginPopUp1 from "../../Auth/Login/LoginPopUp1";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface INavbarProps {
  data: ICommunity;
}
const NoAuthHeader: React.FC<INavbarProps> = ({ data }) => {
  const { logout } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // const avatarProps = {
  //   ...stringAvatar(`${user?.firstName} ${user?.lastName}`),
  // };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  // const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setMobileMoreAnchorEl(event.currentTarget);
  // };

  // const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      // anchorEl={anchorEl}
      // anchorOrigin={{
      //   vertical: "top",
      //   horizontal: "right",
      // }}
      // id={menuId}
      // keepMounted
      // transformOrigin={{
      //   vertical: "top",
      //   horizontal: "left",
      // }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={logout}>Login</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      // anchorEl={mobileMoreAnchorEl}
      // anchorOrigin={{
      //   vertical: "top",
      //   horizontal: "right",
      // }}
      // id={mobileMenuId}
      // keepMounted
      // transformOrigin={{
      //   vertical: "top",
      //   horizontal: "right",
      // }}

      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <Group401Icon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };



  return (
    <>
      <Box
        component="nav"
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "block",
            lg: "block",
            xl: "block",
            xxl: "block",
          },
        }}
      >
        <AppBar sx={{ backgroundColor: "#FAFAFA" }}>
          <Stack>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={9}>
                <Stack>
                  <a
                    href="https://onecommunn.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box
                      ml={3}
                      mt={1}
                      component={"img"}
                      src={require("../../../assets/images/Communn-Logo.png")}
                      alt=""
                      sx={{ width: {xs:"50%", md:'20%'} }}
                    />
                  </a>
                </Stack>
              </Grid>

              <Grid
                item
                xs={3}
                sx={{
                  justifyContent: "end",
                  display: "flex",
                  alignItems: "center",
                }}
              >
              
                <React.Fragment>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      borderRadius: "30px",
                      background:
                        "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                      textTransform: "capitalize",
                      width: "140px",
                      mr: 2,
                    }}
                    onClick={handleClickOpen}
                  >
                    Login
                  </Button>

                  <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth
                  >
                    <Box
                      component={"img"}
                      src={require("./../../../assets/images/LoginMbg.png")}
                      sx={{
                        height: "100px",
                        width: "100%",
                        objectFit: "fill",
                        display: { xs: "block", md: "block" },
                      }}
                      alt=""
                    ></Box>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon sx={{ color: "white" }} />
                    </IconButton>
                    <DialogContent>
                      {data && <LoginPopUp1 data={data} />}
                    </DialogContent>
                  </BootstrapDialog>
                </React.Fragment>

                {/* <Box sx={{ display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <MoreIcon />
                  </IconButton>
                </Box> */}
              </Grid>
            </Grid>
          </Stack>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
    </>
  );
};

export default NoAuthHeader;
