import React, { Key, useContext, useEffect, useState } from "react";
import {
    Box,
    Button,
    Stack,
    Typography,
    IconButton,
    Grid,
    DialogContent,
    CssBaseline,
    AppBar,
    Toolbar,
    Divider,
    ListItemButton,
    ListItem,
    List,
    Drawer,
    Paper,
    Tooltip,
    Link,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Fab,
    CircularProgress,
} from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./About.css";
import { ICommunity } from "../../models/communities.modal";
import { ADMIN_APP_BASE_URL, BASE_URL, USER_APP_BASE_URL } from "../../configurations/url.config";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../contexts/Auth.context";
import { Helmet } from "react-helmet";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { useCommunity } from "../../hooks/useCommunity";
import SendRequestCommunityPreview from "../ExploreCommunities/CommunityPreview/SendRequestCommunityPreview";
import LoginPopUp1 from "../Auth/Login/LoginPopUp1";
import { loadSelectedCommunity } from "../../store/slices/CommunitySlice";
import { metaConfig } from "../../utils/constants";
import store from "../../store";
import { MenuIcon } from "../../assets/icons";
import CallIcon from '@mui/icons-material/Call';
import AboutGallery from "../ImageUploader/AboutGallery";
import MissionImage from "../../assets/images/Goal.png"
import VissionImage from "../../assets/images/Eye.png"
import NoData from "../AlternatePages/NoData";
import NoDataImage from "../../assets/icons/svg-icons/No Post.png"
import NewAboutServices from "./NewAboutServices";
import NewAboutTeam from "./NewAboutTeam";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import 'simplebar-react/dist/simplebar.min.css';
import { IPaymentList } from "../../models/payment.model";
import { usePayment } from "../../hooks/usePayment";
import CustomPayModel from "../Payments/CustomPayPopUp";
import PaymentSuccess from "../Payments/PaymentSuccessPopUp";
import PaymentFailed from "../Payments/PaymentFailedPopUp";
import NewAboutPlans from "./NewAboutPlans";
import NewAboutCourses from "./NewAboutCourses";
import { useSnackbar } from "notistack";







const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const WhatsAppButton = styled(Fab)({
    position: "fixed",
    bottom: 40,
    right: 20,
    backgroundColor: "#2952A2",
    color: "#ffffff",
    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
    zIndex: 1000,
    transition: "transform 0.3s",
    "&:hover": {
        transform: "scale(1.1)",
        backgroundColor: "#2952A2",
    },
});
interface Props {
    windows?: () => Window;
}

interface Member {
    user: {
        _id: string;
    };
}

const drawerWidth = 240;
const navItems = ['Home', 'Gallery', 'Services', 'Plans', 'Courses', 'Team', 'Contacts'];





const NewAboutShare = (props: Props) => {
    const [open, setOpen] = React.useState(false);
    const { isAuthenticated } = useContext(AuthContext);
    const { id } = useParams();
    const { windows } = props;


    const selectedCommunity = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity
    );
    const community_id = id ? id : selectedCommunity?._id;

    const [data, setData] = useState<ICommunity>();
    const [memberList, setMemberList] = useState<ICommunity[]>([]);
    const { getCommunitiesList, memberCommunities } = useCommunity();
    const [communities, setCommunities] = useState<ICommunity[]>([]);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [showFullDescriptionVission, setShowFullDescriptionVission] = useState(false);
    const [showFullDescriptionMission, setShowFullDescriptionMission] = useState(false);
    const { enqueueSnackbar } = useSnackbar();


    const [expanded, setExpanded] = useState<string | false>(false);
    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };



    const navigate = useNavigate();
    const dispatch = useDispatch();

    //join community
    const [copied, setCopied] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    const [loading, setLoading] = useState(true); // Add loading state

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const handleCopyLink = () => {
        const labelContent = `${USER_APP_BASE_URL}/communities/${data?._id}/about`;

        navigator.clipboard
            .writeText(labelContent)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 3000);
                enqueueSnackbar('Link copied successfully', { variant: 'success' });
                
            })
            .catch((error) => {
                console.error("Error copying text: ", error);
            });
    };

    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `${USER_APP_BASE_URL}/communities/${data?._id}/about`
    )}`;

    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        `${USER_APP_BASE_URL}/communities/${data?._id}/about`
    )}`;
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        `${USER_APP_BASE_URL}/communities/${data?._id}/about`
    )}`;
    const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
        `${USER_APP_BASE_URL}/communities/${data?._id}/about`
    )}`;



    const userData = store.getState();
    const navigateToAdminFlow = () => {
        const token = localStorage.getItem("access-token");
        const id = data?._id;
        const url = `${ADMIN_APP_BASE_URL}/login?communityId=${id}&token=${token}`;
        window.open(url, '_blank');
    };




    const handleClickOpen = () => {
        const usersData = store.getState();
        if (isAuthenticated) {
            if (data?.createdBy?._id === usersData?.loggedInUser?.user?.id) {
                navigateToAdminFlow();
                setModalOpen(false);
                setOpen(false);
            } else {
                setModalOpen(true);
            }
        } else {
            setOpen(true);
        }
    };


    // console.log("data", data)

    const getAdminCommunitiesList = async () => {
        const response = await getCommunitiesList();
        setCommunities(response);
    };

    const fetchData = async () => {
        try {
            const response = await memberCommunities();
            setMemberList(response);
        } catch (error) {
            console.log("error");
        }
    };

    // console.log(memberList, "memberList")

    useEffect(() => {
        fetchData();
        if (isAuthenticated) {
            getAdminCommunitiesList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [community_id]);

    // Assuming data?.members is an array of Member objects
    const memberLists: Member[] = data?.members || []; 

    // Now perform the check
    const isAlreadyJoined = memberLists.some(
        (member) => member.user?._id === userData?.loggedInUser?.user?.id
    );

    // console.log(isAlreadyJoined, "isAlreadyJoined");
    // const isAlreadyAdmin = communities?.some((admin) => admin._id === id);

    // console.log(isAlreadyJoined, "isAlreadyJoined")
    // console.log(isAlreadyAdmin, "isAlreadyAdmin")




    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${BASE_URL}/communities/${community_id}/home`
                );
                // console.log(response?.data?.community);
                setData(response.data?.community);
                // console.log(response?.data?.community);
                if (isAuthenticated) {
                    dispatch(loadSelectedCommunity(response.data?.community));
                }
                setLoading(false); 
            } catch (error) {
                console.log("error");
                setLoading(false); 
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [community_id]);

   
    const handleClose = () => {
        setOpen(false);
    };

    const handleButtonClick = () => {
        const id = data?._id;
        navigate('/home', { state: { id } });
        if (data) {
            dispatch(loadSelectedCommunity(data));
          } else {
            console.error("Community data is undefined");
          }
    };

    const handleClickPaid = () => {
    const id = data?._id;
    if(data?.collectSubscription === "YES" ){
      navigate('/subscriptions', { state: { id } });
      dispatch(loadSelectedCommunity(data));
    }else{
      navigate('/home', { state: { id } });
      if (data) {
        dispatch(loadSelectedCommunity(data));
      } else {
        console.error("Community data is undefined");
      }
    }
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ p: 1 }}>
            <Box component={"img"}
                src={data?.logo}
                sx={{
                    backgroundColor: 'white',
                    padding: 2,
                    height: { xs: "50px", md: '100px' },
                    width: { xs: "50px", md: '100px' },
                    position: "relative",
                    zIndex: 1,
                    borderRadius: '10px',

                }}
            />
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton >
                            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 500 }} >
                                {item}
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = windows !== undefined ? () => windows().document.body : undefined;

    const openSocialMediaProfile = (type: string) => {
        const socialLink = data?.socialLinks?.find((link) => link.type === type);

        if (socialLink) {
            window.open(socialLink.value, "_blank");
        }
    };
    const handleFacebookClick = () => {
        openSocialMediaProfile("facebook");
    };
    const handleYoutubeClick = () => {
        openSocialMediaProfile("youtube");
    };

    const handleTwitterClick = () => {
        openSocialMediaProfile("twitter");
    };

    const handleLinkedInClick = () => {
        openSocialMediaProfile("linkedin");
    };

    const handleInstagramClick = () => {
        openSocialMediaProfile("instagram");
    };


    const handleFacebookClickCommunn = () => {
        navigate("/")
    };
    const handleYoutubeClickCommunn = () => {
        navigate("/")
    };

    const handleTwitterClickCommunn = () => {
        navigate("/")
    };

    const handleLinkedInClickCommunn = () => {
        navigate("https://www.linkedin.com/company/one-communn/")
    };

    const handleInstagramClickCommunn = () => {
        navigate("https://www.instagram.com/onecommunn_official/")
    };


    const handleToggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };


    const handleToggleMission = () => {
        setShowFullDescriptionMission(!showFullDescriptionMission);
    };
    const handleToggleVission = () => {
        setShowFullDescriptionVission(!showFullDescriptionVission);
    };



    const buttonNames = [
        { name: "Home", url: "#home" },
        { name: "Gallery", url: "#gallery" },
        { name: "Services", url: "#services" },
        { name: "Plans", url: "#plans" },
        { name: "Courses", url: "#courses" },
        { name: "Team", url: "#team" },
        { name: "Contacts", url: "#contacts" }]

    const gridItems = [
        { label: "Members", value: data?.members?.length },
        { label: "No of Posts", value: data?.posts || 0 },
        { label: "Access", value: data?.type || 'NA' },
        { label: "Call Now", value: data?.phoneNumber || "-" },
    ]

    const visionsections = [
        { title: "VISION", content: data?.vision, img: VissionImage },
        { title: "MISSION", content: data?.mission, img: MissionImage }
    ]


    const contactDetails = [
        {
            label: "Call",
            value: data?.phoneNumber || " - ",
            fontSize: { xs: '10px', md: '16px' },
            fontWeight: 600,
        },
        {
            label: "Email",
            value: data?.email || " - ",
            fontSize: { xs: '10px', md: '16px' },
            fontWeight: 600,
        },
        {
            label: "Address",
            value: (
                <>
                    {data?.city || " - "} - {data?.zipCode || " - "}
                </>
            ),
            fontSize: { xs: '10px', md: '12px' },
            fontWeight: 600,
        }
    ];

    const socialMediaIcons = [
        { title: "Facebook", icon: FacebookIcon, onClick: handleFacebookClick },
        { title: "Youtube", icon: YouTubeIcon, onClick: handleYoutubeClick },
        { title: "Twitter", icon: TwitterIcon, onClick: handleTwitterClick },
        { title: "Linkedin", icon: LinkedInIcon, onClick: handleLinkedInClick },
        { title: "Instagram", icon: InstagramIcon, onClick: handleInstagramClick }
    ];
    const socialCommunnMediaIcons = [
        { title: "Facebook", icon: FacebookIcon, onClick: handleFacebookClickCommunn },
        { title: "Youtube", icon: YouTubeIcon, onClick: handleYoutubeClickCommunn },
        { title: "Twitter", icon: TwitterIcon, onClick: handleTwitterClickCommunn },
        { title: "Linkedin", icon: LinkedInIcon, onClick: handleLinkedInClickCommunn },
        { title: "Instagram", icon: InstagramIcon, onClick: handleInstagramClickCommunn }
    ];


    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };



    const defaultImages = [
        'https://upload-community-files.s3.ap-south-1.amazonaws.com/static/community_4.jpeg',
        'https://upload-community-files.s3.ap-south-1.amazonaws.com/static/community_3.png',
    ];


    const images = data?.gallery?.length ? data.gallery : defaultImages;

    // console.log(data);

    // const handleClickHome = () => {
    //     if (data?.collectSubscription === "YES") {
    //         if (data?.subscriptionStatus === "CREATED" || data?.subscriptionStatus === "EXPIRED") {
    //             const id = data?._id;
    //             navigate('/subscriptions', { state: { id } });
    //             dispatch(loadSelectedCommunity(data));
    //         } else {
    //             const id = data?._id;
    //             navigate('/home', { state: { id } });
    //             dispatch(loadSelectedCommunity(data._id));
    //         }
    //     } else {
    //         const id = data?._id;
    //         navigate('/home', { state: { id } });
    //         dispatch(loadSelectedCommunity(data?.community));
    //     }
    // };

    const { CustomPay, getPaymentStatusById } = usePayment();

    enum PaymentStatus {
        SUCCESS = "SUCCESS",
        PENDING = "PENDING",
        FAILED = "FAILED",
        USERCANCELLED = "USERCANCELLED",
    }

    const paymentResponse = (response: any) => {
        // console.log(response);
        const tnxId = response?.transactionId;
        const transaction = response?.transaction as IPaymentList;
        if (transaction) setTransaction(transaction);
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        // Set the width and height based on screen dimensions
        const width = Math.min(1000, screenWidth); // Set a maximum width of 900
        const height = Math.min(1000, screenHeight); // Set a maximum height of 900
        // Calculate the position to center the window
        const left = (screenWidth - width) / 2;
        const top = (screenHeight - height) / 2;
        const windowRef = window.open(
            response.url,
            // "_self",
            `addressbar=no,directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no, width=${width},height=${height},left=${left},top=${top}`
        );
        const intervalRef = setInterval(async () => {
            const paymentStatus = await getPaymentStatusById(tnxId);
            //console.log(paymentStatus, "paymentStatus");
            if (paymentStatus && paymentStatus.length > 0) {
                clearInterval(intervalRef);
                windowRef?.close();
                if (paymentStatus[0]?.status === PaymentStatus.SUCCESS) {
                    setSuccessOpen(true);
                } else {
                    setFailureOpen(true);
                }
            }
        }, 1000);
    };



    const [timer, setTimer] = useState(5);

    //////PaymentPopup state and Functions/////
    const [successOpen, setSuccessOpen] = React.useState(false);
    const [customPay, setCustomPay] = React.useState(false);
    const [failureOpen, setFailureOpen] = React.useState(false);
    const [reson, setReson] = React.useState("");
    const [amount, setAmount] = React.useState<any>("");
    const [transaction, setTransaction] = useState<IPaymentList>();
    const [customloading, setCustomLoading] = React.useState(false);
    //const [fullWidth, setFullWidth] = React.useState(true);

    const [readonly, setReadOnly] = useState(false);
    const handleSuccessClose = () => {
        setTimer(5);
        navigate("/payments");
        setSuccessOpen(false);
    };

    const handleFailureClose = () => {
        setTimer(5);
        navigate("/payments");
        setFailureOpen(false);
    };
    const handleCustomPayOpen = () => {
        setCustomPay(true);
    };

    const handleCustomPayClose = () => {
        setCustomPay(false);
        setReson("");
        setAmount("");
        setReadOnly(false);
    };

    const handleAmountChange = (newValue: any) => {
        setAmount(newValue);
    };
    const handleResonChange = (newValue: string) => {
        setReson(newValue);
    };
    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            // const formattedAmount = amount.toString() + ".00";
            // formData.append("amount",amount.toString() + ".00");

            const regex = /\.(\d{2})$/;
            //e.log(amount);

            if (regex.test(amount)) {
                formData.append("amount", amount);
            } else {
                formData.append("amount", amount.toString() + ".00");
            }
            formData.append("description", reson);
            setCustomLoading(true);

            if (data?._id) {
                const updateResponse = await CustomPay(data?._id, formData);
                paymentResponse(updateResponse);
                if (updateResponse.status === 200) {
                    // setCustomLoading(false);
                    // setCustomPay(false);
                } else {
                    console.error("Update failed:", updateResponse);
                }
            } else {
                // Handle the case where _id is null or undefined
                console.error("Selected community ID is null or undefined");
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setCustomLoading(false);
            setAmount("");
            setReson("");
            setCustomPay(false);
        }
    };


    return (
        <>
            <Helmet>
                <title>{metaConfig.title.aboutCommunity}</title>
                <meta name="description" content={metaConfig.description} />
                <meta name="format-detection" content="telephone=no" />
                <meta name="author" content={metaConfig.description} />
                <meta name="keywords" content={metaConfig.description} />
            </Helmet>

            {/* Header  Start*/}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CssBaseline />
                <AppBar component="nav" sx={{ backgroundColor: 'white', boxShadow: 'none' }}>
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <Box component={"img"}
                            src={data?.logo}
                            sx={{
                                backgroundColor: 'white',
                                padding: { xs: 1, md: 2 },
                                width: { xs: "60px", md: '75px' },
                                height: { xs: "60px", md: '75px' },
                                position: "relative",
                                zIndex: 1,
                                borderRadius: '10px',
                            }}
                        />
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                flexGrow: 1, display: { xs: 'none', sm: 'block' },
                                background: "linear-gradient(90deg, #50A1CA 0%, #3B9B7F 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                                fontWeight: "bold",
                                fontFamily: 'Montserrat',
                                fontSize: { xs: '12px', md: '20px' },
                            }}
                        >
                            {data?.name}
                        </Typography>

                        <Box sx={{ display: { xs: 'block', sm: 'block' }, }}>
                            {/* <Stack sx={{ display: { xs: 'none', sm: 'inline-block', marginRight: 15, } }}>
                                <IconButton component="a"
                                    href={`tel:${data?.phoneNumber}`} aria-label="delete"
                                    size="medium" sx={{
                                        background: "linear-gradient(180deg, rgba(218, 2, 66, 0.1) 0%, rgba(59, 155, 127, 0.1) 32.8%, rgba(80, 161, 202, 0.1) 71.8%, rgba(42, 83, 162, 0.1) 96.8%)",
                                    }}>
                                    <CallIcon sx={{ color: "#000000", }} />
                                </IconButton>
                            </Stack> */}
                            {!isAlreadyJoined ? (
                                <>
                                    <Button
                                        sx={{
                                            color: '#ffffff',
                                            backgroundColor: '#2952A2',
                                            fontFamily: 'Montserrat',
                                            borderRadius: '30px',
                                            marginRight: 2,
                                            fontSize: { xs: '10px', md: '14px' },
                                            px: 3,
                                            "&:hover": {
                                                backgroundColor: '#2952A2',
                                            }
                                        }}
                                        onClick={handleClickOpen}
                                    >
                                        Join
                                    </Button>
                                    <Button
                                        sx={{
                                            color: '#ffffff',
                                            background: "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                                            backgroundColor: "#50A1CA",
                                            fontFamily: 'Montserrat',
                                            borderRadius: '30px',
                                            marginRight: 2,
                                            fontSize: { xs: '10px', md: '14px' },
                                            px: 3,
                                        }}
                                        onClick={handleClickOpen}
                                    >
                                        Pay
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        sx={{
                                            color: '#ffffff',
                                            backgroundColor: '#2952A2',
                                            fontFamily: 'Montserrat',
                                            borderRadius: '30px',
                                            marginRight: 2,
                                            fontSize: { xs: '10px', md: '14px' },
                                            px: 3,
                                            "&:hover": {
                                                backgroundColor: '#2952A2',
                                            }
                                        }}
                                        onClick={handleButtonClick}
                                    >
                                        Joined
                                    </Button>
                                    <Button
                                        sx={{
                                            color: '#ffffff',
                                            background: "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                                            backgroundColor: "#50A1CA",
                                            fontFamily: 'Montserrat',
                                            borderRadius: '30px',
                                            marginRight: 2,
                                            fontSize: { xs: '10px', md: '14px' },
                                            px: 1,
                                        }}
                                        onClick={handleCustomPayOpen}
                                    >
                                        Pay
                                    </Button>
                                </>

                            )}

                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleDrawerToggle}
                                sx={{ display: { sm: 'none', }, }}
                            >
                                <MenuIcon />
                            </IconButton>

                        </Box>

                    </Toolbar>
                </AppBar>
                <nav>
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </nav>
            </Box >
            {/* Header  End*/}

            < Box sx={{ flexGrow: 1, mt: 10, display: 'flex', alignItems: 'center', }
            }>
                <Grid container spacing={2}>
                    <Grid item xs={1} sm={1} md={2} lg={2}>
                    </Grid>
                    <Grid item xs={10} sm={10} md={8} lg={8}>
                        <div style={{ position: "relative" }}>
                            <img
                                src={data?.banner}
                                alt="Cover"
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    maxHeight: 270,
                                    objectFit: "cover",
                                    position: "relative",
                                    marginBottom: "-10px",
                                    borderRadius: '10px'
                                }}
                            />
                        </div>

                        {/* Bottom Section - Description */}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                position: "relative",

                            }}
                        >
                            {/* Add display picture (DP) */}
                            <Box sx={{ position: "relative", }}>
                                <Box component={"img"}
                                    src={data?.logo}
                                    sx={{
                                        backgroundColor: 'white',
                                        padding: 2,
                                        width: "100px",
                                        height: "100px",
                                        position: "relative",
                                        top: "-50px",
                                        zIndex: 1,
                                        borderRadius: '10px',
                                        boxShadow: 1
                                    }}
                                />

                            </Box>
                        </div>
                        <Stack sx={{ mt: -3 }}>
                            <Typography variant="h5" sx={{ color: '#2952A2', fontWeight: 'bold', fontSize: { xs: '14px', md: '25px' }, textAlign: 'center', fontFamily: 'Montserrat' }}>
                                {data?.name}
                            </Typography>
                            <Typography variant="h5" sx={{ color: '#343434', fontWeight: 500, fontSize: { xs: '12px', md: '14px' }, textAlign: 'center', fontFamily: 'Montserrat' }}>
                                By {data?.createdBy?.firstName}
                            </Typography>
                        </Stack>


                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: { xs: 0, md: 2 },
                                p: { xs: 1, md: 2, },
                                '& > :not(style)': {
                                    my: 1,
                                    width: "100%",
                                },
                            }}
                        >
                            <Paper
                                elevation={1}
                                sx={{
                                    p: { xs: 1, md: 2, },
                                    borderRadius: '10px',
                                    width: { xs: '100%', md: '80%' }, // Adjust width as needed
                                    background:
                                        "linear-gradient(90deg, rgba(223, 34, 90, 0.1) 9.3%, rgba(59, 155, 127, 0.1) 34.8%, rgba(80, 161, 202, 0.1) 64.3%, rgba(42, 83, 162, 0.1) 95.3%)",
                                }}
                            >
                                <Grid container alignItems="center" justifyContent="center" >
                                    {gridItems.map((item, index) => (
                                        <Grid
                                            item
                                            xs={3}
                                            textAlign="center"
                                            key={index}
                                            sx={index > 0 ? { borderLeft: '1px solid #BFBFBF' } : {}}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#000000',
                                                    fontWeight: 400,
                                                    fontSize: { xs: '10px', md: '14px' },
                                                    textAlign: 'center',
                                                    fontFamily: 'Montserrat',
                                                }}
                                            >
                                                {item.label}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: '#000000',
                                                    fontWeight: 700,
                                                    fontSize: { xs: '12px', md: '22px' },
                                                    textAlign: 'center',
                                                    fontFamily: 'Montserrat',
                                                }}
                                            >
                                                {item.value}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Paper>
                        </Box>



                        <Stack direction="row" spacing={2} margin={1} id="home">
                            <>
                                {!isAlreadyJoined ? (
                                    <>
                                        <Button
                                            sx={{
                                                color: '#ffffff',
                                                backgroundColor: '#2952A2',
                                                fontFamily: 'Montserrat',
                                                borderRadius: '30px',
                                                fontSize: { xs: '10px', md: '14px' },
                                                width: '50%',
                                                p: { xs: 1, md: 1.5, },
                                                "&:hover": {
                                                    backgroundColor: '#2952A2',
                                                },
                                            }}
                                            onClick={handleClickOpen}
                                        >
                                            Join Community
                                        </Button>

                                        <Button
                                            sx={{
                                                color: '#ffffff',
                                                background: "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                                                backgroundColor: "#50A1CA",
                                                fontFamily: 'Montserrat',
                                                borderRadius: '30px',
                                                width: '50%',
                                                fontSize: { xs: '10px', md: '14px' },
                                                p: 1.5,
                                            }}
                                            onClick={handleClickOpen}
                                        >
                                            Pay now
                                        </Button>
                                    </>

                                ) : (
                                    <>
                                        <Button
                                            sx={{
                                                color: '#ffffff',
                                                backgroundColor: '#2952A2',
                                                fontFamily: 'Montserrat',
                                                borderRadius: '30px',
                                                fontSize: { xs: '10px', md: '14px' },
                                                width: '50%',
                                                p: 1.5,
                                                "&:hover": {
                                                    backgroundColor: '#2952A2',
                                                },
                                            }}
                                            onClick={handleButtonClick}
                                        >
                                            Joined
                                        </Button>

                                        <Button
                                            sx={{
                                                color: '#ffffff',
                                                background: "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                                                backgroundColor: "#50A1CA",
                                                fontFamily: 'Montserrat',
                                                borderRadius: '30px',
                                                width: '50%',
                                                fontSize: { xs: '10px', md: '14px' },
                                                p: 1.5,
                                            }}
                                            onClick={handleCustomPayOpen}
                                        >
                                            Pay now
                                        </Button>
                                    </>

                                )
                                }
                            </>

                        </Stack>


                        <Box

                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'sticky',
                                top: 67,
                                zIndex: 1000,
                                mt: 3,
                                p: 1,
                                '& > :not(style)': {
                                    // m: 1,
                                    width: "100%",
                                },
                            }}
                        >
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 1,
                                    borderRadius: '30px',
                                    width: '100%',
                                    backgroundColor: "#50A1CA",
                                }}
                            >
                                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                                    {buttonNames.map((button, index) => (
                                        <Grid item xs={1.5} textAlign="center" key={index}>
                                            <Button
                                                sx={{
                                                    width: '100%',
                                                    color: '#ffffff',
                                                    fontSize: '14px',
                                                    fontFamily: 'Montserrat',
                                                    borderRadius: '30px',
                                                    "&:hover": {
                                                        backgroundColor: 'white',
                                                        fontWeight: 'bold',
                                                        color: '#000000'
                                                    },
                                                }}
                                                href={button?.url}
                                            >

                                                {button?.name}
                                            </Button>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Paper>

                        </Box>


                        <Box >
                            <Stack m={1} >
                                <Typography sx={{ fontSize: { xs: '14px', md: '25px' }, fontWeight: 700, color: '#000000', fontFamily: 'Montserrat', mt: 2 }}>
                                    Community Description
                                </Typography>
                                <Typography
                                    variant="caption"
                                    paragraph={true}
                                    className="description"
                                    sx={{ fontSize: { xs: '12px', md: '14px' }, fontWeight: 500, color: '#000000', fontFamily: 'Montserrat', mt: 2, overflow: 'hidden', textOverflow: 'ellipsis', }}
                                >
                                    {showFullDescription ? (data?.description || '') : (data?.description ? data?.description.slice(0, 500) + ' ' : '')}
                                    &nbsp; &nbsp;
                                    {data?.description && data?.description.length > 500 && (
                                        <Button
                                            size="small"
                                            onClick={handleToggleDescription}
                                            sx={{
                                                color: "#2952A2",
                                                fontWeight: 500,
                                                fontSize: { xs: '10px', md: '14px' },
                                                textTransform: 'capitalize',
                                                textDecoration: 'underline',
                                                cursor: "pointer",
                                                pt: 0,
                                                fontFamily: 'Montserrat'
                                            }}
                                        >
                                            {showFullDescription ? 'Show Less' : 'Show More'}
                                        </Button>
                                    )}
                                </Typography>
                            </Stack>
                        </Box>

                        <Box sx={{ alignContent: 'center', justifyItems: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex' } }} >
                            <Grid container spacing={10} sx={{ p: 5 }}>
                                {visionsections.map((section, index) => (
                                    <Grid item xs={6} key={index} >
                                        <Paper sx={{ p: 5, borderRadius: '30px', height: '300px', position: 'relative' }} >
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#000000', fontFamily: 'Montserrat', textAlign: 'center' }}>
                                                    OUR
                                                </Typography>
                                                <Typography sx={{ fontSize: '25px', fontWeight: 700, color: '#000000', fontFamily: 'Montserrat', textAlign: 'center' }}>
                                                    {section.title}
                                                </Typography>
                                                <Typography sx={{ fontSize: { xs: '12px', md: '14px' }, fontWeight: 500, color: '#000000', fontFamily: 'Montserrat', textAlign: 'center' }}>
                                                    {/* {section.content} */}
                                                    {showFullDescriptionMission ? (section.content || '') : (section.content ? section.content.slice(0, 230) + ' ' : '')}
                                                    &nbsp; &nbsp;
                                                    {data?.description && data?.description.length > 230 && (
                                                        <Button
                                                            size="small"
                                                            onClick={handleToggleMission}
                                                            sx={{
                                                                color: "#2952A2",
                                                                fontWeight: 500,
                                                                fontSize: { xs: '12px', md: '14px' },
                                                                textTransform: 'capitalize',
                                                                textDecoration: 'underline',
                                                                cursor: "pointer",
                                                                pt: 0,
                                                                fontFamily: 'Montserrat'
                                                            }}
                                                        >
                                                            {showFullDescriptionMission ? 'Read Less' : 'Read More'}
                                                        </Button>
                                                    )}
                                                </Typography>
                                            </div>
                                        </Paper>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                mt: 2,
                                                position: 'relative',
                                            }}
                                        >
                                            <Box
                                                id="gallery"
                                                component={"img"}
                                                src={section.img}
                                                sx={{
                                                    backgroundColor: 'white',
                                                    padding: 2,
                                                    width: "100px",
                                                    height: "100px",
                                                    mt: -8,
                                                    borderRadius: '100px',
                                                    boxShadow: 1,
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                        <Box
                            sx={{
                                alignContent: 'center',
                                justifyItems: 'center',
                                alignItems: 'center',
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <Carousel
                                responsive={responsive}
                                swipeable={true}
                                draggable={true}
                                showDots={false}
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={3000}
                                keyBoardControl={true}
                                customTransition="all .5"
                                transitionDuration={500}
                                containerClass="carousel-container"
                                // removeArrowOnDeviceType={["tablet", "mobile"]}
                                // dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                            >
                                {visionsections?.map((section, index) => (
                                    <div key={index}>
                                        <Paper
                                            sx={{
                                                p: 5,
                                                borderRadius: '30px',
                                                height: '250px',
                                                position: 'relative',
                                            }}
                                        >
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: { xs: '10px', md: '14px' },
                                                        fontWeight: 500,
                                                        color: '#000000',
                                                        fontFamily: 'Montserrat',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    OUR
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: { xs: '14px', md: '25px' },
                                                        fontWeight: 700,
                                                        color: '#000000',
                                                        fontFamily: 'Montserrat',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {section.title}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: { xs: '12px', md: '14px' },
                                                        fontWeight: 500,
                                                        color: '#000000',
                                                        fontFamily: 'Montserrat',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {section.content}
                                                </Typography>
                                            </div>
                                        </Paper>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                mt: 2,
                                                position: 'relative',
                                            }}
                                        >
                                            <Box
                                                id="gallery"
                                                component={"img"}
                                                src={section.img}
                                                sx={{
                                                    backgroundColor: 'white',
                                                    padding: 2,
                                                    width: { xs: "60px", md: "100px" },
                                                    height: { xs: "60px", md: "100px" },
                                                    mt: -6,
                                                    borderRadius: '100px',
                                                    boxShadow: 1,
                                                }}
                                            />
                                        </Box>
                                    </div>
                                ))}
                            </Carousel>
                        </Box>
                        <Box id="services" >
                            <Typography sx={{ fontSize: { xs: '14px', md: '25px' }, fontWeight: 700, color: '#000000', fontFamily: 'Montserrat', mb: 3, mt: 2 }}>
                                Gallery
                            </Typography>
                            <AboutGallery
                                images={images}
                                imageHeight={100}
                                imageWidth={100}
                            ></AboutGallery>
                        </Box>


                        <Box >
                            <Typography sx={{ fontSize: { xs: '14px', md: '25px' }, fontWeight: 700, color: '#000000', fontFamily: 'Montserrat', mb: 5, mt: 5 }}>
                                Services
                            </Typography>
                            {data?.services && data?.services.length > 0 ? (
                                <NewAboutServices serviceData={data?.services || []} />
                            ) : (
                                <>
                                    <NoData image={NoDataImage} message="No Services Available" />
                                </>
                            )}
                        </Box>

                        <Box id="plans">
                            <Typography sx={{ fontSize: { xs: '14px', md: '25px' }, fontWeight: 700, color: '#000000', fontFamily: 'Montserrat', mb: 5, mt: 3 }}>
                                Plans
                            </Typography>
                            {data?.plans && data.plans.length > 0 ? (
                            <>
                            <NewAboutPlans plansData={data?.plans || []}  />
                            </>
                           ) : (
                             <>
                             <NoData image={NoDataImage} message="No Plans Available" />
                             </>
                             )} 
                        </Box>

                        <Box id="courses">
                            <Typography sx={{ fontSize: { xs: '14px', md: '25px' }, fontWeight: 700, color: '#000000', fontFamily: 'Montserrat', mb: 5, mt: 1 }}>
                                Courses
                            </Typography>
                            {data?.course && data?.course.length > 0 ? (
                            <NewAboutCourses coursesData={data?.course || []} />
                            ) : (
                                <>
                                    <NoData image={NoDataImage} message="No Courses Available" />
                                </>
                            )} 
                        </Box>

                        <Box id="team">
                            <Typography sx={{ fontSize: { xs: '14px', md: '25px' }, fontWeight: 700, color: '#000000', fontFamily: 'Montserrat', mb: 5, mt: 3 }}>
                                Our Team
                            </Typography>
                            {data?.teams && data.teams.length > 0 ? (
                                <NewAboutTeam teamData={data?.teams || []} />
                            ) : (
                                <>
                                    <NoData image={NoDataImage} message="No Team Members Available" />
                                </>
                            )}
                        </Box>

                        <Box >
                            <Stack m={1} >
                                <Typography sx={{ fontSize: { xs: '14px', md: '25px' }, fontWeight: 700, color: '#000000', fontFamily: 'Montserrat', mb: 3, mt: 3 }}>
                                    Contact Me
                                </Typography>
                                <Typography sx={{ fontSize: { xs: '12px', md: '14px' }, fontWeight: 500, color: '#000000', fontFamily: 'Montserrat', }}>
                                    {showFullDescriptionVission ? (data?.directorMessage || '') : (data?.directorMessage ? data?.directorMessage.slice(0, 400) + ' ' : '')}
                                    &nbsp; &nbsp;
                                    {data?.description && data?.description.length > 400 && (
                                        <Button
                                            size="small"
                                            onClick={handleToggleVission}
                                            sx={{
                                                color: "#2952A2",
                                                fontWeight: 500,
                                                fontSize: { xs: '12px', md: '14px' },
                                                textTransform: 'capitalize',
                                                textDecoration: 'underline',
                                                cursor: "pointer",
                                                pt: 0,
                                                fontFamily: 'Montserrat'
                                            }}
                                        >
                                            {showFullDescriptionVission ? 'Read Less' : 'Read More'}
                                        </Button>
                                    )}
                                </Typography>
                            </Stack>
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                alignContent="center"
                                justifyContent="space-between"
                                pt={2}
                            >
                                {contactDetails.map((detail, index) => (
                                    <Stack key={index}>
                                        <Typography
                                            sx={{
                                                color: "#000000",
                                                fontSize: { xs: '12px', md: '16px' },
                                                fontWeight: detail.fontWeight,
                                                fontFamily: 'Montserrat',
                                                mb: 1
                                            }}
                                        >
                                            {detail.label}{" "}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: "#343434",
                                                fontSize: { xs: '12px', md: '14px' },
                                                fontWeight: 500,
                                                fontFamily: 'Montserrat',
                                            }}
                                        >
                                            {detail.value}
                                        </Typography>
                                    </Stack>
                                ))}

                                <Stack>
                                    <Typography
                                        sx={{
                                            color: "#000000",
                                            fontSize: { xs: '12px', md: '16px' },
                                            fontWeight: 600,
                                            fontFamily: 'Montserrat',
                                            textAlign: { xs: 'left', md: 'right' },
                                            mb: { xs: 0, md: 1 }
                                        }}
                                    >
                                        Follow us{" "}
                                    </Typography>
                                    <Stack direction="row">
                                        {socialMediaIcons.map((icon, index) => (
                                            <Tooltip key={index} title={icon.title}>
                                                <IconButton onClick={icon.onClick}>
                                                    {React.createElement(icon.icon, {
                                                        sx: { color: "#464646", fontSize: { xs: '20px', md: '25px' } }
                                                    })}
                                                </IconButton>
                                            </Tooltip>
                                        ))}
                                    </Stack>
                                </Stack>
                            </Stack>

                        </Box>
                        <Box id="services" sx={{ mt: 5 }}>
                            <Typography sx={{ fontSize: { xs: '14px', md: '25px' }, fontWeight: 700, color: '#000000', fontFamily: 'Montserrat', mb: 5, mt: 2 }}>
                                Frequently Asked Questions
                            </Typography>
                            {data?.faq && data.faq.length > 0 ? (
                                <Stack
                                    sx={{
                                        backgroundColor: '#F2F2F2',
                                        borderRadius: '10px',
                                        p: 5,
                                        mt: 2,
                                        display: 'block',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        {data?.faq?.map((f: any, index: Key | null | undefined) => (
                                            <Grid item xs={12} sm={6} key={index}>
                                                <Accordion
                                                    elevation={0}
                                                    disableGutters
                                                    expanded={expanded === `panel${index}`}
                                                    onChange={handleAccordionChange(`panel${index}`)}
                                                    sx={{
                                                        borderRadius: '20px',
                                                        padding: '5px',
                                                        display: 'flex',
                                                        width: '100%',
                                                        marginBottom: '15px',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={expanded === `panel${index}` ? <IndeterminateCheckBoxRoundedIcon sx={{ color: '#2A53A2' }} /> : <AddBoxRoundedIcon sx={{ color: '#2A53A2' }} />}
                                                        sx={{ px: '20px' }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 600,
                                                                fontSize: { xs: '12px', md: '14px' },
                                                                color: '#000000',
                                                                fontFamily: 'Montserrat',
                                                            }}
                                                        >
                                                            {f?.question}
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails sx={{ px: '20px' }}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: { xs: '12px', md: '14px' },
                                                                color: '#6F6C90',
                                                                fontFamily: 'Montserrat',
                                                            }}
                                                        >
                                                            {f?.answer}
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Stack>
                            ) : (
                                <NoData image={NoDataImage} message="No FAQ'S Available" />
                            )}


                        </Box>


                    </Grid >
                    <Grid item xs={1} sm={1} md={2} lg={2}>

                    </Grid>
                </Grid >


            </Box >
            {/* Footer */}

            < Box sx={{ backgroundColor: '#F2F2F2', pt: 10, pl: 10, pr: 10, pb: 2, mt: 5 }}>
                <Stack
                    direction={"row"}
                    spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    alignContent={"center"}
                    justifyContent={"center"}
                    pt={1}
                >
                    <Stack>
                        <Box component={"img"}
                            src={data?.logo}
                            sx={{
                                backgroundColor: 'white',
                                padding: 2,
                                width: "100px",
                                height: "100px",
                                position: "relative",
                                top: "-10px",
                                zIndex: 1,
                                borderRadius: '30px',
                                boxShadow: 1
                            }}
                        />
                    </Stack>
                </Stack>
                <Stack sx={{ mt: 2, mb: 4 }}>
                    <Typography variant="h5" sx={{ color: '#2952A2', fontWeight: 'bold', fontSize: { xs: '14px', md: '25px' }, textAlign: 'center', fontFamily: 'Montserrat' }}>
                        {data?.name}
                    </Typography>
                    <Typography variant="h5" sx={{ color: '#343434', fontWeight: 500, fontSize: { xs: '10px', md: '14px' }, textAlign: 'center', fontFamily: 'Montserrat' }}>
                        By {data?.createdBy?.firstName}
                    </Typography>
                </Stack>
                <Stack
                    direction={"row"}
                    spacing={{ xs: 1.5, sm: 1.5, md: 5, lg: 5 }}
                    alignContent={"center"}
                    justifyContent={"center"}
                    pt={1}
                >
                    {buttonNames.map((button, index) => (
                        <Link href={button?.url} key={index} sx={{ textDecoration: 'none' }}>
                            <Typography sx={{ color: "#000000E5", fontSize: { xs: '10px', md: '14px' }, textDecoration: 'none', fontWeight: 500, fontFamily: 'Montserrat' }}>
                                {button?.name}
                            </Typography>
                        </Link>
                    ))}
                </Stack>

                <Stack
                    direction={"row"}
                    spacing={{ xs: 2, sm: 2, md: 5, lg: 5 }}
                    alignContent={"center"}
                    justifyContent={"space-between"}
                    pt={1}
                    mt={3}
                >
                    <Grid container>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                            <Typography sx={{ color: "#000000", fontSize: { xs: '14px', md: '16px' }, textDecoration: 'none', fontWeight: 700, fontFamily: 'Montserrat', textAlign: 'center' }}>
                                Built With
                            </Typography>
                            <Box component={"img"}
                                src={require("../../assets/images/communnlanding.png")}
                                width={"100%"}
                                sx={{
                                    py: 1,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2}>

                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2}>

                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2}>

                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2}>

                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2}>
                            <Typography sx={{ color: "#000000", fontSize: { xs: '14px', md: '16px' }, textDecoration: 'none', fontWeight: 700, fontFamily: 'Montserrat', textAlign: 'center' }}>
                                Follow us
                            </Typography>
                            <Stack direction={"row"} py={1} sx={{ justifyContent: 'center' }}>
                                {socialCommunnMediaIcons.map((icon, index) => (
                                    <Tooltip key={index} title={icon.title}>
                                        <IconButton onClick={icon.onClick}>
                                            {React.createElement(icon.icon, {
                                                sx: { color: "#464646", fontSize: { xs: '20px', md: '25px' } }
                                            })}
                                        </IconButton>
                                    </Tooltip>
                                ))}
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
                <Divider ></Divider>
                <Stack >
                    <Typography sx={{ color: "#000000", fontSize: { xs: '10px', md: '14px' }, textDecoration: 'none', fontFamily: 'Montserrat', textAlign: 'center', pt: 2 }}>
                        © 2024 All Rights Reserved By {data?.name}
                    </Typography>
                </Stack>
            </Box >


            <Stack sx={{ alignItems: "center" }}>
                <Stack direction={"row"} spacing={5} >
                    {/* {renderActionButton()} */}
                    {isAuthenticated && (
                        <SendRequestCommunityPreview
                            isOpen={isModalOpen}
                            handleClose={handleCloseModal}
                            logo={data?.logo ?? ""}
                            id={data?._id ?? ""}
                            title={data?.name ?? ""}
                            city={data?.city}
                            members={data?.members?.length || []}
                            message={data?.directorMessage ?? ""}
                            type={data?.type ?? ""}
                            description={data?.description ?? ""}
                            handleCopyLink={handleCopyLink}
                            copied={copied}
                            whatsappShareUrl={whatsappShareUrl}
                            facebookShareUrl={facebookShareUrl}
                            linkedinShareUrl={linkedinShareUrl}
                            twitterShareUrl={twitterShareUrl}
                            coverImageSrc={data?.banner ?? ""}
                            handleButtonClick={handleButtonClick}
                            collectSubscription={data?.collectSubscription}
                            handleClickPaid={handleClickPaid}
                        />
                    )}
                    <React.Fragment>
                        <BootstrapDialog
                            onClose={handleClose}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                            fullWidth
                        >
                            <Box
                                component={"img"}
                                src={require("./../../assets/images/LoginMbg.png")}
                                sx={{
                                    height: "100px",
                                    width: "100%",
                                    objectFit: "fill",
                                    display: { xs: "block", md: "block" },
                                }}
                                alt=""
                            ></Box>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon sx={{ color: "white" }} />
                            </IconButton>
                            <DialogContent>
                                {data && <LoginPopUp1 data={data} />}
                            </DialogContent>
                        </BootstrapDialog>
                    </React.Fragment>
                </Stack>
            </Stack>
            <CustomPayModel
                fullWidth={true}
                maxWidth={"xs"}
                customPay={customPay}
                handleCustomPayClose={handleCustomPayClose}
                buttonText={
                    customloading ? (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                    ) : (
                        "Submit"
                    )
                }
                handleCustomPayIcon={handleCustomPayClose}
                handleSubmitClick={() => {
                    //if (amount && amount > 99.0) {
                    handleSubmit();
                    //}
                }}
                paymentvalue={amount || ""}
                paymentOnChange={handleAmountChange}
                paymenReason={reson}
                paymentReasonOnChange={handleResonChange}
                readonly={readonly}
            />
            {/* Payment Success Pop up */}
            <PaymentSuccess
                txnid={transaction?.txnid || ""}
                open={successOpen}
                amount={transaction?.amount || ""}
                timer={timer}
                dialogText={""}
                onClose={handleSuccessClose}
                onClickNavigateButton={() => {
                    navigate("/payments");
                    setSuccessOpen(false);
                }}
            />

            {/* Payment Failed Pop up */}
            <PaymentFailed
                open={failureOpen}
                onClose={handleFailureClose}
                amount={transaction?.amount || ""}
                txnid={transaction?.txnid || ""}
                onClickNavigateButton={() => {
                    navigate("/payments");
                    setFailureOpen(false);
                }}
                dialogText={""}
                timer={timer}
                OnClickRetryButtonFunction={() => {
                    setFailureOpen(false);
                    setCustomPay(true);
                    setAmount(transaction?.amount);
                    setReson(transaction?.udf4 || "");
                }}
            />
            <a
                href={`tel:${data?.phoneNumber}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <WhatsAppButton>
                    <CallIcon />
                </WhatsAppButton>
            </a>


        </>
    );
};

export default NewAboutShare;
