import { Box, Stack } from "@mui/material";
// import CircularProgress from "@mui/material/CircularProgress";

export const Loader = () => {
  return (
    <Stack sx={{
      color: 'grey.500', display: "flex",

      
      justifyContent: "center",
      alignItems: "center",
      // height: "30vh",
      // backgroundColor:'white',
    }} spacing={2} direction="row" >
      {/* <CircularProgress color="inherit" /> */}
      <Box
        component={"img"}
        src={require("../../assets/images/loader.gif")}
        alt=""
        sx={{ width: "50%" }}
      />
    </Stack>
  );
};

export default Loader;
