import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Fab,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/Auth.context";
import { getOtp, verifyOtp } from "../../../services/otp.service";
import { StyledLogin } from "./Login.styles";
import {
  EMPTY_STRING,
  INACTIVE_USER_MSG,
  INVALID_OTP,
  OTP_LIMIT_REACHED,
  OTP_RESENT,
  OTP_SEND_FAILED,
  OTP_SENT,
  PASSWORD_INVALID,
  USER_NOT_FOUNT_MSG,
} from "../../../utils/UdfMessages";

import { BASE_URL } from "../../../configurations/url.config";
// import Dialog from "@mui/material/Dialog";
import { Helmet } from "react-helmet";
import {
  validateEmailAddress,
  validateMobileNumber,
} from "../../../utils/helper";
import { auth, google, apple } from "../../../configurations/fireBase";
import { signInWithPopup } from "@firebase/auth";
import { metaConfig } from "../../../utils/constants";
import { login as loginGoogle } from "webtonative/SocialLogin/google";
import { login as loginApple } from "webtonative/SocialLogin/apple";
import GoogleIcon from "./../../../assets/images/icons8-google.svg";
import AppleIcon from "./../../../assets/images/icons8-apple.svg";

export interface SocialProfile {
  // Define the structure of the profile data
  id: string;
  name: string;
  email: string;
  given_name: string;
  family_name: string;
  hd: string;
  locale: string;
  picture: string;
  verified_email: string;
  // Add more properties as needed
}

export const Login = () => {
  const authContex = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingSocialGoogle, setLoadingSocialGoogle] = useState(false);
  const [loadingSocialMobileGoogle, setLoadingSocialMobileGoogle] =
    useState(false);
  const [loadingSocialFacebook, setLoadingSocialFacebook] = useState(false);
  const [showPassword] = useState(false);
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [enableGetOTP, setEnableGetOTP] = useState(false);
  const [otpReceived, setOtpReceived] = useState(false);
  const [otpSuccessMessage, setOtpSuccessMessage] = useState("");
  // const [enteredOpt, setEnteredOtp] = useState("");
  const [resendCount, setResendCount] = useState(0);
  const [remainingTime, setRemainingTime] = useState(30000);
  const [timerRunning, setTimerRunning] = useState(false);
  const [isBlocked] = useState(false);
  const [actionButton, setActionButton] = useState("Login");
  const [information] = React.useState("");
  // const [otp6, setOtp6] = useState("");
  // const [generatedOTP, setGeneratedOTP] = useState<Number>(0);
  const NUMBER_3 = 3;

  // const location = useLocation();

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Email/Mobile Number is required")
      .test(
        "username",
        "Invalid Email or Mobile Number",
        (value) => validateEmailAddress(value) || validateMobileNumber(value)
      ),
    password: Yup.string()
      .required("Password/OTP is required")
      .max(40, "Password must not exceed 40 characters"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleNumberChange = (e: any) => {
    const value = e.target.value;
    if (/^\d+$/.test(value) && value.length === 10) {
      setPhoneNumber(value);
      setEnableGetOTP(true);
      setValidPhoneNumber(true);
      setResendCount(0);
      setTimerRunning(false);
      setRemainingTime(60);
      setOtpReceived(false);
      setOtpSuccessMessage("");
      setError("");
    } else {
      setEnableGetOTP(false);
      setValidPhoneNumber(false);
      setError(EMPTY_STRING);
      setActionButton("Login");
      // setGeneratedOTP(0);
      setOtpSuccessMessage(EMPTY_STRING);
    }
  };

  const generateOTP = async () => {
    if (resendCount < NUMBER_3) {
      const { data } = await getOtp(phoneNumber);
      // console.log(data);
      let number = phoneNumber.replace(/^.{6}/g, "******");
      setError("");
      if (data) {
        setOtpReceived(true);
        setActionButton("Verify OTP");
        setResendCount(resendCount + 1);
        setTimerRunning(true);
        // setGeneratedOTP(data?.opt);
        //console.log(generatedOTP);
        if (resendCount === 0) {
          setOtpSuccessMessage(OTP_SENT.replace("#PHONE_NUMBER", number));
        } else {
          setOtpSuccessMessage(OTP_RESENT.replace("#PHONE_NUMBER", number));
        }
      } else {
        setOtpSuccessMessage("");
        setError(OTP_SEND_FAILED.replace("#PHONE_NUMBER", number));
      }
    } else if (resendCount === 3) {
      setOtpSuccessMessage(OTP_LIMIT_REACHED);
      setTimerRunning(false);
    }
  };

  useEffect(() => {
    if (timerRunning && remainingTime > 0) {
      const interval = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 3000);

      return () => clearInterval(interval);
    } else {
      setTimerRunning(false);
      setRemainingTime(30);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerRunning, remainingTime]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const value = data.password;
    //console.log(generatedOTP);
    if (!isNaN(value) && value.toString().length === 6) {
      setLoading(true);
      // Call verify OTP to verify
      const verifyResponse = await verifyOtp(phoneNumber, value);
      if (verifyResponse.status === 200) {
        // call autoLogin
        const response = await authContex.autoLogin(phoneNumber, "");
        handleLoginResponse(response);
      } else {
        // OTP
        setOtpSuccessMessage(EMPTY_STRING);
        setError(INVALID_OTP);
        setLoading(false);
      }
      setLoading(false);
    } else {
      // Email flow: login
      const response: any = await authContex.login(data);
      handleLoginResponse(response);
      if (response?.response?.status === 401) {
        setError(PASSWORD_INVALID);
      } else if (response?.response?.status === 404) {
        setError("User not Found, check your Account Credentials");
      }
    }
  };

  // const searchParams = new URLSearchParams(location.search);
  // const communityId = searchParams.get('communityId');
  // console.log(communityId, "communityId");

  const handleLoginResponse = async (response: any) => {
    if (response?.status === 200) {
      const { user, joinedCommunities, notPaidCommunities } = response?.data;
      // console.log(notPaidCommunities, "notPaidCommunities")
      // console.log(joinedCommunities, "joinedCommunities")
      // console.log(user, "user")
      if (notPaidCommunities) {
        navigate("/joined-communities");
      } else if (joinedCommunities && joinedCommunities.length > 0) {
        navigate("/home", { state: { id: user?.community } });
      } else if (!joinedCommunities || joinedCommunities.length === 0) {
        navigate("/explore-communities", {
          state: { id: 2, data: user },
        });
      } else if (user?.community) {
        navigate("/dashboard", { state: { id: 3, data: user } });
      }
    } else if (response.status === 403) {
      setError(INACTIVE_USER_MSG);
      setOtpSuccessMessage(EMPTY_STRING);
    } else if (response?.status === 404) {
      setError(USER_NOT_FOUNT_MSG);
      navigate("/register", { state: { id: 1, phoneNumber: phoneNumber } });
    }
    setLoading(false);
  };

  const SocialLogin = async (login: any) => {
    const response: any = await signInWithPopup(auth, login);
    const socialProfile = response["_tokenResponse"];
    const user = response.user;
    const autoCreateData = {
      firstName: socialProfile.firstName,
      lastName: socialProfile.lastName,
      emailId: socialProfile.email,
      isEmailVerified: socialProfile.emailVerified,
      avatar: socialProfile.photoUrl,
      phoneNumber: user?.phoneNumber,
    };
    // console.log(autoCreateData);

    axios
      .post(`${BASE_URL}/auth/social-login`, autoCreateData)
      .then(async (response) => {
        const { user } = response?.data;
        // console.log("User Info:", user)
        const autologinResponse = await authContex.autoLogin("", user?.emailId);
        handleLoginResponse(autologinResponse);
      })
      .catch((error) => {
        console.error("Auto login error:", error);
      });
  };
  const handleGoogleLogin = async () => {
    setLoadingSocialGoogle(true);
    SocialLogin(google);
  };

  // const handleFacebookLogin = async () => {
  //   setLoadingSocialFacebook(true);
  //   SocialLogin(facebook);
  // }

  const handleAppleLogin = () => {
    setLoadingSocialFacebook(true);
    SocialLogin(apple);
  };

  // const handleMobileGoogleLogin = () => {
  //   loginGoogle({
  //     callback: async (value) => {
  //       // console.log(value, "WebToNative login response");
  //       if (value) {
  //         try {
  //           const googleResponse = await axios.get(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${value.idToken}`);
  //           const googleData = googleResponse.data;
  //           const autoCreateData = {
  //             firstName: googleData.given_name,
  //             lastName: googleData.family_name,
  //             emailId: googleData.email,
  //             isEmailVerified: googleData.email_verified,
  //             avatar: googleData.picture,
  //             phoneNumber: "",
  //           };

  //           const response = await axios.post(`${BASE_URL}/auth/social-login`, autoCreateData);
  //           const { user } = response.data;
  //           // console.log(user, "response");
  //           const autologinResponse = await authContex.autoLogin("", user?.emailId);
  //           handleLoginResponse(autologinResponse);
  //         } catch (error) {
  //           console.error("Error during Google login or fetching user info:", error);
  //         }
  //       }
  //     }
  //   });
  // };

  const handleMobileGoogleLogin = () => {
    loginGoogle({
      callback: async (value) => {
        // console.log(value, "WebToNative login response");
        alert(JSON.stringify(value));
        if (value) {
          try {
            const googleResponse = await axios.get(
              `https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${value.idToken}`
            );
            const googleData = googleResponse.data;
            const autoCreateData = {
              firstName: googleData.given_name,
              lastName: googleData.family_name,
              emailId: googleData.email,
              isEmailVerified: googleData.email_verified,
              avatar: googleData.picture,
              phoneNumber: "",
            };
            const response = await axios.post(
              `${BASE_URL}/auth/social-login`,
              autoCreateData
            );
            const { user } = response.data;
            // console.log(user, "response");
            const autoLoginResponse = authContex.autoLogin("", user?.emailId);
            handleLoginResponse(autoLoginResponse);
          } catch (error) {
            console.error(
              "Error during Google login or fetching user info:",
              error
            );
          }
        }
      },
    });
  };

  const handleMobileAppleLogin = () => {
    loginApple({
      callback: async (value) => {
        if (value && value.isSuccess) {
          const { firstName, lastName, emailId } = value;
          if (!firstName || !emailId) {
            console.error("First name and email are required for apple login.");
            return;
          }
          const autoCreateData = {
            firstName,
            lastName: lastName || "",
            emailId,
            isEmailVerified: true,
            avatar: "",
            phoneNumber: "",
            isPhoneVerified: true,
          };
          try {
            const response = await axios.post(
              `${BASE_URL}/auth/social-login`,
              autoCreateData
            );
            const { user } = response.data;
            const autoLoginResponse = authContex.autoLogin("", user?.emailId);
            handleLoginResponse(autoLoginResponse);
          } catch (error) {
            console.error(
              "Error during Apple login or processing user data:",
              error
            );
          }
        }
      },
    });
  };

  const isAppleDevice = /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent);
  const handleGoogleMobileLogin = () => {
    setLoadingSocialMobileGoogle(true);
    handleMobileGoogleLogin();
  };

  const handleAppleMobileLogin = () => {
    handleMobileAppleLogin();
  };

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.loginPage}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.description} />
        <meta name="keywords" content={metaConfig.description} />
      </Helmet>
      <StyledLogin>
        <Grid container>
          <Grid
            item
            md={7}
            sx={{
              background: "linear-gradient(to right bottom, #50a1ca,#469EA7)",
              display: { xs: "none", md: "block" },
            }}
          >
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ height: "100%", width: "100%", justifyContent: "center" }}
            >
              <Box
                component={"img"}
                src={require("./../../../assets/images/registerbckg.png")}
                alt=""
              />
              <Stack alignItems={"center"} sx={{ mt: 1 }}>
                <Typography
                  sx={{
                    fontSize: "28px",
                    fontWeight: "700",
                    color: "#ffffff",
                    fontFamily: "Inter",
                  }}
                >
                  Create or Join Communities
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#ffffff",
                    fontFamily: "Montserrat",
                    pt: 1,
                  }}
                >
                  Get ready to explore, create, and embrace the limitless
                  possibilities that await you here.{" "}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack
              sx={{
                height: "100vh",
                overflow: "auto",
                justifyContent: {
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "center",
                  lg: "center",
                },
              }}
              className="login-form-container"
              alignItems={"center"}
            >
              {information ? (
                <Typography
                  sx={{
                    color: "green",
                    fontWeight: "700",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {information}
                </Typography>
              ) : (
                ""
              )}
              <Box
                component={"img"}
                src={require("./../../../assets/images/RegisterMBg.png")}
                sx={{
                  height: "180px",
                  width: "100%",
                  display: { xs: "block", md: "none" },
                }}
                alt=""
              ></Box>
              <Box
                component={"form"}
                onSubmit={onSubmit}
                sx={{
                  width: "328px",
                  textAlign: "center",
                  mt: { xs: "5vh", sm: "5vh", md: "10vh", lg: "10vh" },
                }}
              >
                <Box
                  sx={{}}
                  component={"img"}
                  src={require("./../../../assets/images/Communn-new-logo.png")}
                  alt=""
                  width={"75%"}
                />

                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  New user? Enter mobile number please.
                </Typography>
                <Stack
                  spacing={2}
                  sx={{ pt: 2, backgroundColor: "white" }}
                  className="login-form"
                >
                  <TextField
                    sx={{ backgroundColor: "white" }}
                    size="small"
                    label="Enter Mobile No"
                    fullWidth
                    // placeholder="Email or Mobile "
                    {...register("username")}
                    error={errors.username ? true : false}
                    helperText={
                      errors.username ? (errors.username.message as string) : ""
                    }
                    onChange={handleNumberChange}
                    // InputProps={{ style: { borderRadius: "30px", backgroundColor: '#ffffff' } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            cursor: "pointer",
                            backgroundColor: "#ffffff",
                            alignContent: "center",
                            borderRadius: "100px",
                          }}
                        >
                          <Box
                            display="flex"
                            sx={{
                              display: validPhoneNumber ? "block" : "none",
                            }}
                          >
                            <Stack direction="row">
                              <Divider
                                orientation="vertical"
                                sx={{ m: 1, height: 24 }}
                              />

                              <Button
                                size="small"
                                sx={{
                                  backgroundColor: "#3b9b7f",
                                  textTransform: "capitalize",
                                  color: "white",
                                  borderRadius: "100px",
                                  height: "30px",
                                  mt: "5px",
                                  mr: "-15px",
                                  ":hover": {
                                    backgroundColor: "#3b9b7f",
                                    color: "#ffffff",
                                  },
                                  // display: validPhoneNumber ? "block" : "none",
                                }}
                                disabled={
                                  !enableGetOTP || isBlocked || timerRunning
                                }
                                onClick={generateOTP}
                              >
                                {timerRunning
                                  ? `Resend OTP in ${remainingTime}s`
                                  : resendCount === 0
                                  ? `Get OTP`
                                  : `Resend OTP`}
                              </Button>
                            </Stack>
                          </Box>
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: "100px",
                        backgroundColor: "#ffffff",
                      },
                    }}
                  />

                  <TextField
                    size="small"
                    label="Enter OTP"
                    defaultValue=""
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    // placeholder="Enter OTP"
                    {...register("password")}
                    error={errors.password ? true : false}
                    helperText={
                      errors.password ? (errors.password.message as string) : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            cursor: "pointer",
                            backgroundColor: "#E5E3EF",
                            borderRadius: "100px",
                          }}
                        >
                          <Stack
                            divider={
                              <Divider orientation="vertical" flexItem />
                            }
                          ></Stack>
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: "30px",
                        backgroundColor: "#ffffff",
                      },
                    }}
                  ></TextField>

                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{
                      boxShadow: "none",
                      borderRadius: "30px",
                      background:
                        "linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)",
                      textTransform: "capitalize",
                      ":hover": {
                        background:
                          "linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)",
                      },
                    }}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    ) : (
                      `${actionButton}`
                    )}
                  </Button>

                  {/* Popup Starts */}

                  {/* <React.Fragment>
                    <Button
                      size="large"
                      variant="contained"
                      sx={{
                        borderRadius: "30px",
                        background:
                          "linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)",
                        textTransform: "capitalize",
                      }}
                      onClick={handleClickOpen}
                    >
                      Login Popup
                    </Button>
                    <BootstrapDialog
                      onClose={handleClose}
                      aria-labelledby="customized-dialog-title"
                      open={open}
                      fullWidth
                    >
                      <Box
                        component={"img"}
                        src={require("./../../../assets/images/LoginMbg.png")}
                        sx={{
                          height: "100px",
                          width: "100%",
                          objectFit: "fill",
                          display: { xs: "block", md: "block" },
                        }}
                        alt=""
                      ></Box>
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon sx={{color:'white'}} />
                      </IconButton>
                      <DialogContent >
                        <LoginPopUp />

                      </DialogContent>
                    </BootstrapDialog>
                  </React.Fragment> */}

                  <Stack
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ color: "red" }}
                  >
                    {" "}
                    {error ? (
                      <Typography
                        sx={{
                          fontSize: { xs: "15px", md: "16px" },
                          textAlign: "center",
                        }}
                      >
                        {error}
                      </Typography>
                    ) : null}{" "}
                  </Stack>
                  {otpReceived && (
                    <Typography
                      sx={{
                        color: "green",
                        textAlign: "center",
                        fontSize: { xs: "15px", md: "16px" },
                      }}
                      className="success-message"
                    >
                      {otpSuccessMessage}
                    </Typography>
                  )}
                  <Divider sx={{ pt: 1 }}>
                    <Box component={"span"}>OR Continue With</Box>
                  </Divider>
                  <Box sx={{ pt: 1 }}>
                    {/* Laptop Screen Login */}
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent={"center"}
                      alignContent={"center"}
                      sx={{ display: { xs: "none", md: "block" } }}
                    >
                      <Fab
                        size="medium"
                        disabled={loadingSocialFacebook}
                        sx={{ backgroundColor: "white" }}
                      >
                        {loadingSocialGoogle ? (
                          <CircularProgress
                            size={24}
                            sx={{
                              background:
                                "linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)",
                              color: "white",
                              borderRadius: "100px",
                            }}
                          />
                        ) : (
                          <Box
                            onClick={() => handleGoogleLogin()}
                            component={"img"}
                            src={GoogleIcon}
                            sx={{ width: "30px", height: "30px" }}
                          />
                        )}
                      </Fab>

                      {/* <Fab size="medium" disabled={loadingSocialGoogle} sx={{ backgroundColor: "white" }}>
                        {loadingSocialFacebook ? (
                          <CircularProgress size={24} sx={{
                            background:
                              "linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)", color: 'white', borderRadius: '100px',
                          }} />
                        ) : (
                          <Box
                            onClick={() => handleFacebookLogin()}
                            component={"img"}
                            src={require("./../../../assets/images/facebook.png")}
                          />
                        )}
                      </Fab> */}
                      <Fab
                        size="medium"
                        disabled={loadingSocialGoogle}
                        sx={{ backgroundColor: "white" }}
                      >
                        {loadingSocialFacebook ? (
                          <CircularProgress
                            size={24}
                            sx={{
                              background:
                                "linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)",
                              color: "white",
                              borderRadius: "100px",
                            }}
                          />
                        ) : (
                          <Box
                            onClick={() => handleAppleLogin()}
                            component={"img"}
                            src={AppleIcon}
                            sx={{ width: "30px", height: "30px" }}
                          />
                        )}
                      </Fab>
                    </Stack>

                    {/* Mobile Screen Login */}

                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent={"center"}
                      alignContent={"center"}
                      sx={{ display: { xs: "block", md: "none" } }}
                    >
                      <Fab size="medium">
                        {loadingSocialMobileGoogle ? (
                          <CircularProgress
                            size={24}
                            sx={{
                              background:
                                "linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)",
                              color: "white",
                              borderRadius: "100px",
                            }}
                          />
                        ) : (
                          <Box
                            onClick={() => handleGoogleMobileLogin()}
                            component={"img"}
                            src={GoogleIcon}
                            sx={{ width: "30px", height: "30px" }}
                          />
                        )}
                      </Fab>
                      {isAppleDevice && (
                        // <Fab size="medium">
                        //   <Box
                        //     onClick={() => handleAppleMobileLogin()}
                        //     component={"img"}
                        //     src={AppleIcon}
                        //     sx={{ width: "30px", height: "30px" }}
                        //   />
                        // </Fab>
                        <Fab
                          size="medium"
                          disabled={loadingSocialGoogle}
                          sx={{ backgroundColor: "white" }}
                        >
                          {loadingSocialFacebook ? (
                            <CircularProgress
                              size={24}
                              sx={{
                                background:
                                  "linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)",
                                color: "white",
                                borderRadius: "100px",
                              }}
                            />
                          ) : (
                            <Box
                              onClick={() => handleAppleLogin()}
                              component={"img"}
                              src={AppleIcon}
                              sx={{ width: "30px", height: "30px" }}
                            />
                          )}
                        </Fab>
                      )}
                    </Stack>

                    <Box
                      sx={{
                        color: "#9A9A9A",
                        fontSize: "12px",
                        pt: 2,
                        textAlign: "center",
                        mt: 2,
                      }}
                    >
                      By clicking Get OTP, you agree to Onecommunn's <br></br>
                      <Link
                        href="https://onecommunn.com/terms-of-uses"
                        target="_blank"
                        style={{ color: "#9A9A9A" }}
                      >
                        User Agreement,
                      </Link>
                      <Link
                        href="https://onecommunn.com/privacy-policy"
                        target="_blank"
                        style={{ color: "#9A9A9A" }}
                      >
                        {" "}
                        Privacy Policy,
                      </Link>
                      and
                      <Link
                        href="https://onecommunn.com/privacy-policy"
                        target="_blank"
                        style={{ color: "#9A9A9A" }}
                      >
                        {" "}
                        Cookie Policy.
                      </Link>
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </StyledLogin>
      {/* {loading && <Loader />} */}
    </>
  );
};

export default Login;
