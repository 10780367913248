import { useContext, useState } from 'react';

import { useSnackbar } from 'notistack';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import { Invitations } from '../models/Invitation.model';
import {
  addInvitations,
  createInvitationByCommunityId,
  deleteInvitationById,
  deleteSelectedInvitation,
  getAllCommunityInvitations,
  updateInvitationById,
  updateSelectedInvitation,
  getCommunityInvitationsForUser,
  joinToInvitedCommunity,
} from '../services/Invitations.service';
export const useInvitations = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [invitations, setInvitations] = useState<Invitations[]>([]);

  const addInvites = async (usersToAdd: FormData, communityId: string) => {
    try {
      const response = await addInvitations(
        getAccessToken(),
        usersToAdd,
        communityId
      );
      if (response.status === 201) {
        setInvitations((prevInvitations) => [
          ...prevInvitations,
          response?.data,
        ]);
        enqueueSnackbar('invited New User successfully', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('invited new user is failed', { variant: 'error' });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('An error occurred while Adding User', {
        variant: 'error',
      });
    }
  };
  const getInviteList = async (id: string) => {
    setIsLoading(true);
    const response = await getAllCommunityInvitations(getAccessToken(), id);
    if (response.status === 200) {
      setInvitations(response.data.data);
      return response.data;
    } else {
      enqueueSnackbar('Fetching InviteList failed', { variant: 'error' });
    }
    setIsLoading(false);
  };

  const deleteInviteById = async (_id: string) => {
    try {
      const response = await deleteInvitationById(getAccessToken(), _id);
      if (response.status === 200) {
        setInvitations(response.data.data);
        enqueueSnackbar('Invite deleted successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Invite to delete post', { variant: 'error' });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('An error occurred while deleting the Invite', {
        variant: 'error',
      });
    }
  };

  const deleteSelectedInvitations = async (
    communityId: string,
    formData: FormData
  ) => {
    try {
      const response = await deleteSelectedInvitation(
        getAccessToken(),
        communityId,
        formData
      );
      if (response.status === 200) {
        setInvitations(response.data.data);
        enqueueSnackbar('Invite deleted successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Invite to delete post', { variant: 'error' });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('An error occurred while deleting the Invite', {
        variant: 'error',
      });
    }
  };
  const createNewInvitation = async (Id: string, formData: FormData) => {
    try {
      setIsLoading(true);
      const response = await createInvitationByCommunityId(
        getAccessToken(),
        Id,
        formData
      );

      if (response.status === 200) {
        setInvitations((prevInvitation) => [
          ...prevInvitation,
          response.data.data,
        ]);
        enqueueSnackbar('Invitation sent successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to send Invitation', { variant: 'error' });
      }
      setIsLoading(false);
      return response;
    } catch (error) {
      enqueueSnackbar('An error occurred while sending the Invitation', {
        variant: 'error',
      });
    }
  };
  const joinInvitation = async (formData: FormData) => {
    try {
      setIsLoading(true);
      const response = await joinToInvitedCommunity(
        getAccessToken(),

        formData
      );

      if (response.status === 200) {
        setInvitations((prevInvitation) => [
          ...prevInvitation,
          response.data.data,
        ]);
        enqueueSnackbar('Invitation join successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to join Invitation', { variant: 'error' });
      }
      setIsLoading(false);
      return response;
    } catch (error) {
      enqueueSnackbar('An error occurred while joining the Invitation', {
        variant: 'error',
      });
    }
  };

  const updateSingleInvitations = async (Id: string, formData: FormData) => {
    try {
      const response = await updateInvitationById(
        getAccessToken(),
        Id,
        formData
      );
      if (response.status === 200) {
        // Find and update the post in the posts state
        setInvitations((prevInvitation) =>
          prevInvitation.map((Invitation) =>
            Invitation._id === Id ? response.data.data : Id
          )
        );
        enqueueSnackbar('Invitation updated successfully', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Failed to update Invitation', { variant: 'error' });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('An error occurred while updating the Invitation', {
        variant: 'error',
      });
    }
  };

  const updateSelectedInvitations = async (
    communityId: string,
    formData: FormData
  ) => {
    try {
      const response = await updateSelectedInvitation(
        getAccessToken(),
        communityId,
        formData
      );
      if (response.status === 200) {
        setInvitations((prevInvitation) =>
          prevInvitation.map((Invitation) =>
            Invitation._id === communityId ? response.data.data : communityId
          )
        );
        enqueueSnackbar('Invitation updated successfully', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Failed to update Invitation', { variant: 'error' });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('An error occurred while updating the Invitation', {
        variant: 'error',
      });
    }
  };
  const getInvitesForUser = async () => {
    setIsLoading(true);
    console.log('getInvitesForUser123');

    const response = await getCommunityInvitationsForUser(getAccessToken());
    // console.log(response)
    if (response) {
      setIsLoading(false);

      return response.data;
    } else {
      enqueueSnackbar('Fetching InviteList failed', { variant: 'error' });
      return [];
    }
  };
  return {
    isLoading,
    invitations,
    getInviteList,
    deleteInviteById,
    deleteSelectedInvitations,
    updateSingleInvitations,
    updateSelectedInvitations,
    createNewInvitation,
    addInvites,
    getInvitesForUser,
    joinInvitation,
  };
};
